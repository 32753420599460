<template>
  <div class="integer-wrap">
    <h4 class="title is-6">
      {{name}} <span class="mandatory-label" v-if="isRequired">* obligatorio</span>
    </h4>
    <div class="field">
      <input :type="type" :id="'integer-field-' + index" v-model="value" :disabled="disabled"/>
    </div>
  </div>
</template>

<script>
import { IsEmptyOrNull } from '@/utils/utils'
export default {
  name: 'InputField',
  props: {
    type: {
      type: String
    },
    name: {
      type: String
    },
    selectedValue: {
      type: Array
    },
    list: {
      type: Array
    },
    index: {
      type: Number
    },
    isPrimary: {
      type: Boolean,
      default: true
    },
    isRounded: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      value: null,
      hasFocus: false
    }
  },
  watch: {
    value (newValue) {
      this.$emit('updateInteger', {
        indexField: this.index,
        value: newValue
      })
    }
  },
  created () {
    this.value = this.selectedValue[0]

    if (IsEmptyOrNull(this.selectedValue) || this.selectedValue.length === 0) {
      this.$emit('updateInteger', {
        indexField: this.index,
        value: this.value
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
