<template>
  <div class="radio-tronera-wrap">
    <h4 class="title is-6">
      <div class="custom-switch" v-if="!isRequired">
        <input type="checkbox" :id="'switch-tronera-field-' + index" v-model="isActive" :disabled="disabled"/><label class="toggle" :for="'switch-tronera-field-' + index">Toggle</label>
      </div>
      {{name}} <span class="mandatory-label" v-if="isRequired">* obligatorio</span>
    </h4>
    <div class="field">
      <div class="columns is-flex-wrap-wrap radio-w-images-wrap">
        <template v-for="(item, index) in list">
          <label class="column is-3 has-text-centered radio-item" v-bind:key="index">
              <img :src="images[index]" /><br>
              <input type="radio" v-model="selected" :value="index" :disabled="!isActive || disabled"/><br>
              {{item}}
          </label>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { IsEmptyOrNull } from '@/utils/utils'
export default {
  name: 'TroneraDesign',
  props: {
    name: {
      type: String
    },
    selectedValue: {
      type: Array
    },
    list: {
      type: Array
    },
    index: {
      type: Number
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selected: null,
      isActive: false,
      images: [
        '/images/tronera/0.svg',
        '/images/tronera/1.svg',
        '/images/tronera/2.svg'
      ]
    }
  },
  watch: {
    isActive (newValue) {
      if (!newValue) {
        this.$emit('clearFieldValueRadio', {
          indexField: this.index
        })
        this.selected = null
      }
    },
    selected (newValue) {
      this.$emit('updateRadio', {
        indexField: this.index,
        indexRadio: newValue
      })
    }
  },
  created () {
    if (!IsEmptyOrNull(this.selectedValue[0]) || this.isRequired) {
      this.isActive = true
    }
    this.selected = this.selectedValue[0]
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .radio-tronera-wrap{
    .title{
      line-height: 30px;
      text-transform: uppercase;
      .custom-switch{
        width: auto;
        margin-right: 21px;
      }
    }
    .radio-w-images-wrap{
      .radio-item{
        img{
          max-width: 100%;
        }
        input{
          height: 20px;
          width: 20px;
          margin-top: 14px;
        }
      }
    }
  }
</style>
