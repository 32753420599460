<template>
  <div id="app" :class="{ 'has-hero-image': hasHeroImage }">
    <HeaderNav v-if="!isPrintable" :has-hero-image="hasHeroImage" :is-simple-header="isSimpleHeader"></HeaderNav>
    <router-view/>
    <transition name="fade" v-if="!isPrintable">
      <ModalFactory v-if="modalOptions !== null"></ModalFactory>
    </transition>
    <transition name="fade" v-if="!isPrintable">
      <Feedbacks v-if="feedbacks.length > 0"></Feedbacks>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HeaderNav from '@/components/HeaderNav.vue'
import ModalFactory from '@/components/Modals/ModalFactory.vue'
import Feedbacks from '@/components/Feedbacks.vue'
export default {
  components: {
    HeaderNav,
    ModalFactory,
    Feedbacks
  },
  computed: {
    ...mapGetters({
      modalOptions: 'application/modalOptions',
      isLoggedIn: 'user/isLoggedIn',
      feedbacks: 'application/feedbacks'
    })
  },
  watch: {
    isLoggedIn: function (newVal, oldVal) {
      if (!newVal && oldVal) {
        this.$router.push({ path: '/' })
      }
    },
    $route (to) {
      switch (to.name) {
        case 'Home':
        case 'About':
        case 'Products':
          this.hasHeroImage = true
          this.isSimpleHeader = false
          break
        case 'Laboratories':
          this.hasHeroImage = false
          this.isSimpleHeader = false
          break
        case 'WelcomeLab':
        case 'CompleteRegLab':
        case 'RegCompletedLab':
          this.hasHeroImage = false
          this.isSimpleHeader = true
          break
        case 'PrintCertificate':
          this.isPrintable = true
          this.hasHeroImage = true
          break
        default:
          this.isSimpleHeader = false
          this.hasHeroImage = false
      }
    }
  },
  data () {
    return {
      isSimpleHeader: false,
      hasHeroImage: false,
      isPrintable: false
    }
  },
  created () {
    this.$store.dispatch('application/getSettings')
  }
}
</script>

<style lang="scss">
@import "assets/icons.css";

html, body{
  width: 100%;
  scroll-behavior: smooth;
}

#app {
  width: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: "basic-sans",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 70px;
  overflow-x: hidden;
  &.has-hero-image{
    padding-top: 0px;
  }
}
.v-collapse-content{
    max-height: 0;
    transition: max-height 0.3s ease-out;
    overflow: hidden;
    padding: 0;
}
.v-collapse-content-end{
    transition: max-height 0.3s ease-in;
    max-height: 500px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .4s ease;
  opacity: 1;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.fade-scale-enter-active, .fade-scale-leave-active {
  transition: all .4s ease;
  opacity: 1;
  transform: scale(1);
}
.fade-scale-enter, .fade-scale-leave-to /* .fade-scale-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: scale(0.9);
}
.fade-scale-enter{
  transform: scale(0.8);
}
.v-collapse-content-end {
  max-height: 5000px!important;
}
strong{
  color: #57567C!important;
  &.txt-yellow{
    color: #E3914F!important;
  }
}
span{
  &.mandatory-label{
    color: #EA4B4B;
    font-size: .7rem;
  }
}
hr{
  height: 1px!important;
  background-color: #57567C!important;
  &.light{
    opacity: .2;
  }
  &.small-margin{
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
button{
  &.button{
    &.is-outline{
      border: 2px solid #57567C;
      color: #57567C;
      background-color: transparent;
      &:focus{
        &:hover{
          color: #FFFFFF!important;
        }
        border-color: #57567C!important;
        color: #57567C!important;
      }
    }
    text-transform: uppercase;
    font-size: 14px!important;
    padding: 21px 16px!important;
    font-weight: 600;
    white-space: normal;
  }
}
::placeholder {
  text-transform: uppercase;
}
input[type=text], input[type=password], input[type=number]{
  min-height: 40px;
}
select{
  text-transform: uppercase;
  min-height: 40px;
}
.overflow-h{
  overflow: hidden;
}
.custom-switch{
  overflow: hidden;
  float: left;
  width: 50%;
  display: flex;
  &.float-r{
    float: right;
  }
  &.w-auto{
    width: auto;
  }
  .switch-label{
    padding-left: 14px;
    padding-top: 2px;
  }
  input[type=checkbox]{
    height: 0;
    width: 0;
    visibility: hidden;
  }

  .toggle {
    cursor: pointer;
    text-indent: -9999px;
    width: 60px;
    height: 30px;
    background: #E5E4DF;
    display: block;
    border-radius: 100px;
    position: relative;
  }

  .toggle:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 50%;
    transition: 0.3s;
  }

  input:checked + .toggle {
    background: #E3914F;
  }

  input:checked + .toggle:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }

  .toggle:active:after {
    width: 130px;
  }

  input:checked + .toggle {
    background: #E3914F;
  }

  input:checked + .toggle:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }
}
input{
  &.is-primary.input{
    border-width: 1px;
  }
}
.select{
  &.is-primary{
    select{
      border-width: 1px;
    }
  }
  &.is-full-width{
    width: 100%;
    select{
      width: 100%;
    }
  }
}
.stlab-radio{
  width: 20px;
  height: 0;
  position: relative;
  top: -15px;
  margin-bottom: 20px;
  &::after, &::before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    border: 1px solid #57567C;
  }
  &::before {
    width: 15px;
    height: 15px;
    left: 3px;
    top: 3px;
    border-color: transparent;
  }
  &:checked {
    &::before{
      border-color: #E3914F;
      background-color: #E3914F;
    }
    &::after {
      border-color: #57567C;
    }
  }
}
.title.is-2 {
  font-size: 2rem!important;
}
.txt-12 {
  font-size: 12px;
}
.txt-14 {
  font-size: 14px;
}
.txt-16 {
  font-size: 16px;
}
.txt-18 {
  font-size: 18px;
}
.txt-24 {
  font-size: 24px;
}
.txt-72 {
  font-size: 72px!important;
}
.txt-white {
  color: #FFFFFF!important;
}
.txt-yellow {
  color: #E3914F;
}
.is-underlined{
  text-decoration: underline;
}
.has-background-light{
  background-color: #F6F4EF;
}
.has-text-danger{
  color: #EA4B4B;
}
.notification{
  padding: 8px;
  border-radius: 4px;
  &.is-danger{
    color: #EA4B4B;
  }
}
.show-mobile{
  display: none!important;
}
@media screen and (max-width: 768px) {
  #app {
    padding-top: 50px;
    &.has-hero-image{
      padding-top: 0px;
    }
    .hide-mobile{
      display: none!important;
    }
    .show-mobile{
      display: inline-block!important;
    }
    .txt-c-mobile{
      text-align: center!important;
    }
    .txt-l-mobile{
      text-align: left!important;
    }
    .column.is-full-mobile {
      flex: none!important;
      width: 100%!important;
    }
    .txt-72 {
      font-size: 32px!important;
    }
  }
}
// PRINT RULES
@media print {
  @page {
    size: A4;
  }
  @page :first {}
  .page {
    page-break-after: always;
  }
}
</style>
