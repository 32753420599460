<template>
  <div id="extra-edit-modal" class="extra-edit-modal">
    <div class="columns">
      <extra-products v-if="modalOptions.extras.length > 0"
        :extras-list="modalOptions.extras"
        :active-extras="modalOptions.activeExtras"
        :selected-extras-value="modalOptions.selectedExtrasValue"
        :order-id="modalOptions.orderId"
        v-on:addExtra="modalOptions.addExtra"
        v-on:removeExtra="modalOptions.removeExtra"
        v-on:updateExtraQuantity="modalOptions.updateExtraQuantity"
        ></extra-products>
        <p v-if="modalOptions.extras.length === 0">No extras available</p>
    </div>
  </div>
</template>

<script>
import ExtraProducts from '@/components/ExtraProducts.vue'

export default {
  props: {
    modalOptions: Object
  },
  components: {
    ExtraProducts
  },
  data () {
    return {
    }
  },
  methods: {
  },
  created () {
  }
}
</script>

<style lang="scss">
.extra-edit-modal{
  width: 100%;
  margin-bottom: 76px;
  padding: 0 76px;
  hr{
    margin-top: 0px;
    display: none;
  }
  h4{
    text-transform: uppercase;
    &.is-text-orange{
      color: #E3914F;
    }
  }
  .mandatory-options, .optional-options{
    flex-wrap: wrap;
    .column.is-12{
      padding-bottom: 24px;
      margin-bottom: 24px;
      border-bottom: 1px solid #57567C;
    }
    .checkbox, .radio, select{
      margin-right: 14px;
      margin-bottom: 14px;
    }
    select{
      display: block;
      margin-bottom: 14px;
      padding: 0 7px;
    }
  }
}
</style>
