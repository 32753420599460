<template>
  <div class="toggle-wrap">
    <h4 class="title is-6">
      {{name}} <span class="mandatory-label" v-if="isRequired">* obligatorio</span>
    </h4>
    <div class="field">
      <div class="custom-switch">
        <input type="checkbox" :id="'switch-product-field-' + index" v-model="selected" :disabled="disabled"/><label class="toggle" :for="'switch-product-field-' + index">Toggle</label>
      </div>
    </div>
  </div>
</template>

<script>
import { IsEmptyOrNull } from '@/utils/utils'
export default {
  name: 'Toggle',
  props: {
    name: {
      type: String
    },
    selectedValue: {
      type: Array
    },
    list: {
      type: Array
    },
    index: {
      type: Number
    },
    isPrimary: {
      type: Boolean,
      default: true
    },
    isRounded: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selected: false,
      hasFocus: false
    }
  },
  watch: {
    selected (newValue) {
      this.$emit('updateToggle', {
        indexField: this.index,
        valueToggle: newValue
      })
    }
  },
  created () {
    if (this.selectedValue.includes('t') || this.selectedValue.includes('true')) {
      this.selected = true
    }

    if (IsEmptyOrNull(this.selectedValue) || this.selectedValue.length === 0) {
      this.$emit('updateToggle', {
        indexField: this.index,
        valueToggle: this.selected
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
