<template>
  <div class="feedbacks-wrap">
    <template v-for="(feedback, index) in feedbacks">
      <div :key="index" class="columns is-mobile is-justify-content-center is-align-items-center feedback-card" @click="removeFeedback()">
        <div class="column is-3">
          <div class="icon has-text-centered">
            <span class="icon-check-green" v-if="feedback.type === 'success'"></span>
            <span class="icon-alert" v-if="feedback.type === 'alert'"></span>
            <span class="icon-error" v-if="feedback.type === 'error'"></span>
          </div>
        </div>
        <div class="column is-9 has-text-centered">
          <p>{{feedback.message}}</p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Feedbacks',
  computed: {
    ...mapGetters({
      feedbacks: 'application/feedbacks'
    })
  },
  data () {
    return {
      interval: null
    }
  },
  methods: {
    removeFeedback () {
      this.$store.commit({
        type: 'application/REMOVE_FEEDBACK'
      })
    }
  },
  mounted () {
    const self = this
    this.interval = setInterval(() => {
      if (self.feedbacks.length > 0) {
        self.removeFeedback()
      } else {
        clearInterval(self.interval)
      }
    }, 5000)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.feedbacks-wrap{
  position: fixed;
  bottom: 21px;
  right: 21px;
  z-index: 99999999999;
  .feedback-card{
    margin-top: 7px;
    width: 350px;
    height: 100px;
    border-radius: 10px;
    background-color: rgba(87,86,124,.9);
    box-shadow: 0 2px 24px 0 rgba(0,0,0,0.5);
    .icon{
      font-size: 3rem;
      .icon-check-green {
        color: seagreen;
      }
      .icon-alert {
        color: darkorange;
      }
      .icon-error {
        color: red;
      }
    }
    p{
      color: #FFF;
      font-weight: bold;
      font-size: 16px;
    }
  }
}
@media screen and (max-width: 768px) {
  .feedbacks-wrap{
    right: initial;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    .feedback-card{
      margin: 0 auto;
      margin-top: 14px;
      width: 100%;
      height: 80px;
      max-width: 350px;
    }
  }
}
</style>
