<template>
  <div class="modal-factory" v-bind:style="styleObj">
    <div class="modal-overlay"></div>
    <div class="modal-wrap" v-bind:class="{ 'small-modal': modalOptions.smallSize }">
        <div class="modal-header">
          <h3 class="title has-text-centered" v-html="modalOptions.title"></h3>
          <button class="close-modal-btn" @click="closeModal()"><span class="icon-close"></span></button>
        </div>
        <div class="modal-content">
            <!-- MODAL BODY -->
            <component :modal-options="modalOptions" :is="modalOptions.componentId"></component>
        </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoginRegister from '@/components/Modals/LoginRegister.vue'
import CompleteOrderDetails from '@/components/Modals/CompleteOrderDetails.vue'
import OrderAdded from '@/components/Modals/OrderAdded.vue'
import OrderReject from '@/components/Modals/OrderReject.vue'
import OrderRevision from '@/components/Modals/OrderRevision.vue'
import OrderUpdated from '@/components/Modals/OrderUpdated.vue'
import ExtraEdit from '@/components/Modals/ExtraEdit.vue'
import {
  IsEmptyOrNull
} from '@/utils/utils'

export default {
  name: 'ModalFactory',
  components: {
    LoginRegister,
    CompleteOrderDetails,
    OrderAdded,
    OrderReject,
    OrderRevision,
    OrderUpdated,
    ExtraEdit
  },
  computed: {
    ...mapGetters({
      modalOptions: 'application/modalOptions'
    })
  },
  data () {
    return {
      styleObj: {
        height: '100%'
      }
    }
  },
  watch: {
    'modalOptions.componentId': function () {
      window.scrollTo(0, 0)
    }
  },
  methods: {
    closeModal () {
      if (!IsEmptyOrNull(this.modalOptions.closeCb)) {
        this.modalOptions.closeCb()
      }
      this.$store.commit({
        type: 'application/CLOSE_MODAL'
      })
    }
  },
  created () {
    this.styleObj.height = document.documentElement.scrollHeight + 'px'
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped lang="scss">
.modal-factory{
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    z-index: 10000000000;
  .modal-overlay{
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 1;
      height: 100%;
      width: 100%;
      background-color: rgba(26, 26, 26,.5);
  }
  .modal-wrap{
    position: absolute;
    top: 157px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 2;
    width: calc(100% - 182px);
    max-width: 1188px;
    border-radius: 4px;
    background-color: #FFFFFF;
    min-height: 20px;
    box-shadow: 0 2px 44px 0 rgba(0,0,0,0.3);
    &.small-modal{
      max-width: 672px;
    }
    .modal-header{
      position: relative;
      width: 100%;
      padding-top: 38px;
      margin-bottom: 47px;
      .modal-title{
      }
      .close-modal-btn{
        position: absolute;
        top: 10px;
        right: 10px;
        height: 40px;
        width: 40px;
        background-color: #E3914F;
        border-radius: 50%;
        border: 0px;
      }
    }
    .modal-content{
      width: 100%;
    }
  }
}
@media screen and (max-width: 550px) {
  .modal-factory{
    position: fixed;
    height: 100%!important;
    .modal-wrap{
      top: 0px;
      left: 0px;
      transform: initial;
      width: 100%;
      height: 100%;
      .modal-header{
        padding: 0 21px;
        padding-top: 48px;
        margin-bottom: 21px;
        height: 110px;
      }
      .modal-content{
        height: calc(100% - 110px);
        padding-bottom: 35px;
        padding-top: 35px;
        overflow-y: auto;
      }
    }
  }
}
</style>
