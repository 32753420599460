<template>
  <div class="triple-color-wrap">
    <div class="radio-color-wrap">
      <h4 class="title is-6">
        {{name}} <span class="mandatory-label" v-if="isRequired">* obligatorio</span>
      </h4>
      <div class="field">
        <template v-for="(item, index) in vitaList">
          <label class="radio" v-bind:key="index">
            <input type="radio" v-model="selectedVita" :value="item.value" :disabled="disabled"/>
            <span class="pl-4" v-html="item.label"></span>
          </label>
        </template>
      </div>
      <p class="mt-6">Escriba los tres colores en el campo contiguo</p>
    </div>
    <div class="teeth-color-wrap">
      <div class="color-img-wrap">
        <img :src="colorImg" />
      </div>
      <div class="input-color-wrap">
        <input-text :placeholder="'COLOR (E.G. A1, A2...)'" v-model="valueTop" :name="'color-triple-top'" class="color-top" :disabled="disabled"></input-text>
        <input-text :placeholder="'COLOR (E.G. A1, A2...)'" v-model="valueMiddle" :name="'color-triple-middle'" class="color-middle" :disabled="disabled"></input-text>
        <input-text :placeholder="'COLOR (E.G. A1, A2...)'" v-model="valueBottom" :name="'color-triple-bottom'" class="color-bottom" :disabled="disabled"></input-text>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from '@/components/FormElements/InputText.vue'
export default {
  name: 'TeethColorTriple',
  components: {
    InputText
  },
  props: {
    name: {
      type: String
    },
    selectedValue: {
      type: Array
    },
    list: {
      type: Array
    },
    index: {
      type: Number
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      valueTop: '',
      valueMiddle: '',
      valueBottom: '',
      selectedVita: 'vita-classical',
      vitaList: [
        {
          label: 'VITA classical A1-D4&#174;',
          value: 'vita-classical'
        },
        {
          label: 'VITA 3D-MASTER&#174;',
          value: 'vita-3dmaster'
        }
      ],
      // Images
      colorImg: '/images/color/colore-3.png'
    }
  },
  watch: {
    selectedVita (newValue) {
      this.$emit('updateColorValues', {
        indexField: this.index,
        values: [newValue + '|' + this.valueTop, newValue + '|' + this.valueMiddle, newValue + '|' + this.valueBottom]
      })
    },
    valueTop (newValue) {
      this.$emit('updateColorValues', {
        indexField: this.index,
        values: [this.selectedVita + '|' + newValue, this.selectedVita + '|' + this.valueMiddle, this.selectedVita + '|' + this.valueBottom]
      })
    },
    valueMiddle (newValue) {
      this.$emit('updateColorValues', {
        indexField: this.index,
        values: [this.selectedVita + '|' + this.valueTop, this.selectedVita + '|' + newValue, this.selectedVita + '|' + this.valueBottom]
      })
    },
    valueBottom (newValue) {
      this.$emit('updateColorValues', {
        indexField: this.index,
        values: [this.selectedVita + '|' + this.valueTop, this.selectedVita + '|' + this.valueMiddle, this.selectedVita + '|' + newValue]
      })
    }
  },
  created () {
    if (this.selectedValue.length > 0) {
      this.selectedVita = this.selectedValue[0].split('|')[0]
      this.valueTop = this.selectedValue[0].split('|')[1]
    }
    if (this.selectedValue.length > 1) {
      this.valueMiddle = this.selectedValue[1].split('|')[1]
    }
    if (this.selectedValue.length > 2) {
      this.valueBottom = this.selectedValue[2].split('|')[1]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .triple-color-wrap{
    width: 100%;
    display: flex;
    justify-content: space-between;
    .radio-color-wrap{
      width: 40%;
      .radio{
        display: block;
        margin-left: 0px;
      }
    }
    .teeth-color-wrap{
      display: flex;
      justify-content: space-between;
      width: 60%;
      .color-img-wrap{
        width: 200px;
        img{
          width: 140px;
        }
      }
      .input-color-wrap{
        position: relative;
        width: calc(100% - 200px);
        .input-text-wrap{
          position: absolute;
          top: 50%;
          left: 0px;
          transform: translate(0, -50%);
          max-width: 300px;
          width: 80%;
          &.color-top{
            top: 14px;
            transform: translate(0, 0%);
          }
          &.color-bottom{
            top: initial;
            bottom: 20px;
            transform: translate(0, 0%);
          }
          &:before{
            content: '';
            display: block;
            height: 2px;
            width: 116px;
            background-color: #E3914F;
            position: absolute;
            top: 50%;
            right: calc(100% + 14px);
          }
          &:after{
            content: '';
            display: block;
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background-color: #E3914F;
            position: absolute;
            top: calc(50% + 1px);
            right: calc(100% + 125px);
            transform: translate(0, -50%);
          }
        }
      }
    }
  }
</style>
