import products from '@/api/products'
import { IsEmptyOrNull } from '@/utils/utils'

const getProducts = ({ commit }, payload) => {
  const params = {
    page: (IsEmptyOrNull(payload.page) ? 1 : payload.page),
    pageSize: (IsEmptyOrNull(payload.pageSize) ? 30 : payload.pageSize),
    minPriceIn: (IsEmptyOrNull(payload.minPriceIn) ? '' : payload.minPriceIn),
    materialIdIn: (IsEmptyOrNull(payload.materialIdIn) ? '' : payload.materialIdIn),
    categoryIdIn: (IsEmptyOrNull(payload.categoryIdIn) ? '' : payload.categoryIdIn),
    searchString: (IsEmptyOrNull(payload.searchString) ? '' : payload.searchString),
    productOffersPresent: (IsEmptyOrNull(payload.productOffersPresent) ? '' : payload.productOffersPresent),
    cb: (response) => {
      commit({
        type: 'SET_PRODUCTS',
        products: response
      })
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  products.getProducts(params)
}

const getProductById = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    const params = {
      productId: payload.productId
    }
    products.getProductById(params)
      .then(data => {
        return resolve(data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const getLaboratoriesByProduct = ({ commit }, payload) => {
  const params = {
    productId: payload.productId,
    page: (IsEmptyOrNull(payload.page) ? 1 : payload.page),
    pageSize: (IsEmptyOrNull(payload.pageSize) ? 20 : payload.pageSize),
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  products.getLaboratoriesByProduct(params)
}

const getProductCategories = ({ commit }, payload) => {
  const params = {
    page: (IsEmptyOrNull(payload.page) ? 1 : payload.page),
    pageSize: (IsEmptyOrNull(payload.pageSize) ? 10000 : payload.pageSize),
    cb: (response) => {
      commit({
        type: 'SET_PRODUCT_CATEGORIES',
        categories: response
      })
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  products.getProductCategories(params)
}

const getProductMaterials = ({ commit }, payload) => {
  const params = {
    page: (IsEmptyOrNull(payload.page) ? 1 : payload.page),
    pageSize: (IsEmptyOrNull(payload.pageSize) ? 10000 : payload.pageSize),
    cb: (response) => {
      commit({
        type: 'SET_PRODUCT_MATERIALS',
        materials: response
      })
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  products.getProductMaterials(params)
}

const getProductComplementaries = ({ commit }, payload) => {
  const params = {
    productId: payload.productId,
    page: (IsEmptyOrNull(payload.page) ? 1 : payload.page),
    pageSize: (IsEmptyOrNull(payload.pageSize) ? 20 : payload.pageSize),
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  products.getProductComplementaries(params)
}

const getProductOffers = ({ commit }, payload) => {
  const params = {
    page: (IsEmptyOrNull(payload.page) ? 1 : payload.page),
    pageSize: (IsEmptyOrNull(payload.pageSize) ? 30 : payload.pageSize),
    priceIn: (IsEmptyOrNull(payload.priceIn) ? '' : payload.priceIn),
    minDaysToDeliverIn: (IsEmptyOrNull(payload.minDaysToDeliverIn) ? '' : payload.minDaysToDeliverIn),
    maxDaysToDeliverIn: (IsEmptyOrNull(payload.maxDaysToDeliverIn) ? '' : payload.maxDaysToDeliverIn),
    productIdIn: (IsEmptyOrNull(payload.productIdIn) ? '' : payload.productIdIn),
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }

  products.getProductOffers(params)
}

const getProductOffersComplementaries = ({ commit }, payload) => {
  const params = {
    productOfferId: payload.productOfferId,
    page: (IsEmptyOrNull(payload.page) ? 1 : payload.page),
    pageSize: (IsEmptyOrNull(payload.pageSize) ? 100 : payload.pageSize),
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  products.getProductOffersComplementaries(params)
}

const createProductOffers = ({ commit }, payload) => {
  const params = {
    labId: payload.labId,
    params: payload.offer,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  products.createProductOffers(params)
}

const updateProductOffers = ({ commit }, payload) => {
  products.updateProductOffers(payload)
}

const deleteProductOffers = ({ commit }, payload) => {
  const params = {
    productOfferId: payload.productOfferId,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  products.deleteProductOffers(params)
}

const getProductOrderFields = ({ commit }, payload) => {
  const params = {
    page: (IsEmptyOrNull(payload.page) ? 1 : payload.page),
    pageSize: (IsEmptyOrNull(payload.pageSize) ? 30 : payload.pageSize),
    productId: (IsEmptyOrNull(payload.productId) ? '' : payload.productId),
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }

  products.getProductOrderFields(params)
}

export default {
  getProducts,
  getProductById,
  getLaboratoriesByProduct,
  getProductCategories,
  getProductMaterials,
  getProductOffers,
  createProductOffers,
  updateProductOffers,
  deleteProductOffers,
  getProductOrderFields,
  getProductComplementaries,
  getProductOffersComplementaries
}
