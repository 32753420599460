<template>
  <div class="column is-5 register-wrap">
    <h4 class="title has-text-centered pb-5">Crear una nueva cuenta</h4>
    <p class="is-size-6 pb-4">
      Crea una nueva cuenta para gestionar con facilidad tus ordenes, tu dirección y metodos de pago.
    </p>
    <div class="field">
      <input-text :placeholder="'Nombre clinic*'" v-model="clinic_name" :name="'clinic_name'"></input-text>
    </div>
    <div class="field">
      <input-text :placeholder="'Nombre*'" v-model="first_name" :name="'email'"></input-text>
    </div>
    <div class="field">
      <input-text :placeholder="'Apellidos*'" v-model="last_name" :name="'email'"></input-text>
    </div>
    <div class="field">
      <input-text :placeholder="'Email*'" v-model="email" :name="'email'"></input-text>
    </div>
    <div class="field">
      <input-text :placeholder="'Contraseña* (min 8 char)'" v-model="password" :name="'password-registration'" :type="'password'"></input-text>
    </div>
    <div class="field mb-5">
      <input-text :placeholder="'Repetir la nueva contraseña* (min 8 char)'" v-model="passwordConfirm" :name="'password-confirm'" :type="'password'"></input-text>
    </div>
    <div class="field">
      <label id="terms-of-service" class="checkbox">
        <input type="checkbox" v-model="terms_of_service_accepted">
        He leído y acepto la Política de privacidad*
      </label>
    </div>
    <div class="field">
      <label class="checkbox">
        <input type="checkbox" v-model="marketing_accepted">
        Doy mi consentimiento para que mis datos personales sean utilizados para recibir ofertas y promociones
      </label>
    </div>
    <button class="button is-primary is-fullwidth is-medium mt-4" @click="registerClinic()">
      Crear mi cuenta
    </button>
  </div>
</template>

<script>
import InputText from '@/components/FormElements/InputText.vue'
import {
  IsEmptyOrNull,
  AddClassById,
  RemoveClassById
} from '@/utils/utils.js'

export default {
  name: 'ClinicRegister',
  components: {
    InputText
  },
  data () {
    return {
      email: '',
      password: '',
      passwordConfirm: '',
      first_name: '',
      last_name: '',
      clinic_name: '',
      rememberMe: false,
      terms_of_service_accepted: false,
      marketing_accepted: false,
      errorRegistration: false
    }
  },
  methods: {
    registerClinic () {
      const self = this
      const missingFields = this.formValidation()
      if (missingFields.length === 0) {
        this.$store.dispatch('user/create', {
          credentials: {
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            password: this.password,
            terms_of_service_accepted: this.terms_of_service_accepted,
            marketing_accepted: this.terms_of_service_accepted,
            newsletter_accepted: this.marketing_accepted,
            clinic: {
              company_name: this.clinic_name
            }
          },
          cb: () => {
            self.goTo(`/clinic/welcome?first_name=${encodeURIComponent(self.first_name)}&last_name=${encodeURIComponent(self.last_name)}&email=${encodeURIComponent(self.email)}`)
          },
          cbError: (error) => {
            console.log(error.errors.full_messages)
            error.errors.full_messages.forEach(errorMsg => {
              self.$store.commit({
                type: 'application/ADD_FEEDBACK',
                feedback: {
                  type: 'error',
                  message: errorMsg
                }
              })
            })
          }
        })
      } else {
        this.$store.commit({
          type: 'application/ADD_FEEDBACK',
          feedback: {
            type: 'error',
            message: missingFields[0]
          }
        })
      }
    },
    formValidation () {
      var missingFields = []
      var re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm

      // First name check
      if (IsEmptyOrNull(this.clinic_name) || this.clinic_name.trim() === '') {
        missingFields.push('Clinic name is missing')
      }

      // First name check
      if (IsEmptyOrNull(this.first_name) || this.first_name.trim() === '') {
        missingFields.push('First name is missing')
      }

      // Last name check
      if (IsEmptyOrNull(this.last_name) || this.last_name.trim() === '') {
        missingFields.push('Last name is missing')
      }

      // Email check
      if (IsEmptyOrNull(this.email) || (this.email.trim() === '' && re.test(this.email.trim()))) {
        missingFields.push('Email is missing or not valid')
      }

      // password check
      if (this.password.length < 8) {
        missingFields.push('Password is too short')
      }

      if (IsEmptyOrNull(this.password) || this.password.trim() === '') {
        missingFields.push('Password is missing')
      }

      // confirm password check
      if (this.passwordConfirm.length < 8) {
        missingFields.push('Password confirm is too short')
      }
      // confirm password check
      if (IsEmptyOrNull(this.passwordConfirm) || this.passwordConfirm.trim() === '') {
        missingFields.push('Password confirm is missing')
      }

      // password confirmation check
      if (this.password.length > 7 && this.passwordConfirm.length > 7) {
        if (this.password.trim() !== this.passwordConfirm.trim()) {
          missingFields.push('Confirm password doesnt match')
          AddClassById('password', 'missing')
          AddClassById('password-confirm', 'missing')
        } else {
          RemoveClassById('password', 'missing')
          RemoveClassById('password-confirm', 'missing')
        }
      }

      if (!this.terms_of_service_accepted) {
        missingFields.push('Accept the Terms Of Service is mandatory')
        AddClassById('terms-of-service', 'missing')
      } else {
        RemoveClassById('terms-of-service', 'missing')
      }

      return missingFields
    },
    goTo (path) {
      this.$router.push({ path: path })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.register-wrap {
  padding: 0px 60px;
  padding-top: 24px;
  position: relative;
  button{
    max-width: 354px;
  }
  #terms-of-service{
    &.missing{
      border-bottom: 1px solid red;
    }
  }
}
@media screen and (max-width: 768px) {
  .register-wrap{
    overflow: hidden;
    button{
      position: initial;
      transform: initial;
      max-width: initial;
    }
  }
}
@media screen and (max-width: 500px) {
  .register-wrap{
    margin-top: 40px;
    border-top: 1px solid #57567C;
    padding: 0 14px;
    padding-top: 25px;
  }
}
</style>
