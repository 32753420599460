<template>
  <div class="control input-text-wrap" v-bind:class="{'has-focus': hasFocus, 'has-icons-right': hasIconsRight}">
    <span class="placeholder" v-html="placeholder" @click="focusInput()"></span>
    <input class="input"
      v-bind:class="{'is-primary': isPrimary, 'is-rounded': isRounded, 'has-error': hasError}"
      :type="type"
      :name="name"
      :value="value"
      :disabled="disabled"
      @input="onInput"
      @focus="onFocus"
      @blur="onBlur"/>
    <span class="icon is-right has-text-centered" v-if="hasIconsRight">
      <span class="icon-search"></span>
    </span>
  </div>
</template>

<script>
import { IsEmptyOrNull } from '@/utils/utils'
export default {
  name: 'InputText',
  props: {
    placeholder: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'text'
    },
    value: {
      type: String
    },
    name: {
      type: String
    },
    isPrimary: {
      type: Boolean,
      default: true
    },
    isRounded: {
      type: Boolean,
      default: false
    },
    hasIconsRight: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      hasFocus: false
    }
  },
  computed: {
    hasError () {
      if (this.type === 'number') {
        if (this.value === '0' || IsEmptyOrNull(this.value)) {
          return true
        } else {
          return false
        }
      }
      if (this.type === 'text' && this.isRequired) {
        if (this.value === '' || IsEmptyOrNull(this.value)) {
          return true
        } else {
          return false
        }
      }
      return false
    }
  },
  methods: {
    onInput (event) {
      // Can add validation here
      this.$emit('input', event.target.value)
      // On component add v-on:input="functionHERE" to listen to this emit
    },
    onFocus () {
      this.hasFocus = true
    },
    onBlur (event) {
      if (IsEmptyOrNull(event.target.value)) {
        this.hasFocus = false
      }
    },
    focusInput () {
      document.getElementsByName(this.name)[0].focus()
    }
    /* onChange(event) { // Supports .lazy
      // Can add validation here
      this.$emit('change', event.target.value);
    } */
  },
  created () {
    if (!IsEmptyOrNull(this.value)) {
      this.hasFocus = true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.input-text-wrap{
  .placeholder{
    position: absolute;
    top: 50%;
    left: 8.5px;
    transform: translate(0, -50%);
    z-index: 999;
    font-weight: bold;
    transition: all .3s ease-out;
  }
  &.has-focus{
    .placeholder{
      font-size: .9rem;
      top: 28%;
    }
  }
  .input{
    padding-top: 18px;
    &.has-error{
      border-color: red;
    }
  }
  .icon{
    height: 34px;
    width: 34px;
    margin-right: 4px;
    margin-top: 2.5px;
    background-color: #E3914F;
    border-radius: 50%;
    line-height: 34px;
  }
}
</style>
