import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCollapse from 'vue2-collapse'
import VueStickyKit from 'vue-stickykit'
require('@/assets/sass/main.scss')
require('@/utils/JsBarcode.code39.min.js')

Vue.use(VueStickyKit)
Vue.use(VueCollapse)

const VueUploadComponent = require('vue-upload-component')
Vue.component('file-upload', VueUploadComponent)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
