import laboratoriesApi from '@/api/laboratories'
import locationsApi from '@/api/locations'
import { IsEmptyOrNull } from '@/utils/utils'

const updateLab = ({ commit }, payload) => {
  const params = {
    labId: payload.labId,
    params: payload.params,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  laboratoriesApi.updateLab(params)
}

const getLocations = ({ commit }, payload) => {
  const params = {
    labId: payload.labId,
    cb: (response) => {
      commit({
        type: 'SET_LOCATIONS',
        locations: response
      })
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  laboratoriesApi.getLocations(params)
}
const getLocationById = ({ commit }, payload) => {
  const params = {
    locationId: payload.locationId,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  locationsApi.getLocationById(params)
}

const createLocation = ({ commit }, payload) => {
  const params = {
    labId: payload.labId,
    data: payload.data,
    cb: (response) => {
      commit({
        type: 'ADD_LOCATION',
        location: response
      })
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  laboratoriesApi.createLocation(params)
}

const updateLocation = ({ commit }, payload) => {
  const params = {
    locationId: payload.locationId,
    data: payload.data,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  locationsApi.updateLocation(params)
}

const deleteLocation = ({ commit }, payload) => {
  const params = {
    locationId: payload.locationId,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  locationsApi.deleteLocation(params)
}

const getLabByInvitation = ({ commit }, payload) => {
  const params = {
    code: payload.invitationCode,
    cb: (response) => {
      /* commit({
        type: 'SET_LAB_INFO',
        info: response
      }) */
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  laboratoriesApi.getLabByInvitation(params)
}

const acceptInvitation = ({ commit }, payload) => {
  const params = {
    code: payload.invitationCode,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  laboratoriesApi.acceptInvitation(params)
}

const getLabList = ({ commit }, payload) => {
  const params = {
    page: (IsEmptyOrNull(payload.page) ? 1 : payload.page),
    pageSize: (IsEmptyOrNull(payload.pageSize) ? 20 : payload.pageSize),
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  laboratoriesApi.getLabList(params)
}

const getLabOrders = ({ commit }, payload) => {
  const params = {
    labId: payload.labId,
    statusIn: payload.statusIn,
    filterSelected: payload.filterSelected,
    orderList: payload.orderList,
    query: payload.query,
    page: (IsEmptyOrNull(payload.page) ? 1 : payload.page),
    pageSize: (IsEmptyOrNull(payload.pageSize) ? 20 : payload.pageSize),
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  if (payload.clinicIdIn !== null) {
    params.clinicIdIn = payload.clinicIdIn
  }
  laboratoriesApi.getLabOrders(params)
}

const getLabOrderById = ({ commit }, payload) => {
  const params = {
    orderId: payload.orderId,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  laboratoriesApi.getLabOrderById(params)
}

const getLabDetail = ({ commit }, payload) => {
  const params = {
    labId: payload.labId,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  laboratoriesApi.getLabDetail(params)
}

const getProductOfferByLab = ({ commit }, payload) => {
  const params = {
    page: (IsEmptyOrNull(payload.page) ? 1 : payload.page),
    pageSize: (IsEmptyOrNull(payload.pageSize) ? 30 : payload.pageSize),
    labId: payload.labId,
    priceIn: payload.priceIn,
    minDaysToDeliverIn: payload.minDaysToDeliverIn,
    maxDaysToDeliverIn: payload.maxDaysToDeliverIn,
    productIdIn: payload.productIdIn,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  laboratoriesApi.getProductOfferByLab(params)
}

const reviewRequest = ({ commit }, payload) => {
  const params = {
    orderItemId: payload.orderItemId,
    reviewRequestComment: payload.reviewRequestComment,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  laboratoriesApi.reviewRequest(params)
}

const createPickUpRequest = ({ commit }, payload) => {
  const params = {
    labId: payload.labId,
    ordersId: payload.ordersId,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  laboratoriesApi.createPickUpRequest(params)
}

const startOrder = ({ commit }, payload) => {
  const params = {
    orderId: payload.orderId,
    comment: payload.comment,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  laboratoriesApi.startOrder(params)
}

const processedOrder = ({ commit }, payload) => {
  const params = {
    orderId: payload.orderId,
    comment: payload.comment,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  laboratoriesApi.processedOrder(params)
}

const shippedOrder = ({ commit }, payload) => {
  const params = {
    orderId: payload.orderId,
    comment: payload.comment,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  laboratoriesApi.shippedOrder(params)
}

const deliveredOrder = ({ commit }, payload) => {
  const params = {
    orderId: payload.orderId,
    comment: payload.comment,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  laboratoriesApi.deliveredOrder(params)
}

const archivedOrder = ({ commit }, payload) => {
  const params = {
    orderId: payload.orderId,
    comment: payload.comment,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  laboratoriesApi.archivedOrder(params)
}

const cancelledOrder = ({ commit }, payload) => {
  const params = {
    orderId: payload.orderId,
    comment: payload.comment,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  laboratoriesApi.cancelledOrder(params)
}

const deleteOrderItem = ({ commit }, payload) => {
  const params = {
    orderItemId: payload.orderItemId,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  laboratoriesApi.deleteOrderItem(params)
}

const createOrderItem = ({ commit }, payload) => {
  const params = {
    orderItemId: payload.orderItemId,
    productOfferId: payload.productOfferId,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  laboratoriesApi.createOrderItem(params)
}

const getStreamChatToken = ({ commit }, payload) => {
  const params = {
    labId: payload.labId,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  laboratoriesApi.getStreamChatToken(params)
}

export default {
  updateLab,
  getLabByInvitation,
  acceptInvitation,
  getLabList,
  getLabOrders,
  getLabOrderById,
  getLabDetail,
  getProductOfferByLab,
  reviewRequest,
  startOrder,
  processedOrder,
  shippedOrder,
  deliveredOrder,
  archivedOrder,
  cancelledOrder,
  getLocations,
  getLocationById,
  createLocation,
  updateLocation,
  deleteLocation,
  deleteOrderItem,
  createOrderItem,
  createPickUpRequest,
  getStreamChatToken
}
