import actions from './actions'
import getters from './getters'
import mutations from './mutations'

// initial state
const state = {
  isLoading: false,
  modalOptions: null,
  /*
  {
    type: 'success',
    message: 'Your info has been saved.'
  },
  {
    type: 'alert',
    message: 'Your info has been saved. Your info has been saved. Your info has been saved.'
  },
  {
    type: 'error',
    message: 'Your info has been saved.'
  }
   */
  feedbacks: [],
  settings: null
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
