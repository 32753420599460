Math.easeInOutQuad = function (t, b, c, d) {
  t /= d / 2
  if (t < 1) {
    return c / 2 * t * t + b
  }
  t--
  return -c / 2 * (t * (t - 2) - 1) + b
}

export const GroupComplementaryShoppingCart = function (items) {
  for (let i = 0; i < items.length; i++) {
    if (items[i].complementary_ids.length > 0) {
      for (let j = 0; j < items[i].complementary_ids.length; j++) {
        for (let t = i + 1; t < items.length; t++) {
          if (items[i].complementary_ids[j] === items[t].id) {
            items[i].complementary_ids[j] = items[t]
            break
          }
        }
      }
    }
  }
}

export const IsEmptyOrNull = function (value) {
  // || typeof value.length === 'undefined'
  if (value === null || value === '' || typeof value === 'undefined') {
    return true
  }
  return false
}

export const GetRandomInt = function () {
  const min = Math.ceil(0)
  const max = Math.floor(10000)
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const HumanFileSize = function (size) {
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024))
  return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
}

export const CheckInView = function (container, element, partial) {
  // Get container properties
  const cTop = container.scrollTop
  const cBottom = cTop + container.clientHeight
  // Get element properties
  const eTop = element.offsetTop
  const eBottom = eTop + element.clientHeight
  // Check if in view
  const isTotal = (eTop >= cTop && eBottom <= cBottom)
  const isPartial = partial && (
    (eTop < cTop && eBottom > cTop) ||
    (eBottom > cBottom && eTop < cBottom)
  )
  // Return outcome
  return (isTotal || isPartial)
}

export const ScrollTo = function (element, to, duration) {
  var start = element.scrollTop
  var change = to - start
  var currentTime = 0
  var increment = 20

  var animateScroll = function () {
    currentTime += increment
    var val = Math.easeInOutQuad(currentTime, start, change, duration)
    element.scrollTop = val
    if (currentTime < duration) {
      setTimeout(animateScroll, increment)
    }
  }
  animateScroll()
}

export const ElemOffset = function (elem) {
  var rect = elem.getBoundingClientRect()
  var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
  var scrollTop = window.pageYOffset || document.documentElement.scrollTop
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

export const Debounce = function (func, wait, immediate) {
  var timeout
  var args
  var context
  var timestamp
  var result

  var later = function () {
    var last = Date.Now() - timestamp

    if (last < wait && last >= 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function () {
    context = this
    args = arguments
    timestamp = Date.Now()
    var callNow = immediate && !timeout
    if (!timeout) {
      timeout = setTimeout(later, wait)
    }
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }
    return result
  }
}

export const AddClassById = function (elemId, classname) {
  var element = document.getElementById(elemId)
  if (!IsEmptyOrNull(element)) {
    element.classList.add(classname)
  }
}

export const RemoveClassById = function (elemId, classname) {
  var element = document.getElementById(elemId)
  if (!IsEmptyOrNull(element)) {
    element.classList.remove(classname)
  }
}

export const RemoveClassBySelector = function (selector, classname) {
  var elements = document.querySelectorAll(selector)
  if (!IsEmptyOrNull(elements)) {
    elements.forEach(function (item) {
      item.classList.remove(classname)
    })
  }
}

export const HasClassById = function (elemId, classname) {
  var element = document.getElementById(elemId)
  if (!IsEmptyOrNull(element)) {
    return element.classList.contains(classname)
  }
}

export const GetArrayRandomElements = function (arr, n) {
  const result = new Array(n)
  let len = arr.length
  const taken = new Array(len)
  if (n > len) {
    throw new RangeError('getRandom: more elements taken than available')
  }
  while (n--) {
    var x = Math.floor(Math.random() * len)
    result[n] = arr[x in taken ? taken[x] : x]
    taken[x] = --len in taken ? taken[len] : len
  }
  return result
}
