<template>
  <div class="home">
  	<HeroImage :bg-image="'images/1-hp-1-hero-original.jpg'" :has-purple-bg="true">
  		<div class="hp-search-wrap">
	      <h3 class="hero-title title is-3 has-text-centered">Tú busca el producto o prótesis, nosotros encontramos el laboratorio</h3>
	      <div class="control has-icons-right">
	        <input class="input is-rounded" type="text" placeholder="Busca un producto..." v-model="searchText" v-on:keyup.enter="searchProduct()">
	        <span class="icon is-right has-text-centered" @click="searchProduct()">
	          <span class="icon-search"></span>
	        </span>
	      </div>
	    </div>
  	</HeroImage>
  	<div class="browse-service-wrap">
  		<div class="macro-categories-wrap">
  			<h3 class="is-size-3 has-text-centered">Descubra todas las categorías</h3>
  			<!-- Prótesis Removibles -->
  			<div class="categories-wrap">
  				<div class="category-box" @click="goTo('/products?category=1')">
  					<div class="category-icon orange">
				      <span class="icon-cat-1"></span>
				    </div>
  					<div class="category-title orange">
  						Prótesis Removibles
  					</div>
  					<div class="category-name">
  						Resina
  					</div>
  					<div class="category-action">
  						<router-link :to="'/products?category=1'" exact>Ver todo</router-link>
  					</div>
  				</div>
  				<div class="category-box" @click="goTo('/products?category=5')">
  					<div class="category-icon orange">
				      <span class="icon-cat-5"></span>
				    </div>
  					<div class="category-title orange">
  						Prótesis Removibles
  					</div>
  					<div class="category-name">
  						Esqueléticos
  					</div>
  					<div class="category-action">
  						<router-link :to="'/products?category=5'" exact>Ver todo</router-link>
  					</div>
  				</div>
  			</div>
  		</div>
  		<div class="macro-categories-wrap">
  			<!-- Prótesis Fijas Sobre Dientes -->
  			<div class="categories-wrap">
  				<div class="category-box" @click="goTo('/products?category=18')">
  					<div class="category-icon blue">
				      <span class="icon-cat-18"></span>
				    </div>
  					<div class="category-title blue">
  						Prótesis Fijas Sobre Dientes
  					</div>
  					<div class="category-name">
  						Prótesis fija con metal sobre dientes
  					</div>
  					<div class="category-action">
  						<router-link :to="'/products?category=18'" exact>Ver todo</router-link>
  					</div>
  				</div>
  				<div class="category-box" @click="goTo('/products?category=2')">
  					<div class="category-icon blue">
				      <span class="icon-cat-2"></span>
				    </div>
  					<div class="category-title blue">
  						Prótesis Fijas Sobre Dientes
  					</div>
  					<div class="category-name">
  						Prótesis fija sin metal sobre dientes
  					</div>
  					<div class="category-action">
  						<router-link :to="'/products?category=2'" exact>Ver todo</router-link>
  					</div>
  				</div>
  				<div class="category-box" @click="goTo('/products?category=13')">
  					<div class="category-icon blue">
				      <span class="icon-cat-13"></span>
				    </div>
  					<div class="category-title blue">
  						Prótesis Fijas Sobre Dientes
  					</div>
  					<div class="category-name">
  						Incrustaciones
  					</div>
  					<div class="category-action">
  						<router-link :to="'/products?category=13'" exact>Ver todo</router-link>
  					</div>
  				</div>
  			</div>
  		</div>
  		<div class="macro-categories-wrap">
  			<!-- Prótesis Removibles -->
  			<div class="categories-wrap">
  				<div class="category-box" @click="goTo('/products?category=14')">
  					<div class="category-icon green">
				      <span class="icon-cat-14"></span>
				    </div>
  					<div class="category-title green">
  						Prótesis Sobre Implantes
  					</div>
  					<div class="category-name">
  						Prótesis directa atornillada
  					</div>
  					<div class="category-action">
  						<router-link :to="'/products?category=14'" exact>Ver todo</router-link>
  					</div>
  				</div>
  				<div class="category-box" @click="goTo('/products?category=15')">
  					<div class="category-icon green">
				      <span class="icon-cat-15"></span>
				    </div>
  					<div class="category-title green">
  						Prótesis Sobre Implantes
  					</div>
  					<div class="category-name">
  						Prótesis sobre muñón
  					</div>
  					<div class="category-action">
  						<router-link :to="'/products?category=15'" exact>Ver todo</router-link>
  					</div>
  				</div>
  				<div class="category-box" @click="goTo('/products?category=16')">
  					<div class="category-icon green">
				      <span class="icon-cat-16"></span>
				    </div>
  					<div class="category-title green">
  						Prótesis Sobre Implantes
  					</div>
  					<div class="category-name">
  						Prótesis híbrida sobre implante
  					</div>
  					<div class="category-action">
  						<router-link :to="'/products?category=16'" exact>Ver todo</router-link>
  					</div>
  				</div>
  			</div>
  		</div>
  		<div class="macro-categories-wrap">
  			<!-- Prótesis Removibles -->
  			<div class="categories-wrap">
  				<div class="category-box" @click="goTo('/products?category=7')">
	  				<div class="category-icon violet">
				      <span class="icon-cat-7"></span>
				    </div>
  					<div class="category-title violet">
  						Ortodoncia
  					</div>
  					<div class="category-name">
  						Ortodoncia fija
  					</div>
  					<div class="category-action">
  						<router-link :to="'/products?category=7'" exact>Ver todo</router-link>
  					</div>
  				</div>
  				<div class="category-box" @click="goTo('/products?category=6')">
  					<div class="category-icon violet">
				      <img src="../assets/images/cat-6.svg">
				    </div>
  					<div class="category-title violet">
  						Ortodoncia
  					</div>
  					<div class="category-name">
  						Ortodoncia removible
  					</div>
  					<div class="category-action">
  						<router-link :to="'/products?category=6'" exact>Ver todo</router-link>
  					</div>
  				</div>
  				<div class="category-box" @click="goTo('/products?category=3')">
  					<div class="category-icon violet">
				      <span class="icon-cat-3"></span>
				    </div>
  					<div class="category-title violet">
  						Ortodoncia
  					</div>
  					<div class="category-name">
  						Férulas
  					</div>
  					<div class="category-action">
  						<router-link :to="'/products?category=3'" exact>Ver todo</router-link>
  					</div>
  				</div>
  			</div>
  		</div>
  	</div>
  	<section id="how-it-works" v-bind:class="{ 'enter-left': enterHowItWorks }">
  		<div class="container is-fullhd">
	  		<div class="columns">
	  			<div class="column is-half is-offset-1">
	  				<h1 class="title is-1">Cómo funciona</h1>
	  			</div>
	  		</div>
	  		<div class="columns works-3-steps">
	  			<img class="line-arrow" alt="STLLAB arrow" src="../assets/line@2x.png">
	  			<div class="column is-7 is-offset-5">
	  				<div class="columns mobile-column">
	  					<div class="column is-one-third">
	  						<div class="big-icon-wrap">
	  							<span class="icon-lab"></span>
	  						</div>
	  						<p class="has-text-centered">
	  							Busca una prótesis<br>y selecciona un laboratorio
	  						</p>
	  					</div>
	  					<div class="column is-one-third">
	  						<div class="small-icon-wrap">
	  							<span class="icon-scan"></span>
	  						</div>
	  						<p class="has-text-centered">
	  							Escanea y carga<br>tu archivo STL
	  						</p>
	  					</div>
	  					<div class="column is-one-third">
	  						<div class="medium-icon-wrap">
	  							<span class="icon-product"></span>
	  						</div>
	  						<p class="has-text-centered">
	  							Recibe el producto<br>en pocos días!
	  						</p>
	  					</div>
	  				</div>
	  			</div>
	  		</div>
	  		<div class="columns mobile-column">
	  			<div class="column is-half">
	  				<div id="orange-circle-left" class="columns orange-circle-left" v-bind:class="{ 'enter-left': enterCircleLeft }">
	  					<div class="column is-8 is-offset-2">
	  						<p class="is-size-4 pb-5">
	  							Queremos brindarte la manera más sencilla de entrar en el flujo digital de la clínica dental. ¡Obtengas prótesis e implantes para sus clientes en unos pocos clics!
	  						</p>
	  						<p class="pb-5">Desde subir tu archivo STL hasta obtener la mejor asistencia durante tu orden de trabajo, no dejamos nada por sentado. Puedes seleccionar los laboratorios que mejor cumple con tus expectativas, luego sentarte y relajarte, ¡del envío y de la entrega nos encargamos nosotros!</p>
	  						<button class="button is-medium is-fullwidth is-primary" @click="goTo('/products')">Empezar un orden de trabajo</button>
	  					</div>
	  				</div>
	  			</div>
	  		</div>
	  		<div id="orange-circle-right" class="columns orange-circle-right" v-bind:class="{ 'enter-right': enterCircleRight }">
	  			<div class="column is-4 is-offset-6">
	  				<h2 class="title is-2">
	  					Con una red segura y garantizada de profesionales, cubrimos todos los riesgos
	  				</h2>
						<p class="pb-5">
							Todo irá de la mejor manera posible, pero, por si acaso, nuestro servicio de atención al cliente te ayudará en cualquier momento que lo necesites.
						</p>
	  				<!-- <button class="button is-medium is-primary" @click="goTo('/laboratories')">
	  					Explorar los laboratorios
	  				</button> -->
	  			</div>
	  		</div>
  		</div>
  	</section>
  	<PreFooterCta></PreFooterCta>
		<Footer></Footer>
  </div>
</template>

<script>
import { IsEmptyOrNull } from '@/utils/utils.js'
import HeroImage from '@/components/HeroImage.vue'
import PreFooterCta from '@/components/PreFooterCta.vue'
import Footer from '@/components/Footer.vue'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
export default {
  name: 'Home',
  components: {
    HeroImage,
    PreFooterCta,
    Footer
  },
  data () {
    return {
    	searchText: '',
    	enterHowItWorks: false,
    	enterCircleLeft: false,
      enterCircleRight: false
    }
  },
  methods: {
    searchProduct () {
      if (!IsEmptyOrNull(this.searchText)) {
        this.$router.push({ path: '/products', query: { search: this.searchText } })
      }
    },
  	handleScroll (e) {
  		const howItWorks = document.getElementById('how-it-works')
  		const orangeCircleLeft = document.getElementById('orange-circle-left')
  		const orangeCircleRight = document.getElementById('orange-circle-right')

  		if (howItWorks.getBoundingClientRect().top < 350) {
  			this.enterHowItWorks = true
  		}

  		if (orangeCircleLeft.getBoundingClientRect().top < 350) {
  			this.enterCircleLeft = true
  		}

  		if (orangeCircleRight.getBoundingClientRect().top < 550) {
  			this.enterCircleRight = true
  		}
    },
  	goTo (path) {
  		this.$router.push({ path: path })
  	}
  },
  created () {
    const self = this
    let ticking = false

    document.addEventListener('scroll', function (e) {
      if (!ticking) {
        window.requestAnimationFrame(function () {
          self.handleScroll(e)
          ticking = false
        })
        ticking = true
      }
    })
  }
}
</script>

<style scoped lang="scss">
.home{
	width: 100%;
	overflow: hidden;
	.hp-search-wrap{
		.control{
			position: relative;
			input{
				position: relative;
				z-index: 1;
	    }
			.is-right{
				position: absolute;
				top: 0px;
				right: 1px;
				cursor: pointer;
				z-index: 9999999;
				color: #FFF!important;
			}
		}
	}
	.browse-service-wrap{
		text-align: center;
		background-color: #57567C;
		width: 100%;
		padding: 57px 0px;
		h3{
			color: #FFF;
			font-weight: 600;
			font-size: 1.25rem;
			padding: 0px 14px;
		}
		.macro-categories-wrap{
			border-top: 1px solid rgba(255, 255, 255, 20%);
			width: 100%;
			max-width: 1024px;
			margin: 0 auto;
			padding: 21px;
			.categories-wrap{
				width: 100%;
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				margin-top: 51px;
				margin-bottom: 21px;
				.category-box{
					position: relative;
					height: 266px;
				  width: 242px;
				  border-radius: 8px;
				  background-color: #FFFFFF;
				  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
				  text-align: center;
				  margin: 10px 10px;
				  transition: all .4s ease-out;
				  cursor: pointer;
				  &:hover{
				    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
				  }
				  .category-icon{
				  	position: absolute;
				    height: 76px;
				    width: 76px;
				    top: -50px;
				    left: 50%;
				    transform: translate(-50%, 0);
				    background-color: #FFF;
				    border-radius: 50%;
				    box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
				    span{
				      position: absolute;
				      top: 50%;
				      left: 50%;
				      transform: translate(-50%, -50%);
				      font-size: 2.2rem;
				      &:before{
				        color: #FFF;
				      }
				    }
			      &.orange{
							background-color: #E3914F;
						}
						&.blue{
							background-color: #7791E1;
						}
						&.green{
							background-color: #8FA974;
						}
						&.violet{
							background-color: #9474BA;
						}
						img{
							width: 55%;
							position: absolute;
				      top: 50%;
				      left: 50%;
				      transform: translate(-50%, -50%);
						}
				  }
					.category-title{
						height: 76px;
					  width: 100%;
					  box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
					  color: #FFF;
					  border-radius: 8px 8px 0px 0px;
					  font-size: 14px;
					  font-weight: bold;
					  letter-spacing: 0.17px;
					  line-height: 14px;
					  padding-top: 18px;
					  text-transform: uppercase;
					  display: flex;
						justify-content: center;
						align-items: center;
						&.orange{
							background-color: #E3914F;
						}
						&.blue{
							background-color: #7791E1;
						}
						&.green{
							background-color: #8FA974;
						}
						&.violet{
							background-color: #9474BA;
						}
					}
					.category-name{
						height: calc(100% - 132px);
						width: 100%;
						color: #57567C;
						font-size: 24px;
					  font-weight: 600;
					  letter-spacing: 0;
					  line-height: 23px;
					  padding: 14px 14px;
					  display: flex;
						justify-content: center;
						align-items: center;
					}
					.category-action{
						height: 56px;
						width: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
						a{
							text-decoration: underline;
						}
					}
				}
			}
		}
		.products-list-carousel-wrap{
			width: 100%;
			padding-bottom: 30px;
			.slide-wrap{
				padding-top: 60px;
				padding-bottom: 40px;
				.service-item{
					position: relative;
					background-color: #FFF;
					width: 242px;
					height: 208px;
					margin-left: 15px;
					margin-right: 15px;
					border-radius: 4px;
					box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
					padding: 10px;
					transition: all .4s ease-out;
					&:hover{
						box-shadow: 0 19px 28px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
					}
					.service-teeth-icon{
						position: absolute;
						height: 76px;
						width: 76px;
						top: -38px;
						left: 50%;
						transform: translate(-50%, 0);
						background-color: #FFF;
						border-radius: 50%;
						box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
						span{
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							font-size: 2rem;
						}
					}
					.service-type{
						margin-top: 50px;
						text-transform: uppercase;
						color: #57567C;
						font-size: 12px;
					  font-weight: 600;
					  letter-spacing: 0;
					  line-height: 23px;
					}
					.service-title{
						color: #57567C;
						font-size: 20px;
					  font-weight: 600;
					  letter-spacing: 0;
					  line-height: 23px;
					  padding: 10px 0px;
					}
					.service-price{
						text-transform: uppercase;
						color: #57567C;
						font-size: .7rem;
					  letter-spacing: 0;
					  line-height: 23px;
					  text-align: center;
					  span{
					  	font-size: 1rem;
					  }
					}
				}
			}
		}
		.is-primary{
			border: 1px solid #FFF;
		}
	}
	section{
		padding: 60px 0px;
	}
	.works-3-steps{
		position: relative;
		overflow: hidden;
		.line-arrow {
			position: absolute;
			right: 0px;
			top: 70px;
		}
		.big-icon-wrap{
			position: relative;
			height: 180px;
	  	width: 180px;
	  	margin: 0 auto;
			background-color: #57567C;
			border-radius: 50%;
			margin-bottom: 21px;
			span{
				color: #FFF;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				font-size: 5rem;
			}
		}
		.small-icon-wrap{
			position: relative;
			height: 112px;
	  	width: 112px;
	  	margin: 0 auto;
			background-color: #57567C;
			border-radius: 50%;
			margin-top: 40px;
			margin-bottom: 21px;
			span{
				color: #FFF;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				font-size: 4rem;
			}
		}
		.medium-icon-wrap{
			position: relative;
			height: 152px;
	  	width: 152px;
	  	margin: 0 auto;
	  	margin-top: 10px;
			background-color: #57567C;
			border-radius: 50%;
			margin-bottom: 21px;
			span{
				color: #FFF;
				position: absolute;
				top: 50%;
				left: 54%;
				transform: translate(-50%, -50%);
				font-size: 4.5rem;
			}
		}
	}
	.orange-circle-left{
		position: relative;
		&:before{
			display: block;
			content: "";
			position: absolute;
			bottom: -100px;
			left: -450px;
			z-index: -1;
			width: 362px;
			height: 362px;
			border-radius: 50%;
			background-color: #E3914F;
			transition: all .8s ease-out;
		}
		&.enter-left{
			&:before{
				left: -61px;
			}
		}
	}
	.orange-circle-right{
		position: relative;
		&:before{
			display: block;
			content: "";
			position: absolute;
			bottom: 10px;
			right: -600px;
			z-index: -1;
			width: 550px;
			height: 550px;
			border-radius: 50%;
			background-color: #E3914F;
			transition: all 1s ease-out;
		}
		&.enter-right{
			&:before{
				right: -100px;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	#how-it-works{
		position: relative;
		text-align: center;
		&:before{
			display: block;
			content: "";
			position: absolute;
			top: -100px;
			left: -450px;
			z-index: -1;
			width: 362px;
			height: 362px;
			border-radius: 50%;
			background-color: #E3914F;
			transition: all .8s ease-out;
		}
		&.enter-left{
			&:before{
				left: -61px;
			}
		}
		.column{
			padding: 0 21px;
		}
		.works-3-steps{
			.columns{
				&.mobile-column{
					display: flex;
					flex-direction: column;
					padding: 28px 0px;
				}
			}
			.line-arrow {
				display: none;
			}
			.big-icon-wrap {
				height: 152px;
	  		width: 152px;
	  		span{
	  			font-size: 4.5rem;
	  		}
			}
			.small-icon-wrap {
				height: 152px;
	  		width: 152px;
	  		span{
	  			font-size: 4.5rem;
	  		}
			}
			.medium-icon-wrap {}
		}
		.orange-circle-left{
			&:before{
				display: none;
			}
		}
		.orange-circle-right{
			margin-top: 35px;
			&.enter-right{
				&:before{
					right: -250px;
				}
			}
		}
	}
}
</style>
