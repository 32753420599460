<template>
  <div class="checkbox-wrap">
    <h4 class="title is-6">
      {{name}} <span class="mandatory-label" v-if="isRequired">* obligatorio</span>
    </h4>
    <div class="field">
      <template v-for="(item, index) in list">
        <label class="checkbox" v-bind:key="index">
          <input :id="'test-' + item" type="checkbox" :disabled="disabled" @click="setCheckboxValue($event, index, item)" :checked="setCheckedValues(item)">
          {{item}}
        </label>
      </template>
    </div>
  </div>
</template>

<script>
import { IsEmptyOrNull } from '@/utils/utils'
export default {
  name: 'Checkbox',
  props: {
    name: {
      type: String
    },
    selectedValue: {
      type: Array
    },
    list: {
      type: Array
    },
    index: {
      type: Number
    },
    isPrimary: {
      type: Boolean,
      default: true
    },
    isRounded: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      hasFocus: false
      // selectedValueTest: ['1', '23', '11']
    }
  },
  methods: {
    setCheckedValues (value) {
      if (this.selectedValue.includes(value.toString())) {
        return true
      }
      return false
    },
    setCheckboxValue (e, index, value) {
      this.$emit('updateCheckbox', {
        indexField: this.index,
        indexCheckbox: index,
        value: value,
        limit: this.list.length
      })
    },
    onInput (event) {
      // Can add validation here
      this.$emit('input', event.target.value)
      // On component add v-on:input="functionHERE" to listen to this emit
    },
    onFocus () {
      this.hasFocus = true
    },
    onBlur (event) {
      if (IsEmptyOrNull(event.target.value)) {
        this.hasFocus = false
      }
    }
    /* onChange(event) { // Supports .lazy
      // Can add validation here
      this.$emit('change', event.target.value);
    } */
  },
  mounted () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
