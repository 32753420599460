import actions from './actions'
import getters from './getters'
import mutations from './mutations'

// initial state
const state = {
  user: {
    id: null,
    email: null,
    allow_password_change: false,
    provider: null,
    uid: null,
    first_name: null,
    last_name: null,
    terms_of_service_accepted: false,
    marketing_accepted: false,
    newsletter_accepted: false,
    roles: [],
    clinics: [{
      id: null,
      company_name: null,
      date_of_birth: null,
      tax_id: null,
      stripe_setup_intent_client_secret: null
    }],
    laboratories: [{
      id: null,
      name: null,
      address_city: null,
      address_state: null,
      address_line1: null,
      address_postal_code: null,
      tax_id: null,
      business_profile_url: null,
      external_account_account_number: null,
      person_date_of_birth: null,
      person_title: null,
      phone: null,
      stripe_account_onboarding_link_url: null,
      pickup_location: null
    }]
  },
  isLoggedIn: false
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
