import actions from './actions'
import getters from './getters'
import mutations from './mutations'

// initial state
const state = {
  // addresses: [],
  locations: [],
  shoppingCart: {
    items: []
  },
  currentOrder: {
    shipping_location_id: null,
    billing_location_id: null,
    pickup_location_id: null
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
