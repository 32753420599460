import api from '@/api/api'
import { IsEmptyOrNull } from '@/utils/utils'
const endpoints = {
  locations: 'locations'
}
export default {
  getLocationById (payload) {
    const endpoint = endpoints.locations + '/' + payload.locationId
    api.get(endpoint).then((response) => {
      if (response.status === 200) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.response.data.errors)
        }
      }
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error.response.data)
      }
    })
  },
  updateLocation (payload) {
    const endpoint = endpoints.locations + '/' + payload.locationId
    api.put(endpoint, { location: payload.data }).then((response) => {
      if (response.status === 200) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.response.data.errors)
        }
      }
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error.response.data)
      }
    })
  },
  deleteLocation (payload) {
    const endpoint = endpoints.locations + '/' + payload.locationId
    api.delete(endpoint).then((response) => {
      if (response.status === 200) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.response.data.errors)
        }
      }
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error.response.data)
      }
    })
  }
}
