import axios from 'axios'
import store from '../store/index.js'
const api = axios.create({
  baseURL: (process.env.NODE_ENV === 'development') ? 'https://api-staging.stlab.es/api/v1/' : 'https://api-staging.stlab.es/api/v1/'
})

/*
  * The interceptor here ensures that we check for the token in local storage every time an ajax request is made
*/
api.interceptors.request.use(
  (config) => {
    config.headers['Accept'] = 'application/json'
    config.headers['Content-Type'] = 'application/json'
    let accessToken = null
    // TO DO CREATE STORE USER AND MANAGE EVERYTHING FROM THERE
    if (store.getters['user/isLoggedIn']) {
      accessToken = localStorage.getItem('token')
    }
    /* if (store.getters['clinic/isLoggedIn']) {
      accessToken = localStorage.getItem('bearer')
    }
    const client = localStorage.getItem('Client')
    const uid = localStorage.getItem('Uid') */
    // const tokenType = localStorage.getItem('Token-Type')
    // const expiry = localStorage.getItem('Expiry')

    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`
      /* config.headers['Access-Token'] = accessToken
      config.headers['Token-Type'] = 'Bearer'
      config.headers['Uid'] = uid
      config.headers['Client'] = client */
      // config.headers['Expiry'] = '1707302672'
    }

    return config
  },

  (error) => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use((response) => {
  return response
}, error => {
  if (error.response.status === 401) {
    console.log('LOGOUT 401')
    store.commit({
      type: 'user/LOG_OUT'
    })
  }
  if (error.response.status === 500) {}
  return Promise.reject(error)
})

export default api
