const isLoading = state => state.isLoading
const modalOptions = state => state.modalOptions
const feedbacks = state => state.feedbacks
const settings = state => state.settings

export default {
  isLoading,
  modalOptions,
  feedbacks,
  settings
}
