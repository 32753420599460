import api from '@/api/api'
import { IsEmptyOrNull } from '@/utils/utils'
const parse = require('parse-link-header')

const endpoints = {
  categories: 'categories',
  materials: 'materials',
  products: 'products',
  laboratories: 'laboratories',
  productOffers: 'product_offers',
  productOrderFields: 'product_order_fields',
  complementaries: 'complementaries'
}
export default {
  getProductCategories (payload) {
    const url = endpoints.categories + '?page=' + payload.page + '&per_page=' + payload.pageSize
    api.get(url).then((response) => {
      response.data.pagination = parse(response.headers['link'])
      payload.cb(response.data)
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    })
  },
  getProductMaterials (payload) {
    const url = endpoints.materials + '?page=' + payload.page + '&per_page=' + payload.pageSize
    api.get(url).then((response) => {
      response.data.pagination = parse(response.headers['link'])
      payload.cb(response.data)
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    })
  },
  getProducts (payload) {
    let url = endpoints.products + '?min_price_in=' + payload.minPriceIn + '&material_id_in=' + payload.materialIdIn + '&category_id_in=' + payload.categoryIdIn + '&name_like=' + payload.searchString + '&page=' + payload.page + '&per_page=' + payload.pageSize
    if (!IsEmptyOrNull(payload.productOffersPresent)) {
      url += '&product_offers_present=' + payload.productOffersPresent
    }
    api.get(url).then((response) => {
      response.data.pagination = parse(response.headers['link'])
      payload.cb(response.data)
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    })
  },
  getProductById (payload) {
    return new Promise((resolve, reject) => {
      const url = endpoints.products + '/' + payload.productId
      api.get(url).then((response) => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error)
      })
    })
  },
  getLaboratoriesByProduct (payload) {
    const url = endpoints.products + '/' + payload.productId + '/laboratories?page=' + payload.page + '&per_page=' + payload.pageSize
    api.get(url).then((response) => {
      response.data.pagination = parse(response.headers['link'])
      payload.cb(response.data)
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    })
  },
  createProductOffers (payload) {
    api.post(endpoints.laboratories + '/' + payload.labId + '/' + endpoints.productOffers, payload.params).then((response) => {
      payload.cb(response.data)
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    })
  },
  updateProductOffers (payload) {
    console.log(payload)
    api.put(endpoints.productOffers + '/' + payload.offer.id, payload.offer).then((response) => {
      payload.cb(response.data)
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    })
  },
  deleteProductOffers (payload) {
    api.delete(endpoints.productOffers + '/' + payload.productOfferId).then((response) => {
      payload.cb(response.data)
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    })
  },
  getProductOffers (payload) {
    const url = endpoints.productOffers + '?price_in=' + payload.priceIn + '&min_days_to_deliver_in=' + payload.minDaysToDeliverIn + '&max_days_to_deliver_in=' + payload.maxDaysToDeliverIn + '&product_id_in=' + payload.productIdIn + '&page=' + payload.page + '&per_page=' + payload.pageSize
    api.get(url).then((response) => {
      response.data.pagination = parse(response.headers['link'])
      payload.cb(response.data)
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    })
  },
  getProductOfferDetail (payload) {
    api.get(endpoints.productOffers + '/' + payload.productOfferId).then((response) => {
      payload.cb(response.data)
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    })
  },
  getProductOrderFields (payload) {
    const url = endpoints.products + '/' + payload.productId + '/' + endpoints.productOrderFields + '?page=' + payload.page + '&per_page=' + payload.pageSize
    api.get(url).then((response) => {
      response.data.pagination = parse(response.headers['link'])
      payload.cb(response.data)
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    })
  },
  getProductComplementaries (payload) {
    const url = endpoints.products + '/' + payload.productId + '/' + endpoints.complementaries + '?page=' + payload.page + '&per_page=' + payload.pageSize
    api.get(url).then((response) => {
      response.data.pagination = parse(response.headers['link'])
      payload.cb(response.data)
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    })
  },
  getProductOffersComplementaries (payload) {
    const url = endpoints.productOffers + '/' + payload.productOfferId + '/' + endpoints.complementaries + '?page=' + payload.page + '&per_page=' + payload.pageSize
    api.get(url).then((response) => {
      response.data.pagination = parse(response.headers['link'])
      payload.cb(response.data)
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    })
  }
}
