import api from '@/api/api'
import { IsEmptyOrNull } from '@/utils/utils'
const endpoints = {
  users: 'users',
  sign_in: 'users/sign_in',
  sign_out: 'users/sign_out',
  password: 'users/password',
  me: 'me',
  confirm_request: 'confirmation_requests',
  confirmations: 'confirmations'
}
export default {
  create (payload) {
    api.post(endpoints.users, payload.credentials).then((response) => {
      if (response.status === 200 || response.status === 201) {
        // Save headers informations
        // localStorage.setItem('Access-Token-Clinic', response.headers['access-token'])
        // localStorage.setItem('Client', response.headers['client'])
        // localStorage.setItem('Uid', response.headers['uid'])
        // localStorage.setItem('Token-Type', response.headers['Token-Type'])
        // localStorage.setItem('Expiry', response.headers['expiry'])
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.response.data.errors)
        }
      }
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error.response.data)
      }
    })
  },
  sendConfirmationEmail (payload) {
    const endpoint = endpoints.confirm_request
    api.post(endpoint, payload.params).then((response) => {
      if (response.status === 200 || response.status === 201) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.response.data.errors)
        }
      }
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error.response.data)
      }
    })
  },
  confirmAccount (payload) {
    const endpoint = endpoints.confirmations
    api.post(endpoint, { confirmation_token: payload.confirmation_token }).then((response) => {
      if (response.status === 200 || response.status === 201) {
        // Save headers informations
        localStorage.setItem('token', response.data.token)
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.response.data.errors)
        }
      }
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error.response.data)
      }
    })
  },
  getUser (payload) {
    api.get(endpoints.me).then((response) => {
      if (response.status === 200 || response.status === 201) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.response.data.errors)
        }
      }
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error.response.data)
      }
    })
  },
  updateUser (payload) {
    api.put(endpoints.me, payload.params).then((response) => {
      if (response.status === 200 || response.status === 201) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.response.data.errors)
        }
      }
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error.response.data)
      }
    })
  },
  logIn (payload) {
    api.post(endpoints.sign_in, payload.credentials).then((response) => {
      if (response.status === 200 || response.status === 201) {
        // Save headers informations
        localStorage.setItem('token', response.data.token)
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.data)
        }
      }
    }).catch(function (error) {
      if (error.response) {
        // Request made and server responded
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.response.data.error)
        }
      } else if (error.request) {
        // The request was made but no response was received
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.request)
        }
      } else {
        // Something happened in setting up the request that triggered an Error
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.message)
        }
      }
    })
  },
  logOut (payload) {
    api.delete(endpoints.sign_out).then((response) => {
      if (response.status === 200 || response.status === 201) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.data)
        }
      }
    }).catch(function (error) {
      if (error.response) {
        // Request made and server responded
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.response.data.error)
        }
      } else if (error.request) {
        // The request was made but no response was received
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.request)
        }
      } else {
        // Something happened in setting up the request that triggered an Error
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.message)
        }
      }
    })
  },
  recoverPassword (payload) {
    api.post(endpoints.password, { email: payload.email }).then((response) => {
      if (response.status === 200 || response.status === 201) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.data)
        }
      }
    }).catch(function (error) {
      if (error.response) {
        // Request made and server responded
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.response.data.error)
        }
      } else if (error.request) {
        // The request was made but no response was received
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.request)
        }
      } else {
        // Something happened in setting up the request that triggered an Error
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.message)
        }
      }
    })
  },
  resetPassword (payload) {
    const params = {
      password: {
        password: payload.password,
        password_confirmation: payload.confirmPassword,
        reset_password_token: payload.token
      }
    }
    api.put(endpoints.password, params).then((response) => {
      if (response.status === 200 || response.status === 201) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.data)
        }
      }
    }).catch(function (error) {
      if (error.response) {
        // Request made and server responded
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.response.data.error)
        }
      } else if (error.request) {
        // The request was made but no response was received
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.request)
        }
      } else {
        // Something happened in setting up the request that triggered an Error
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.message)
        }
      }
    })
  }
}
