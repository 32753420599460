const SET_PRODUCTS = (state, payload) => {
  state.list = payload.products
}

const SET_PRODUCT_CATEGORIES = (state, payload) => {
  state.categories = payload.categories
}

const SET_PRODUCT_MATERIALS = (state, payload) => {
  state.materials = payload.materials
}

export default {
  SET_PRODUCTS,
  SET_PRODUCT_CATEGORIES,
  SET_PRODUCT_MATERIALS
}
