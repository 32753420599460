import api from '@/api/api'
import { IsEmptyOrNull } from '@/utils/utils'
const endpoints = {
  getLabByInvitation: 'invitations/',
  acceptInvitation: 'accepted_invitations/',
  signin: 'laboratories/sign_in',
  laboratories: 'laboratories',
  password: 'laboratories/password',
  orders: 'orders',
  orderItems: 'order_items',
  locations: 'locations'
}
const parse = require('parse-link-header')

export default {
  updateLab (payload) {
    api.put(endpoints.laboratories + '/' + payload.labId, payload.params).then((response) => {
      if (response.status === 200) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.response.data.errors)
        }
      }
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error.response.data)
      }
    })
  },
  // LOCATIONS
  // TO DO PAGINATION??
  getLocations (payload) {
    api.get(endpoints.laboratories + '/' + payload.labId + '/' + endpoints.locations).then((response) => {
      if (response.status === 200) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.response.data.errors)
        }
      }
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error.response.data)
      }
    })
  },
  createLocation (payload) {
    api.post(endpoints.laboratories + '/' + payload.labId + '/' + endpoints.locations, { location: payload.data }).then((response) => {
      if (response.status === 200) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.response.data.errors)
        }
      }
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error.response.data)
      }
    })
  },
  // DEPRECATED?
  getLabByInvitation (payload) {
    const url = endpoints.getLabByInvitation + payload.code + '/laboratory'

    api.get(url).then((response) => {
      // Save headers informations
      if (response.headers['Authorization'] !== null) {
        const tokenArray = esponse.headers['Authorization'].split(' ')
        if (tokenArray.length > 1) {
          localStorage.setItem('token', response.headers['Authorization'].split(' ')[1])
        }
      }
      console.log(response)
      payload.cb(response.data)
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    })
  },
  // DEPRECATED?
  acceptInvitation (payload) {
    const url = endpoints.acceptInvitation + payload.code

    api.put(url).then((response) => {
      payload.cb(response.data)
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    })
  },
  getLabList (payload) {
    const url = endpoints.laboratories + '?page=' + payload.page + '&per_page=' + payload.pageSize

    api.get(url).then((response) => {
      response.data.pagination = parse(response.headers['link'])
      payload.cb(response.data)
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    })
  },
  getLabDetail (payload) {
    const url = endpoints.laboratories + '/' + payload.labId

    api.get(url).then((response) => {
      payload.cb(response.data)
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    })
  },
  getProductOfferByLab (payload) {
    const url = endpoints.laboratories + '/' + payload.labId + '/product_offers?price_in=' + payload.priceIn + '&min_days_to_deliver_in=' + payload.minDaysToDeliverIn + '&max_days_to_deliver_in=' + payload.maxDaysToDeliverIn + '&product_id_in=' + payload.productIdIn + '&page=' + payload.page + '&per_page=' + payload.pageSize

    api.get(url).then((response) => {
      response.data.pagination = parse(response.headers['link'])
      payload.cb(response.data)
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    })
  },
  getLabOrders (payload) {
    let url = endpoints.laboratories + '/' + payload.labId + '/' + endpoints.orders + '?page=' + payload.page + '&per_page=' + payload.pageSize
    if (!IsEmptyOrNull(payload.statusIn)) {
      url = url + '&status_in=' + payload.statusIn
    }
    if (!IsEmptyOrNull(payload.clinicIdIn)) {
      url = url + '&clinic_id_in=' + payload.clinicIdIn
    }

    url = url + '&sorted_by=' + payload.filterSelected + '|' + payload.orderList

    if (!IsEmptyOrNull(payload.query)) {
      url = url + '&q=' + payload.query
    }

    api.get(url).then((response) => {
      response.data.pagination = parse(response.headers['link'])
      payload.cb(response.data)
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    })
  },
  getLabOrderById (payload) {
    const url = endpoints.orders + '/' + payload.orderId

    api.get(url).then((response) => {
      payload.cb(response.data)
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    })
  },
  reviewRequest (payload) {
    const body = {
      review_request: {
        review_request_comment: payload.reviewRequestComment
      }
    }
    api.post(endpoints.orderItems + '/' + payload.orderItemId + '/review_requests', body).then((response) => {
      if (response.status === 200 || response.status === 201) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.data)
        }
      }
    }).catch(function (error) {
      if (error.response) {
        // Request made and server responded
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.response.data.error)
        }
      } else if (error.request) {
        // The request was made but no response was received
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.request)
        }
      } else {
        // Something happened in setting up the request that triggered an Error
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.message)
        }
      }
    })
  },
  createPickUpRequest (payload) {
    const body = {
      pickup_request: {
        order_ids: payload.ordersId
      }
    }

    api.post(endpoints.laboratories + '/' + payload.labId + '/pickup_requests', body).then((response) => {
      if (response.status === 200 || response.status === 201) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.data)
        }
      }
    }).catch(function (error) {
      if (error.response) {
        // Request made and server responded
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.response.data.error)
        }
      } else if (error.request) {
        // The request was made but no response was received
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.request)
        }
      } else {
        // Something happened in setting up the request that triggered an Error
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.message)
        }
      }
    })
  },
  startOrder (payload) {
    const body = {
      notes: payload.comment
    }
    api.post(endpoints.orders + '/' + payload.orderId + '/started', body).then((response) => {
      if (response.status === 200 || response.status === 201) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.data)
        }
      }
    }).catch(function (error) {
      if (error.response) {
        // Request made and server responded
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.response.data.error)
        }
      } else if (error.request) {
        // The request was made but no response was received
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.request)
        }
      } else {
        // Something happened in setting up the request that triggered an Error
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.message)
        }
      }
    })
  },
  processedOrder (payload) {
    const body = {
      notes: payload.comment
    }
    api.post(endpoints.orders + '/' + payload.orderId + '/processed', body).then((response) => {
      if (response.status === 200 || response.status === 201) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.data)
        }
      }
    }).catch(function (error) {
      if (error.response) {
        // Request made and server responded
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.response.data.error)
        }
      } else if (error.request) {
        // The request was made but no response was received
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.request)
        }
      } else {
        // Something happened in setting up the request that triggered an Error
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.message)
        }
      }
    })
  },
  shippedOrder (payload) {
    const body = {
      notes: payload.comment
    }
    api.post(endpoints.orders + '/' + payload.orderId + '/shipped', body).then((response) => {
      if (response.status === 200 || response.status === 201) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.data)
        }
      }
    }).catch(function (error) {
      if (error.response) {
        // Request made and server responded
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.response.data.error)
        }
      } else if (error.request) {
        // The request was made but no response was received
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.request)
        }
      } else {
        // Something happened in setting up the request that triggered an Error
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.message)
        }
      }
    })
  },
  deliveredOrder (payload) {
    const body = {
      notes: payload.comment
    }
    api.post(endpoints.orders + '/' + payload.orderId + '/delivered', body).then((response) => {
      if (response.status === 200 || response.status === 201) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.data)
        }
      }
    }).catch(function (error) {
      if (error.response) {
        // Request made and server responded
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.response.data.error)
        }
      } else if (error.request) {
        // The request was made but no response was received
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.request)
        }
      } else {
        // Something happened in setting up the request that triggered an Error
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.message)
        }
      }
    })
  },
  archivedOrder (payload) {
    const body = {
      notes: payload.comment
    }
    api.post(endpoints.orders + '/' + payload.orderId + '/archived', body).then((response) => {
      if (response.status === 200 || response.status === 201) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.data)
        }
      }
    }).catch(function (error) {
      if (error.response) {
        // Request made and server responded
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.response.data.error)
        }
      } else if (error.request) {
        // The request was made but no response was received
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.request)
        }
      } else {
        // Something happened in setting up the request that triggered an Error
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.message)
        }
      }
    })
  },
  cancelledOrder (payload) {
    const body = {
      notes: payload.comment
    }
    api.post(endpoints.orders + '/' + payload.orderId + '/cancelled', body).then((response) => {
      if (response.status === 200 || response.status === 201) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.data)
        }
      }
    }).catch(function (error) {
      if (error.response) {
        // Request made and server responded
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.response.data.error)
        }
      } else if (error.request) {
        // The request was made but no response was received
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.request)
        }
      } else {
        // Something happened in setting up the request that triggered an Error
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.message)
        }
      }
    })
  },
  // EXTRAS
  deleteOrderItem (payload) {
    const endpoint = endpoints.orderItems + '/' + payload.orderItemId
    api.delete(endpoint).then((response) => {
      if (response.status === 200) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.response.data.errors)
        }
      }
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error.response.data)
      }
    })
  },
  createOrderItem (payload) {
    const body = {
      order_item: {
        product_offer_id: payload.productOfferId
      }
    }
    api.post(endpoints.orders + '/' + payload.orderItemId + '/order_items', body).then((response) => {
      if (response.status === 200 || response.status === 201) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.data)
        }
      }
    }).catch(function (error) {
      if (error.response) {
        // Request made and server responded
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.response.data.error)
        }
      } else if (error.request) {
        // The request was made but no response was received
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.request)
        }
      } else {
        // Something happened in setting up the request that triggered an Error
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.message)
        }
      }
    })
  },
  getStreamChatToken (payload) {
    const endpoint = endpoints.laboratories + '/' + payload.labId + '/chat_tokens'
    api.post(endpoint, {}).then((response) => {
      if (response.status === 200) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.response.data.errors)
        }
      }
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error.response.data)
      }
    })
  }
}
