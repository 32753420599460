<template>
  <div id="log-in-register-modal" class="log-in-register-modal">
    <clinic-access :callback="closeCb" :no-bg="true"></clinic-access>
  </div>
</template>

<script>
import ClinicAccess from '@/components/Clinic/ClinicAccess.vue'
import {
  IsEmptyOrNull
} from '@/utils/utils.js'

export default {
  props: {
    modalOptions: Object
  },
  components: {
    'clinic-access': ClinicAccess
  },
  data () {
    return {
    }
  },
  methods: {
    closeCb () {
      this.$store.commit({
        type: 'application/CLOSE_MODAL'
      })
      if (!IsEmptyOrNull(this.modalOptions.closeCb)) {
        this.modalOptions.closeCb()
      }
    }
  }
}
</script>

<style lang="scss">
.log-in-register-modal{
  width: 100%;
  margin-bottom: 76px;
  hr{
    margin-top: 0px;
    display: none;
  }
  .log-in-column{
    padding: 0 46px;
    border-right: 1px solid #57567C;
    form{
      .recover-pwd{
        float: right;
        text-decoration: underline;
      }
      button{
        float: left;
        margin-top: 48px;
      }
    }
  }
  .register-column{
    padding: 0 46px;
  }
}

@media screen and (max-width: 1000px) {
  .log-in-register-modal{
    hr{
      display: block;
    }
    .columns{
      flex-direction: column;
      overflow: hidden;
      .column{
        float: left;
        margin-left: 0px;
        width: 100%;
        &:first-child{
          margin-bottom: 35px;
        }
      }
    }
  }
}
</style>
