<template>
  <div class="column is-5 is-offset-1 login-wrap">
    <h4 class="title has-text-centered pb-5">Inicia sesión</h4>
    <form class="overflow-h" onsubmit="return false">
      <div class="field mb-5">
        <input-text :placeholder="'Email*'" v-model="emailLogin" :name="'email-login'"></input-text>
      </div>
      <div class="field mb-5">
        <input-text :placeholder="'Contraseña*'" v-model="passwordLogin" :name="'password-log-in'" :type="'password'"></input-text>
      </div>
      <div class="custom-switch">
        <input type="checkbox" id="switch" v-model="rememberMe"/><label class="toggle" for="switch">Toggle</label>
        <label class="switch-label">
          Recuérdame
        </label>
      </div>
      <router-link class="recover-pwd" :to="'/clinic/recover-password'" exact>Recupera la contraseña</router-link>
      <div class="field">
        <p class="control">
          <button class="button is-primary is-fullwidth is-medium mt-4" @click="logIn()">
            Iniciar sesión
          </button>
        </p>
      </div>
    </form>
    <div class="error-login-wrap" v-if="errorMessage !== null">
      <p class="error-msg" v-html="errorMessage"></p>
      <p>¿No encuentras el correo electrónico de confirmación?</p>
      <button class="button is-primary is-fullwidth mt-2" @click="sendConfirmationEmail()">Envialo de nuevo</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import InputText from '@/components/FormElements/InputText.vue'
import {
  IsEmptyOrNull
} from '@/utils/utils.js'

export default {
  name: 'ClinicLogin',
  props: {
    callback: {
      type: Function,
      default: null
    }
  },
  components: {
    InputText
  },
  computed: {
    ...mapGetters({
      clinic: 'user/clinic'
    })
  },
  data () {
    return {
      errorMessage: null,
      emailLogin: '',
      passwordLogin: '',
      rememberMe: false
    }
  },
  methods: {
    sendConfirmationEmail () {
      const self = this
      this.$store.dispatch('user/sendConfirmationEmail', {
        params: {
          email: this.emailLogin
        },
        cb: () => {
          console.log('Email inviata')
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'success',
              message: 'Se envió un correo electrónico de confirmación a su cuenta en ' + self.emailLogin + '.'
            }
          })
        },
        cbError: (error) => {
          self.errorMessage = '¡Error!'
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error
            }
          })
        }
      })
    },
    logIn () {
      const self = this
      if (this.rememberMe) {
        localStorage.setItem('remember-email-clinic', this.emailLogin)
      } else {
        localStorage.removeItem('remember-email-clinic')
      }
      this.errorMessage = null
      this.$store.dispatch('user/logIn', {
        credentials: {
          email: this.emailLogin,
          password: this.passwordLogin
        },
        cb: () => {
          console.log('BIENVENIDO')
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'success',
              message: 'Bienvenido!'
            }
          })
          self.$store.dispatch('user/getUser', {
            clinicId: this.clinic.id,
            cb: () => {
              self.$store.dispatch('clinic/getShoppingCart', {
                cb: (response) => {}
              })
              if (IsEmptyOrNull(self.callback)) {
                self.goTo('/clinic/personal-informations')
              } else {
                self.callback()
              }
            },
            cbError: (error) => {
              console.log(error)
              self.$store.commit({
                type: 'application/ADD_FEEDBACK',
                feedback: {
                  type: 'error',
                  message: error.response.data.error
                }
              })
            }
          })
        },
        cbError: (error) => {
          self.errorMessage = '¡Error! Se envió un correo electrónico de confirmación a su cuenta en ' + self.emailLogin + '. Debe seguir las instrucciones del correo electrónico antes de poder activar su cuenta'
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error
            }
          })
        }
      })
    },
    goTo (path) {
      this.$router.push({ path: path })
    }
  },
  created () {
    const emailSaved = localStorage.getItem('remember-email-clinic')
    if (emailSaved) {
      this.emailLogin = emailSaved
      this.rememberMe = true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.login-wrap{
  padding: 0px 60px;
  padding-top: 24px;
  border-right: 1px solid #57567C;
  form{
    .recover-pwd{
      float: right;
      text-decoration: underline;
    }
    button{
      float: left;
      margin-top: 48px;
    }
  }
  .error-login-wrap{
    position: relative;
    top: 14px;
    p{
      padding: 7px;
      &.errorMessage{
        color: red;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .login-wrap{
    border-right: 0px;
    overflow: hidden;
    form{
      button{
        display: block;
        line-height: 0px;
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .login-wrap{
    padding: 0 14px;
  }
}
</style>
