<template>
  <nav class="navbar" v-bind:class="[hasHeroImage ? 'is-transparent' : 'is-primary', isFixedTop ? 'is-fixed-top' : '', isScrolled ? 'has-bg' : '', isSimpleHeader ? 'is-simple-header' : '']" role="navigation" aria-label="main navigation">
    <div class="nav-bg"></div>
    <div class="container">
      <div class="navbar-brand" v-bind:class="{'abs-centered': isSimpleHeader}">
        <button class="navbar-burger" aria-label="menu" aria-expanded="false" @click="toggleMobileMenu()" v-if="!isSimpleHeader">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </button>
        <router-link class="navbar-item" to="/">
          <img alt="STLLAB Logo" src="../assets/logo.svg">
        </router-link>
      </div>
      <div id="mainNavbar" class="navbar-menu abs-centered" v-if="!isSimpleHeader">
        <div class="navbar-start centered">
          <router-link v-if="!isLabLoggedIn" class="navbar-item" to="/products">
            <span>productos</span>
          </router-link>
          <router-link v-if="isLabLoggedIn" class="navbar-item" to="/laboratory/orders">
            <span>tus pedidos</span>
          </router-link>
          <router-link v-if="isLabLoggedIn" class="navbar-item" to="/laboratory/products">
            <span>tus productos</span>
          </router-link>
          <!-- <router-link class="navbar-item" to="/laboratories">
            <span>laboratorios</span>
          </router-link> -->

          <router-link v-if="isClinicLoggedIn" class="navbar-item" to="/clinic/orders">
            <span>tus pedidos</span>
          </router-link>

          <router-link v-if="isClinicLoggedIn" class="navbar-item" to="/clinic/favourites">
            <span>favoritos</span>
          </router-link>

          <router-link class="navbar-item" to="/about">
            <span>acerca de nosotros</span>
          </router-link>

          <router-link v-if="!isLoggedIn" class="navbar-item" to="/contacts">
            <span>contacta</span>
          </router-link>
        </div>
      </div>

      <div class="navbar-end" v-if="!isSimpleHeader">
        <div class="navbar-item">
          <div class="log-wrap" v-bind:class="{'logged': isLoggedIn, 'open': isUserMenuOpen}" @click="toggleUserMenu()">
            <span v-if="isLoggedIn">Hola, {{user.first_name}} </span>
            <button class="navbar-icon-button" v-bind:class="{'open': isUserMenuOpen}">
              <span class="icon-account" v-if="!isLoggedIn"></span>
              <span class="icon-chevron-up" v-if="isLoggedIn && isUserMenuOpen"></span>
              <span class="icon-chevron-down" v-if="isLoggedIn && !isUserMenuOpen"></span>
            </button>
          </div>
          <div class="user-menu-overlay" v-if="isUserMenuOpen"></div>
          <transition name="fade-scale">
            <div class="user-menu-wrap" v-if="isUserMenuOpen && !isLoggedIn">
              <button class="button is-fullwidth is-primary mb-3" @click="goTo('/clinic/login')">CLINIC ACCESS</button>
              <button class="button is-fullwidth is-primary is-empty" @click="goTo('/laboratory/login')">LABORATORY ACCESS</button>
            </div>
          </transition>
          <transition name="fade-scale">
            <div class="user-menu-wrap" v-if="isUserMenuOpen && isLabLoggedIn">
              <router-link class="mb-3" :to="'/laboratory/info-laboratory'" exact>Info del laboratorio</router-link>
              <router-link class="mb-3" :to="'/laboratory/orders'" exact>Tus pedidos</router-link>
              <router-link class="mb-3" :to="'/laboratory/products'" exact>Tus productos</router-link>
              <router-link class="mb-3" :to="'/laboratory/payment-details'" exact>Detalles de pago</router-link>
              <router-link class="mb-5" to="/contacts" exact>contacta</router-link>
              <button class="button is-fullwidth is-primary is-empty" @click="logOut()">Cerrar sessión</button>
            </div>
          </transition>
          <transition name="fade-scale">
            <div class="user-menu-wrap" v-if="isUserMenuOpen && isClinicLoggedIn">
              <router-link class="mb-3" :to="'/clinic/personal-informations'" exact>Info personales</router-link>
              <router-link class="mb-3" :to="'/clinic/addresses'" exact>Direcciones</router-link>
              <router-link class="mb-3" :to="'/clinic/my-cards'" exact>Detalles de pago</router-link>
              <router-link class="mb-3" :to="'/clinic/orders'" exact>Tus pedidos</router-link>
              <router-link class="mb-3" :to="'/clinic/favourites'" exact>Favoritos</router-link>
              <router-link class="mb-5" to="/contacts" exact>contacta</router-link>
              <button class="button is-fullwidth is-primary is-empty" @click="logOut()">Cerrar sessión</button>
            </div>
          </transition>
        </div>
        <div class="navbar-item">
          <button class="navbar-icon-button" @click="goToCart()" :disabled="shoppingCart.items.length === 0">
            <span class="icon-cart"></span>
            <template v-if="isClinicLoggedIn">
              <span class="cart-item-count" v-if="shoppingCart.items.length > 0">{{cartItemsCount}}</span>
            </template>
          </button>
        </div>
        <div class="navbar-item">
          <button class="navbar-icon-button" @click="goTo('/checkout/login')">
            <span class="icon-search"></span>
          </button>
        </div>
      </div>
    </div>
    <transition name="fade-scale">
      <div class="mobile-menu-wrap" v-if="isMobileMenuOpen">
        <div class="mobile-menu abs-centered" v-if="!isLoggedIn">
          <router-link v-if="!isLabLoggedIn" class="navbar-item has-text-weight-bold is-uppercase title is-4" to="/products">
            <span>productos</span>
          </router-link>
          <router-link v-if="isLabLoggedIn" class="navbar-item has-text-weight-bold is-uppercase title is-4" to="/laboratory/products">
            <span>tus productos</span>
          </router-link>
          <!-- <router-link class="navbar-item has-text-weight-bold is-uppercase title is-4" to="/laboratories">
            <span>laboratorios</span>
          </router-link> -->
          <router-link class="navbar-item has-text-weight-bold is-uppercase title is-4" to="/about">
            <span>acerca de nosotros</span>
          </router-link>

          <router-link class="navbar-item has-text-weight-bold is-uppercase title is-4" to="/contacts">
            <span>contacta</span>
          </router-link>
        </div>
        <!-- Lab menu -->
        <div class="mobile-menu abs-centered" v-if="isLabLoggedIn">
          <router-link class="navbar-item has-text-weight-bold is-uppercase title is-4" :to="'/laboratory/info-laboratory'" exact><span>Info del laboratorio</span></router-link>
          <router-link class="navbar-item has-text-weight-bold is-uppercase title is-4" :to="'/laboratory/products'" exact><span>Tus productos</span></router-link>
          <router-link class="navbar-item has-text-weight-bold is-uppercase title is-4" :to="'/laboratory/orders'" exact><span>Tus pedidos</span></router-link>
          <router-link class="navbar-item has-text-weight-bold is-uppercase title is-4" :to="'/laboratory/payment-details'" exact><span>Detalles de pago</span></router-link>
          <router-link class="navbar-item has-text-weight-bold is-uppercase title is-4" to="/contacts" exact><span>contacta</span></router-link>
          <button class="button is-fullwidth is-primary is-empty" @click="logOut()">Cerrar sessión</button>
        </div>
        <!-- Clinic menu -->
        <div class="mobile-menu abs-centered" v-if="isClinicLoggedIn">
          <router-link class="navbar-item has-text-weight-bold is-uppercase title is-4" to="/products"><span>productos</span></router-link>
          <router-link class="navbar-item has-text-weight-bold is-uppercase title is-4" :to="'/clinic/personal-informations'" exact><span>Info personales</span></router-link>
          <router-link class="navbar-item has-text-weight-bold is-uppercase title is-4" :to="'/clinic/addresses'" exact><span>Direcciones</span></router-link>
          <router-link class="navbar-item has-text-weight-bold is-uppercase title is-4" :to="'/clinic/my-cards'" exact><span>Detalles de pago</span></router-link>
          <router-link class="navbar-item has-text-weight-bold is-uppercase title is-4" :to="'/clinic/order-history'" exact><span>Tus pedidos</span></router-link>
          <router-link class="navbar-item has-text-weight-bold is-uppercase title is-4" :to="'/clinic/favourites'" exact><span>Favoritos</span></router-link>
          <router-link class="navbar-item has-text-weight-bold is-uppercase title is-4" to="/contacts" exact><span>contacta</span></router-link>
          <button class="button is-fullwidth is-primary is-empty" @click="logOut()">Cerrar sessión</button>
        </div>

        <div class="social-buttons-wrap">
          <a class="icon-btn" href="#">
            <span class="icon-facebook"></span>
          </a>
          <a class="icon-btn" href="#">
            <span class="icon-instagram"></span>
          </a>
        </div>
      </div>
    </transition>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'HeaderNav',
  props: {
    hasHeroImage: Boolean,
    isSimpleHeader: Boolean
  },
  data () {
    return {
      isFixedTop: true,
      isScrolled: false,
      lastKnownScrollPosition: 0,
      ticking: false,
      isUserMenuOpen: false,
      isMobileMenuOpen: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      isLoggedIn: 'user/isLoggedIn',
      isClinicLoggedIn: 'user/isClinicLoggedIn',
      isLabLoggedIn: 'user/isLabLoggedIn',
      shoppingCart: 'clinic/shoppingCart'
    }),
    cartItemsCount () {
      let count = 0
      for (let i = 0; i < this.shoppingCart.items.length; i++) {
        if (!this.shoppingCart.items[i].complementary && !this.shoppingCart.items[i].extra) {
          count++
        }
      }
      return count
    }
  },
  watch: {
    $route (newValue) {
      this.isMobileMenuOpen = false
      this.isUserMenuOpen = false
    }
  },
  methods: {
    logOut () {
      const self = this
      this.$store.dispatch('user/logOut', {
        cb: () => {
          self.goTo('/')
        },
        cbError: () => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: 'Error on the log out'
            }
          })
        }
      })
    },
    toggleMobileMenu () {
      this.isMobileMenuOpen = !this.isMobileMenuOpen
    },
    toggleUserMenu () {
      this.isUserMenuOpen = !this.isUserMenuOpen
    },
    goToCart () {
      if (this.isClinicLoggedIn) {
        this.goTo('/checkout')
      } else {
        this.goTo('/checkout/login')
      }
    },
    goTo (path) {
      this.isMobileMenuOpen = false
      this.isUserMenuOpen = false
      this.$router.push({ path: path })
    }
  },
  created () {
    const self = this
    if (window.addEventListener) {
      window.addEventListener('scroll', function (e) {
        self.lastKnownScrollPosition = window.scrollY
        if (!self.ticking) {
          window.requestAnimationFrame(function () {
            if (self.lastKnownScrollPosition > 90) {
              self.isScrolled = true
            } else {
              self.isScrolled = false
            }
            self.ticking = false
          })
          self.ticking = true
        }
      })
    } else {
      window.attachEvent('onscroll', function (e) {
        self.lastKnownScrollPosition = window.scrollY
        if (!self.ticking) {
          window.requestAnimationFrame(function () {
            if (self.lastKnownScrollPosition > 90) {
              self.isScrolled = true
            } else {
              self.isScrolled = false
            }
            self.ticking = false
          })
          self.ticking = true
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.navbar{
  height: 70px;
  background-color: transaprent;
  transition: all .4s ease-out;
  border-bottom: 1px solid rgba(255,255,255,.5);
  &.is-simple-header{
    border-bottom: 0px;
  }
  &.has-bg{
    .nav-bg{
      opacity: 1;
    }
  }
  &.is-fixed-top{
    z-index: 999999999;
  }
  .mobile-menu-wrap{
    position: fixed;
    top: 50px;
    left: 0px;
    width: 100%;
    height: calc(100% - 50px);
    background-color: #57567C;
    z-index: 999999999;
    text-align: center;
    .mobile-menu{
      position: absolute;
      top: 50%;
      width: 100%;
      transform: translate(0, -50%);
      .navbar-item{
        color: #FFF;
        &:hover{
          background-color: #525174;
        }
        span{
          position: relative;
          color: #FFF;
          &:after{
            content: "";
            display: block;
            position: absolute;
            bottom: 0px;
            left: 50%;
            transform: translate(-50%, 0);
            width: 0%;
            height: 2px;
            background-color: #E3914F;
            transition: all 0.3s ease-out;
          }
        }
        &.router-link-active{
          span{
            color: #E3914F!important;
            &:after{
              width: 100%;
            }
          }
        }
      }
    }
    .social-buttons-wrap{
      position: absolute;
      bottom: 28px;
      width: 100%;
      .icon-btn{
        font-size: 1.5rem;
        margin: 0 10px;
      }
    }
  }
  .nav-bg{
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1;
    height: 100%;
    width: 100%;
    background-color: #57567C;
    opacity: 0;
    transition: opacity .4s ease-out;
  }
  .navbar-brand {
    &.abs-centered{
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      height: 100%;
    }
  }
  .navbar-menu{
    .navbar-item, .navbar-link{
      position: relative;
      color: #FFF;
      font-weight: bold;
      text-transform: uppercase;
      span{
        position: relative;
        &:after{
          content: "";
          display: block;
          position: absolute;
          bottom: 0px;
          left: 50%;
          transform: translate(-50%, 0);
          width: 0%;
          height: 2px;
          background-color: #E3914F;
          transition: all 0.3s ease-out;
        }
      }
      &.router-link-active{
        span{
          color: #E3914F!important;
          &:after{
            width: 100%;
          }
        }
      }
      &:hover{
        background-color: transparent!important;
        span{
          &:after{
            width: 100%;
          }
        }
      }
    }
    &.abs-centered{
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      height: 100%;
    }
  }
  .navbar-end{
    .navbar-item{
      position: relative;
      .log-wrap{
        &.logged{
          position: relative;
          // background-color: #FFFFFF;
          padding: 7px;
          border-radius: 15px;
          cursor: pointer;
          // box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
          transition: all .4s ease-out;
          &:hover{
            // box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
          }
          span{
            color: #FFF;
            // color: #E3914F;
          }
          .navbar-icon-button{
            // color: #E3914F;
            span{
              // color: #E3914F;
            }
          }
        }
        &.open{
          &::after{
            content: "";
            position: absolute;
            top: calc(100% - 5px);
            left: 0px;
            width: 100%;
            height: 4px;
            border-radius: 3px;
            background-color: #E3914F;
          }
        }
      }
      .navbar-icon-button{
        position: relative;
        border: 0px;
        background-color: transparent;
        cursor: pointer;
        span{
          color: #FFF;
        }
        .cart-item-count{
          position: absolute;
          top: -10px;
          left: -10px;
          width: 20px;
          height: 20px;
          text-align: center;
          line-height: 20px;
          background-color: #E3914F;
          border-radius: 50%;
          color: #FFFFFF;
        }
        &.open{
          &::after{
            content: "";
            position: absolute;
            top: calc(100% + 3px);
            left: 0px;
            width: 100%;
            height: 4px;
            border-radius: 3px;
            background-color: #E3914F;
          }
        }
      }
      .user-menu-wrap{
        position: absolute;
        top: 73%;
        left: 50%;
        transform: translate(-50%, 0);
        width: 250px;
        padding: 25px;
        border-radius: 4px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 44px 0 rgba(0,0,0,0.3);
        button{
          &.is-empty{
            background-color: transparent;
            color: #57567C;
            border-color: #57567C;
          }
        }
        a{
          text-align: center;
          display: block;
          text-decoration: underline;
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .navbar{
    .container{
      .navbar-brand {
        padding-left: 14px;
      }
      .navbar-end{
        padding-right: 14px;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .navbar{
    border-bottom: 0px;
    height: 50px;
    min-height: auto;
    .nav-bg{
      opacity: 1!important;
    }
    .container{
      height: 100%;
      min-height: auto;
      .navbar-brand {
        float: left;
        height: 100%;
        padding-left: 0px;
        min-height: auto;
        .navbar-burger{
          height: 50px;
          width: 50px;
          background: transparent;
          border: 0px;
          span{
            color: white!important;
          }
        }
        a{
          height: 45px;
        }
      }
      .navbar-menu{
        display: none;
      }
      .navbar-end{
        float: right;
        height: 100%;
        padding-right: 0px;
        .navbar-item{
          float: left;
          height: 50px;
          // width: 50px;
          display: flex;
          align-content: center;
          .navbar-icon-button{
            height: 100%;
            // width: 100%;
            span{
              // font-size: 1.4rem;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 450px) {
  .navbar{
    .navbar-end{
      .navbar-item{
        .user-menu-overlay{
          position: fixed;
          top: 50px;
          left: 0px;
          width: 100%;
          height: calc(100% - 50px);
          z-index: 998;
          background-color: #F6F4EF;
        }
        .user-menu-wrap{
          position: fixed;
          width: 100%;
          top: 50px;
          z-index: 999;
        }
      }
    }
  }
}
</style>
