<template>
  <div class="select-wrap">
    <h4 class="title is-6">
      {{name}} <span class="mandatory-label" v-if="isRequired">* obligatorio</span>
    </h4>
    <div class="field">
      <select v-model="selected" @change="setSelectValue($event)" :disabled="disabled">
        <option :value="''">Seleccione</option>
        <template v-for="(item, index) in list">
          <option :value="index" v-bind:key="index">{{item}}</option>
        </template>
      </select>
    </div>
  </div>
</template>

<script>
// import { IsEmptyOrNull } from '@/utils/utils'
export default {
  name: 'Select',
  props: {
    name: {
      type: String
    },
    selectedValue: {
      type: Array
    },
    list: {
      type: Array
    },
    index: {
      type: Number
    },
    isPrimary: {
      type: Boolean,
      default: true
    },
    isRounded: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selected: '',
      hasFocus: false
    }
  },
  methods: {
    setSelectValue (e) {
      this.$emit('updateSelect', {
        indexField: this.index,
        indexOption: this.selected,
        value: e.srcElement.checked
      })
    }
  },
  created () {
    this.selected = this.selectedValue[0]
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
