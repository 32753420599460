<template>
  <div class="radio-wrap">
    <h4 class="title is-6">
      <div class="custom-switch" v-if="!isRequired">
        <input type="checkbox" :id="'switch-pontico-field-' + index" v-model="isActive" :disabled="disabled"/><label class="toggle" :for="'switch-pontico-field-' + index">Toggle</label>
      </div>
      {{name}} <span class="mandatory-label" v-if="isRequired">* obligatorio</span>
    </h4>
    <div class="field">
      <template v-for="(item, index) in list">
        <label class="radio" v-bind:key="index">
          <input type="radio" v-model="selected" :value="index" :disabled="!isActive || disabled"/>
          {{item}}
        </label>
      </template>
    </div>
  </div>
</template>

<script>
import { IsEmptyOrNull } from '@/utils/utils'
export default {
  name: 'Radio',
  props: {
    name: {
      type: String
    },
    selectedValue: {
      type: Array
    },
    list: {
      type: Array
    },
    index: {
      type: Number
    },
    isPrimary: {
      type: Boolean,
      default: true
    },
    isRounded: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selected: null,
      hasFocus: false,
      isActive: false
    }
  },
  watch: {
    isActive (newValue) {
      if (!newValue) {
        this.$emit('clearFieldValueRadio', {
          indexField: this.index
        })
        this.selected = null
      }
    },
    selected (newValue) {
      this.$emit('updateRadio', {
        indexField: this.index,
        indexRadio: newValue
      })
    }
  },
  created () {
    if (!IsEmptyOrNull(this.selectedValue[0]) || this.isRequired) {
      this.isActive = true
    }
    this.selected = this.selectedValue[0]
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .radio-wrap{
    .title{
      line-height: 30px;
      text-transform: uppercase;
      .custom-switch{
        width: auto;
        margin-right: 21px;
      }
    }
  }
</style>
