<template>
  <div id="order-reject-modal" class="order-reject-modal">
    <p class="has-text-centered">Añadir comentarios al rechazo de la pedido</p>
    <!-- <textarea></textarea> -->
    <div class="comment-wrap">
      <p><strong class="is-uppercase">Mensaje</strong></p>
      <p>
        Cras justo odio, dapibus ac facilisis in, egestas eget quam. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Vestibulum id ligula porta felis euismod semper. Donec sed odio dui. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Curabitur blandit tempus porttitor. Nullam id dolor id nibh ultricies vehicula ut id elit.
      </p>
    </div>
    <div class="columns btn-wrap">
      <div class="column is-12">
        <button v-if="!modalOptions.isUpdate" class="button is-primary is-fullwidth is-medium mt-5">
          Rechazar el pedido
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modalOptions: Object
  },
  methods: {
  }
}
</script>

<style lang="scss">
.order-reject-modal{
  width: 100%;
  margin-bottom: 76px;
  padding: 0 76px;
  textarea, .comment-wrap{
    width: 100%;
    box-sizing: border-box;
    height: 150px;
    border: 1px solid rgba(87,86,124,0.5);
    border-radius: 4px;
  }
  .comment-wrap{
    height: auto;
    padding: 16px;
  }
}
</style>
