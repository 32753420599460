const SET_LOCATIONS = (state, payload) => {
  state.locations = payload.locations
}

const ADD_LOCATION = (state, payload) => {
  state.locations.push(payload.location)
}

const UPDATE_LOCATION = (state, payload) => {
  for (let i = 0; i < state.locations.length; i++) {
    if (state.locations[i].id === payload.location.id) {
      state.locations[i] = payload.location
      break
    }
  }
}

const DELETE_LOCATION = (state, payload) => {
  for (let i = 0; i < state.locations.length; i++) {
    if (state.locations[i].id === payload.locationId) {
      state.locations.splice(i, 1)
      break
    }
  }
}

const SET_SHOPPING_CART = (state, payload) => {
  state.shoppingCart = payload.shoppingCart
  localStorage.setItem('shoppingCart', JSON.stringify(state.shoppingCart))
}

const ADD_SHOPPING_CART_ITEM = (state, payload) => {
  state.shoppingCart.items.push(payload.item)
  localStorage.setItem('shoppingCart', JSON.stringify(state.shoppingCart))
}

const UPDATE_SHOPPING_CART_ITEM = (state, payload) => {
  for (let i = 0; i < state.shoppingCart.items.length; ++i) {
    if (state.shoppingCart.items[i].id === payload.cartItem.id) {
      state.shoppingCart.items.splice(i, 1, payload.cartItem)
      break
    }
  }
  localStorage.setItem('shoppingCart', JSON.stringify(state.shoppingCart))
}

const REMOVE_SHOPPING_CART_ITEM = (state, payload) => {
  for (let i = 0; i < state.shoppingCart.items.length; ++i) {
    if (state.shoppingCart.items[i].id === payload.id) {
      state.shoppingCart.items.splice(i, 1)
      break
    }
  }
  localStorage.setItem('shoppingCart', JSON.stringify(state.shoppingCart))
}

const SET_CURRENT_ORDER_SHIPMENTS = (state, payload) => {
  state.currentOrder.shipping_location_id = payload.currentOrder.shipping_location_id
  state.currentOrder.billing_location_id = payload.currentOrder.billing_location_id
  state.currentOrder.pickup_location_id = payload.currentOrder.pickup_location_id
  localStorage.setItem('currentOrder', JSON.stringify(state.currentOrder))
}

const CLEAR_SHOPPING_CART = (state) => {
  state.shoppingCart = { items: [] }
  localStorage.setItem('shoppingCart', JSON.stringify(state.shoppingCart))
  state.currentOrder.shipping_location_id = null
  state.currentOrder.billing_location_id = null
  state.currentOrder.pickup_location_id = null
  localStorage.setItem('currentOrder', JSON.stringify(state.currentOrder))
}

export default {
  SET_LOCATIONS,
  ADD_LOCATION,
  UPDATE_LOCATION,
  DELETE_LOCATION,
  SET_SHOPPING_CART,
  ADD_SHOPPING_CART_ITEM,
  UPDATE_SHOPPING_CART_ITEM,
  REMOVE_SHOPPING_CART_ITEM,
  SET_CURRENT_ORDER_SHIPMENTS,
  CLEAR_SHOPPING_CART
}
