<template>
  <div class="checkbox-wrap">
    <h4 class="title is-6">
      {{name}} <span class="mandatory-label" v-if="isRequired">* obligatorio</span> <span v-bind:class="{'has-text-danger': selectedValueLocal.length === maxFdiSelections}">({{selectedValueLocal.length}}/{{maxFdiSelections}})</span>
    </h4>
    <p class="mb-4">TO TRANSLATE --> Haga clic en los dientes para seleccionarlos</p>
    <div class="field">
      <div class="columns teeth-wrapper is-flex-wrap-wrap">
        <template v-for="(teethQuadrant, indexQ) in teeth">
          <div class="column is-6 area-wrapper" v-bind:key="indexQ">
            <template v-for="tooth in teethQuadrant.q">
              <div v-bind:key="tooth[0]" :id="type + '-wrapper-teeth-' + tooth[0]" class="wrapper-teeth" v-bind:class="{'selected': isSelected(tooth[0])}">

                <span class="icon icon-implante" v-if="tooth[1] === 'implante'" v-bind:class="{'below': indexQ === 2 || indexQ === 3}"></span>
                <span class="icon icon-pilar" v-if="tooth[1] === 'pilar'" v-bind:class="{'below': indexQ === 2 || indexQ === 3}"></span>
                <span class="icon icon-pontico" v-if="tooth[1] === 'pontico'" v-bind:class="{'below': indexQ === 2 || indexQ === 3}"></span>

                <div :id="type + '-tooth-' + tooth[0]" :class="'tooth t' + tooth[0]" @click="openTypeSelector(tooth)">
                  <label>{{tooth[0]}}</label>
                </div>
                <div :id="type + '-type-selector-' + tooth[0]" class="type-selector" v-bind:class="{
                  'right-arrow': tooth[0] !== '17' && tooth[0] !== '18' && tooth[0] !== '47' && tooth[0] !== '48',
                  'left-arrow': tooth[0] === '17' || tooth[0] === '18' || tooth[0] === '47' || tooth[0] === '48',
                }">
                  <div class="field">
                    <label class="radio" v-if="selectedValueLocal.length < maxFdiSelections">
                      <input type="radio" v-model="tooth[1]" :value="valuesType[0]" @change="setCheckboxValue($event, tooth, valuesType[0])" :disabled="disabled"/>
                      {{valuesType[0]}}
                    </label>
                    <label class="radio" v-if="selectedValueLocal.length < maxFdiSelections">
                      <input type="radio" v-model="tooth[1]" :value="valuesType[1]" @change="setCheckboxValue($event, tooth, valuesType[1])" :disabled="disabled"/>
                      {{valuesType[1]}}
                    </label>
                    <label class="radio" v-if="(tooth[1] !== null && tooth[1] !== 'none') || selectedValueLocal.length >= maxFdiSelections">
                      <input type="radio" v-model="tooth[1]" :value="valuesType[2]" @change="setCheckboxValue($event, tooth, valuesType[2])" :disabled="disabled"/>
                      {{valuesType[2]}}
                    </label>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {
  IsEmptyOrNull,
  AddClassById,
  HasClassById,
  RemoveClassBySelector
} from '@/utils/utils'
export default {
  name: 'TeethPosition',
  props: {
    name: {
      type: String
    },
    type: {
      type: String
    },
    selectedValue: {
      type: Array
    },
    list: {
      type: Array
    },
    index: {
      type: Number
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    maxFdiSelections: {
      type: Number
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      valuesType: [
        'implante',
        'pontico',
        'none'
      ],
      hasFocus: false,
      teeth: [
        { q: [] },
        { q: [] },
        { q: [] },
        { q: [] }
      ],
      // TEST
      selectedValueLocal: []
    }
  },
  methods: {
    isSelected (value) {
      for (let i = 0; i < this.selectedValueLocal.length; i++) {
        if (this.selectedValueLocal[i].includes(value.toString())) {
          return true
        }
      }

      return false
    },
    getSelectedToothType (value) {
      for (let i = 0; i < this.selectedValueLocal.length; i++) {
        if (this.selectedValueLocal[i].includes(value.toString())) {
          return this.selectedValueLocal[i][1]
        }
      }

      return 'none'
    },
    openTypeSelector (tooth) {
      if (!this.disabled) {
        if (!HasClassById(this.type + '-wrapper-teeth-' + tooth[0], 'selected') && this.selectedValueLocal.length >= this.maxFdiSelections) {
          return
        }
        RemoveClassBySelector('.wrapper-teeth', 'open')
        AddClassById(this.type + '-wrapper-teeth-' + tooth[0], 'open')
      }
    },
    setCheckboxValue (e, tooth, type) {
      let isPresent = false

      for (let i = 0; i < this.selectedValueLocal.length; i++) {
        if (this.selectedValueLocal[i].includes(tooth[0].toString())) {
          if (type === 'none') {
            this.selectedValueLocal.splice(i, 1)
          }
          isPresent = true
          break
        }
      }

      if (!isPresent && this.selectedValueLocal.length < this.maxFdiSelections) {
        const stringTooth = JSON.stringify(tooth)
        this.selectedValueLocal.push(stringTooth)
      }

      this.$emit('updateCheckbox', {
        indexField: this.index,
        value: this.selectedValueLocal
      })

      RemoveClassBySelector('.wrapper-teeth', 'open')
    },
    onInput (event) {
      // Can add validation here
      this.$emit('input', event.target.value)
      // On component add v-on:input="functionHERE" to listen to this emit
    },
    onFocus () {
      this.hasFocus = true
    },
    onBlur (event) {
      if (IsEmptyOrNull(event.target.value)) {
        this.hasFocus = false
      }
    }
  },
  mounted () {
    for (let i = 0; i < this.list.length; i++) {
      const selectedToothType = this.getSelectedToothType(this.list[i])
      const newTooth = [this.list[i], selectedToothType]

      if (this.list[i] > 10 && this.list[i] < 20) {
        this.teeth[0].q.push(newTooth)
      }

      if (this.list[i] > 20 && this.list[i] < 30) {
        this.teeth[1].q.push(newTooth)
      }

      if (this.list[i] > 30 && this.list[i] < 40) {
        this.teeth[3].q.push(newTooth)
      }

      if (this.list[i] > 40 && this.list[i] < 50) {
        this.teeth[2].q.push(newTooth)
      }
    }
    this.teeth[0].q = this.teeth[0].q.reverse()
    this.teeth[2].q = this.teeth[2].q.reverse()
  },
  created () {
    for (let i = 0; i < this.selectedValue.length; i++) {
      this.selectedValueLocal.push(JSON.parse(this.selectedValue[i]))
    }
    // this.selectedValueLocal = this.selectedValue
    if (this.type === 'fixed') {
      this.valuesType = [
        'pilar',
        'pontico',
        'none'
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .teeth-wrapper{
    width: 100%;
    overflow: hidden;
    padding-bottom: 39px;
    padding-top: 21px;
    .area-wrapper{
      display: flex;
      justify-content: space-between;
      margin-bottom: 31px;
      .wrapper-teeth{
        position: relative;

        .icon {
          color: #E2914F;
          position: absolute;
          bottom: calc(100% + 4px);
          left: 50%;
          transform: translate(-50%, 0);
          font-size: 2rem;
          &.below{
            bottom: initial;
            top: calc(100% + 21px);
          }
          &.icon-implante-implant{}
          &.icon-pilar-fixed{}
          &.icon-close{
            bottom: calc(100% + 8px);
            font-size: 1rem;
          }
          &.icon-pontico{
            bottom: calc(100% + 14px);
            font-size: 1.2rem;
          }
        }

        &.selected, &.open{
          .t11{
            background-image: url('../../assets/order/teeth-selected/11.svg');
          }
          .t12{
            background-image: url('../../assets/order/teeth-selected/12.svg');
          }
          .t13{
            background-image: url('../../assets/order/teeth-selected/13.svg');
          }
          .t14{
            background-image: url('../../assets/order/teeth-selected/14.svg');
          }
          .t15{
            background-image: url('../../assets/order/teeth-selected/15.svg');
          }
          .t16{
            background-image: url('../../assets/order/teeth-selected/16.svg');
          }
          .t17{
            background-image: url('../../assets/order/teeth-selected/17.svg');
          }
          .t18{
            background-image: url('../../assets/order/teeth-selected/18.svg');
          }
          .t21{
            background-image: url('../../assets/order/teeth-selected/21.svg');
          }
          .t22{
            background-image: url('../../assets/order/teeth-selected/22.svg');
          }
          .t23{
            background-image: url('../../assets/order/teeth-selected/23.svg');
          }
          .t24{
            background-image: url('../../assets/order/teeth-selected/24.svg');
          }
          .t25{
            background-image: url('../../assets/order/teeth-selected/25.svg');
          }
          .t26{
            background-image: url('../../assets/order/teeth-selected/26.svg');
          }
          .t27{
            background-image: url('../../assets/order/teeth-selected/27.svg');
          }
          .t28{
            background-image: url('../../assets/order/teeth-selected/28.svg');
          }
          .t31{
            background-image: url('../../assets/order/teeth-selected/31.svg');
          }
          .t32{
            background-image: url('../../assets/order/teeth-selected/32.svg');
          }
          .t33{
            background-image: url('../../assets/order/teeth-selected/33.svg');
          }
          .t34{
            background-image: url('../../assets/order/teeth-selected/34.svg');
          }
          .t35{
            background-image: url('../../assets/order/teeth-selected/35.svg');
          }
          .t36{
            background-image: url('../../assets/order/teeth-selected/36.svg');
          }
          .t37{
            background-image: url('../../assets/order/teeth-selected/37.svg');
          }
          .t38{
            background-image: url('../../assets/order/teeth-selected/38.svg');
          }
          .t41{
            background-image: url('../../assets/order/teeth-selected/41.svg');
          }
          .t42{
            background-image: url('../../assets/order/teeth-selected/42.svg');
          }
          .t43{
            background-image: url('../../assets/order/teeth-selected/43.svg');
          }
          .t44{
            background-image: url('../../assets/order/teeth-selected/44.svg');
          }
          .t45{
            background-image: url('../../assets/order/teeth-selected/45.svg');
          }
          .t46{
            background-image: url('../../assets/order/teeth-selected/46.svg');
          }
          .t47{
            background-image: url('../../assets/order/teeth-selected/47.svg');
          }
          .t48{
            background-image: url('../../assets/order/teeth-selected/48.svg');
          }
        }
        &.open{
          .type-selector {
            display: block;
          }
        }
        .type-selector {
          position: absolute;
          right: 60px;
          top: 50%;
          padding: 10px;
          width: 120px;
          max-height: 500px;
          transform: translate(0, -50%);
          background-color: #FFF;
          border: 1px solid rgb(87, 86, 124);
          border-radius: 5px;
          box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
          cursor: pointer;
          display: none;
          transition: opacity .4s ease-out;
          line-height: initial;
          color: #57567C;
          font-weight: initial;
          font-size: .9rem;
          z-index: 100;
          .radio{
            margin-left: 0.5em;
            margin-bottom: 7px;
            input{
              position: relative;
              top: 3px;
              left: -3px;
            }
          }
          &.left-arrow{
            left: 60px;
            right: initial;
            &:before{
              content:'';
              display: block;
              position: absolute;
              right: calc(100% - 1px);
              top: 50%;
              z-index: 2;
              transform: translate(0, -50%);
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 12.5px 21.7px 12.5px 0;
              border-color: transparent #FFF transparent transparent;
            }
            &:after{
              content:'';
              display: block;
              position: absolute;
              right: calc(100% + 1px);
              top: 50%;
              z-index: 1;
              transform: translate(0, -50%);
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 12.5px 21.7px 12.5px 0;
              border-color: transparent rgb(87, 86, 124) transparent transparent;
            }
          }
          &.right-arrow{
            &:before{
              content:'';
              display: block;
              position: absolute;
              left: calc(100% - 1px);
              top: 50%;
              z-index: 2;
              transform: translate(0, -50%);
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 12.5px 0 12.5px 21.7px;
              border-color: transparent transparent transparent #FFF;
            }
            &:after{
              content:'';
              display: block;
              position: absolute;
              left: calc(100% + 1px);
              top: 50%;
              z-index: 1;
              transform: translate(0, -50%);
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 12.5px 0 12.5px 21.7px;
              border-color: transparent transparent transparent rgb(87, 86, 124);
            }
          }
        }
      }
      .tooth{
        position: relative;
        width: 55px;
        height: 47px;
        cursor: pointer;
        label{
          position: absolute;
          left: 50%;
          top: 100%;
          transform: translate(-50%, 0);
        }
        &.t11{
          background-image: url('../../assets/order/teeth/11.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t12{
          background-image: url('../../assets/order/teeth/12.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t13{
          background-image: url('../../assets/order/teeth/13.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t14{
          background-image: url('../../assets/order/teeth/14.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t15{
          background-image: url('../../assets/order/teeth/15.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t16{
          background-image: url('../../assets/order/teeth/16.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }&.t17{
          background-image: url('../../assets/order/teeth/17.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }&.t18{
          background-image: url('../../assets/order/teeth/18.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t21{
          background-image: url('../../assets/order/teeth/21.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t22{
          background-image: url('../../assets/order/teeth/22.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t23{
          background-image: url('../../assets/order/teeth/23.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t24{
          background-image: url('../../assets/order/teeth/24.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t25{
          background-image: url('../../assets/order/teeth/25.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t26{
          background-image: url('../../assets/order/teeth/26.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t27{
          background-image: url('../../assets/order/teeth/27.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t28{
          background-image: url('../../assets/order/teeth/28.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t31{
          background-image: url('../../assets/order/teeth/31.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t32{
          background-image: url('../../assets/order/teeth/32.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t33{
          background-image: url('../../assets/order/teeth/33.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t34{
          background-image: url('../../assets/order/teeth/34.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t35{
          background-image: url('../../assets/order/teeth/35.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t36{
          background-image: url('../../assets/order/teeth/36.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t37{
          background-image: url('../../assets/order/teeth/37.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t38{
          background-image: url('../../assets/order/teeth/38.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t41{
          background-image: url('../../assets/order/teeth/41.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t42{
          background-image: url('../../assets/order/teeth/42.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t43{
          background-image: url('../../assets/order/teeth/43.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t44{
          background-image: url('../../assets/order/teeth/44.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t45{
          background-image: url('../../assets/order/teeth/45.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }&.t46{
          background-image: url('../../assets/order/teeth/46.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t47{
          background-image: url('../../assets/order/teeth/47.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t48{
          background-image: url('../../assets/order/teeth/48.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
    }
  }
</style>
