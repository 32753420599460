const SET_IS_LOADING = (state, payload) => {
  state.isLoading = payload.value
}

const SHOW_MODAL = (state, payload) => {
  const modal = payload.modalOptions
  if (payload.modalOptions.smallSize) {
    modal.width = 650 + 'px'
    modal.height = 420 + 'px'
  } else {
    modal.height = '98%'
    modal.width = '1024px'
  }
  state.modalOptions = modal
}

const CLOSE_MODAL = (state) => {
  state.modalOptions = null
}

const ADD_FEEDBACK = (state, payload) => {
  state.feedbacks.push(payload.feedback)
}

const REMOVE_FEEDBACK = (state) => {
  state.feedbacks.pop()
}

const SET_SETTINGS = (state, payload) => {
  state.settings = payload.value
}

export default {
  SET_IS_LOADING,
  SHOW_MODAL,
  CLOSE_MODAL,
  ADD_FEEDBACK,
  REMOVE_FEEDBACK,
  SET_SETTINGS
}
