import clinicsApi from '@/api/clinics'
import locationsApi from '@/api/locations'
import { IsEmptyOrNull, GroupComplementaryShoppingCart } from '@/utils/utils'

const updateClinic = ({ commit }, payload) => {
  const params = {
    clinicId: payload.clinicId,
    params: payload.params,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  clinicsApi.updateClinic(params)
}

const getLocations = ({ commit }, payload) => {
  const params = {
    clinicId: payload.clinicId,
    cb: (response) => {
      commit({
        type: 'SET_LOCATIONS',
        locations: response
      })
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  clinicsApi.getLocations(params)
}
const getLocationById = ({ commit }, payload) => {
  const params = {
    locationId: payload.locationId,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  locationsApi.getLocationById(params)
}

const createLocation = ({ commit }, payload) => {
  const params = {
    clinicId: payload.clinicId,
    data: payload.data,
    cb: (response) => {
      commit({
        type: 'ADD_LOCATION',
        location: response
      })
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  clinicsApi.createLocation(params)
}

const updateLocation = ({ commit }, payload) => {
  const params = {
    locationId: payload.locationId,
    data: payload.data,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  locationsApi.updateLocation(params)
}

const deleteLocation = ({ commit }, payload) => {
  const params = {
    locationId: payload.locationId,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  locationsApi.deleteLocation(params)
}

// CART
const getShoppingCart = ({ commit }, payload) => {
  const params = {
    clinicId: payload.clinicId,
    cb: (response) => {
      // Group the complementary items
      GroupComplementaryShoppingCart(response.items)
      commit({
        type: 'SET_SHOPPING_CART',
        shoppingCart: response
      })
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  clinicsApi.getShoppingCart(params)
}
const getShoppingCartList = ({ commit }, payload) => {
  const params = {
    clinicId: payload.clinicId,
    cb: (response) => {
      // Group the complementary items
      GroupComplementaryShoppingCart(response.items)
      commit({
        type: 'SET_SHOPPING_CART',
        shoppingCart: response
      })
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  clinicsApi.getShoppingCartList(params)
}
const getShoppingCartItem = ({ commit }, payload) => {
  const params = {
    cartItemId: payload.cartItemId,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  clinicsApi.getShoppingCartItem(params)
}
const createShoppingCartItem = ({ commit }, payload) => {
  const params = {
    clinicId: payload.clinicId,
    data: payload.data,
    cb: (response) => {
      commit({
        type: 'ADD_SHOPPING_CART_ITEM',
        item: response
      })
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  clinicsApi.createShoppingCartItem(params)
}
const updateShoppingCartItem = ({ commit }, payload) => {
  const params = {
    cartItemId: payload.cartItemId,
    data: payload.data,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  clinicsApi.updateShoppingCartItem(params)
}
const deleteShoppingCartItem = ({ commit }, payload) => {
  const params = {
    cartItemId: payload.cartItemId,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  clinicsApi.deleteShoppingCartItem(params)
}

// ORDERS
const getOrder = ({ commit }, payload) => {
  const params = {
    orderId: payload.orderId,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  clinicsApi.getOrder(params)
}
const getOrderList = ({ commit }, payload) => {
  const params = {
    clinicId: payload.clinicId,
    statusIn: payload.statusIn,
    page: (IsEmptyOrNull(payload.page) ? 1 : payload.page),
    pageSize: (IsEmptyOrNull(payload.pageSize) ? 20 : payload.pageSize),
    filterSelected: payload.filterSelected,
    orderList: payload.orderList,
    query: payload.query,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  if (payload.laboratoryIdIn !== null) {
    params.laboratoryIdIn = payload.laboratoryIdIn
  }
  clinicsApi.getOrderList(params)
}
const createOrder = ({ commit }, payload) => {
  const params = {
    clinicId: payload.clinicId,
    data: payload.data,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
      commit({
        type: 'CLEAR_SHOPPING_CART'
      })
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  clinicsApi.createOrder(params)
}
const updateOrderItems = ({ commit }, payload) => {
  const params = {
    orderItemId: payload.orderItemId,
    data: payload.data,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
      commit({
        type: 'CLEAR_SHOPPING_CART'
      })
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  clinicsApi.updateOrderItems(params)
}

const completeReview = ({ commit }, payload) => {
  const params = {
    orderItemId: payload.orderItemId,
    data: payload.data,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
      commit({
        type: 'CLEAR_SHOPPING_CART'
      })
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  clinicsApi.completeReview(params)
}

const createPickUpRequest = ({ commit }, payload) => {
  const params = {
    clinicId: payload.clinicId,
    ordersId: payload.ordersId,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  clinicsApi.createPickUpRequest(params)
}

const downloadFile = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    const params = {
      fileId: payload.fileId
    }
    clinicsApi.downloadFile(params)
      .then(response => {
        return resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const getStreamChatToken = ({ commit }, payload) => {
  const params = {
    clinicId: payload.clinicId,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  clinicsApi.getStreamChatToken(params)
}

const getOrderChatSession = ({ commit }, payload) => {
  const params = {
    orderId: payload.orderId,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  clinicsApi.getOrderChatSession(params)
}

export default {
  updateClinic,
  getLocations,
  getLocationById,
  createLocation,
  updateLocation,
  deleteLocation,
  getShoppingCart,
  getShoppingCartList,
  getShoppingCartItem,
  createShoppingCartItem,
  updateShoppingCartItem,
  deleteShoppingCartItem,
  getOrder,
  getOrderList,
  createOrder,
  updateOrderItems,
  completeReview,
  createPickUpRequest,
  downloadFile,
  getStreamChatToken,
  getOrderChatSession
}
