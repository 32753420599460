<template>
  <div id="order-added" class="order-added">
    <div class="columns">
      <div class="column is-8 is-offset-2">
        <p>
          Cras mattis consectetur purus sit amet fermentum. Etiam porta sem malesuada magna mollis euismod.
        </p>
        <button class="button is-primary is-fullwidth is-medium mt-6" @click="closeModal()">
          ADD MORE
        </button>
        <button class="button is-primary is-outline is-fullwidth is-medium mt-4" @click="goTocart()">
          PROCEED TO CHECKOUT
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modalOptions: Object
  },
  methods: {
    closeModal () {
      this.$store.commit({
        type: 'application/CLOSE_MODAL'
      })
    },
    goTocart () {
      this.$router.push({ path: '/checkout' })
      this.$store.commit({
        type: 'application/CLOSE_MODAL'
      })
    }
  }
}
</script>

<style lang="scss">
.order-added{
  width: 100%;
  margin-bottom: 76px;
  .column{
    padding: 0 46px;
  }
}
</style>
