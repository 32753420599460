<template>
  <div class="complementary-list" v-if="complementaries.length > 0 || extras.length > 0">
    <p v-if="complementaries.length > 0">Este producto incluye artículos complementarios y/o costes adicionales</p>
    <template v-for="product in complementaries">
      <div :id="'complementary-' + product.id" class="column is-12 complementary-wrap" v-bind:key="product.id">
        <div class="product-name-wrap has-text-weight-bold">{{product.product_name}}</div>
        <!-- <div class="product-quantity-wrap"></div> -->
        <div class="product-price-wrap">{{getProductPrice(product)}}€</div>
      </div>
    </template>
    <p v-if="extras.length > 0 && showExtras">Producto extra</p>
    <template v-for="product in extras">
      <div :id="'complementary-' + product.id" v-if="showExtras" class="column is-12 complementary-wrap" v-bind:key="product.id">
        <div class="product-name-wrap has-text-weight-bold">{{product.product_name}}</div>
        <div class="product-price-wrap"><span class="pr-4">CANTITAD {{product.quantity}}</span> {{getProductPrice(product)}}€</div>
      </div>
    </template>
    <button v-if="orderId !== null && showEditExtra" class="icon-button mt-3" @click="openEditExtra()">
      <span class="icon-review"></span> <span class="btn-label">Añadir/Eliminar extras</span>
    </button>
  </div>
</template>

<script>
import { IsEmptyOrNull } from '@/utils/utils'
export default {
  name: 'ComplementaryList',
  props: {
    productOfferId: {
      type: Number,
      default: null
    },
    withPrice: {
      type: Boolean,
      default: false
    },
    showExtras: {
      type: Boolean,
      default: true
    },
    loaded: {
      type: Function,
      default: null
    },
    updateCb: {
      type: Function,
      default: null
    },
    showEditExtra: {
      type: Boolean,
      default: false
    },
    activeExtras: {
      type: Array,
      default: () => { return [] }
    }
  },
  data () {
    return {
      complementaries: [],
      orderId: null,
      extras: [],
      extrasList: [],
      extraModalIsOpen: false,
      selectedExtrasValue: [],
      complemetaryAreLoaded: false,
      extrasAreLoaded: false
    }
  },
  watch: {
    productOfferId () {
      this.getProductOffersComplementaries()
    }
  },
  methods: {
    openEditExtra () {
      const modalOptions = {
        componentId: 'ExtraEdit',
        title: '',
        extras: this.extrasList,
        activeExtras: this.activeExtras,
        selectedExtrasValue: this.selectedExtrasValue,
        orderId: this.orderId,
        addExtra: this.addExtra,
        removeExtra: this.removeExtra,
        updateExtraQuantity: this.updateExtraQuantity,
        closeCb: this.updateCb
      }
      this.$store.commit({
        type: 'application/SHOW_MODAL',
        modalOptions: modalOptions
      })
    },
    updateExtraQuantity (extraToUpdate) {
      this.selectedExtrasValue[extraToUpdate.index].quantity = extraToUpdate.quantity
    },
    addExtra (item) {
      const newExtra = {
        product_offer_id: item.id,
        quantity: item.quantity
      }
      this.selectedExtrasValue.push(newExtra)
    },
    removeExtra (index) {
      this.selectedExtrasValue.splice(index, 1)
    },
    getProductPrice (product) {
      if (!IsEmptyOrNull(product.price)) {
        return product.price / 100
      }

      if (!IsEmptyOrNull(product.total_price)) {
        return product.total_price / 100
      }

      return '-'
    },
    getProductNameComplementaries (productId, index) {
      const self = this
      this.$store.dispatch('products/getProductById', {
        productId: productId
      }).then(function (response) {
        self.complementaries[index].product_name = response.name
        self.complementaries[index].accepts_quantity_input = response.accepts_quantity_input
        self.complementaries[index].bypass_fdi_chart_multiplier = response.bypass_fdi_chart_multiplier

        if ((self.complementaries.length - 1) === index) {
          self.complemetaryAreLoaded = true
          self.loadCompleted()
        }
      }).catch(function (error) {
        self.$store.commit({
          type: 'application/ADD_FEEDBACK',
          feedback: {
            type: 'error',
            message: error
          }
        })
      })
    },
    getProductNameExtras (productId, index) {
      const self = this
      this.$store.dispatch('products/getProductById', {
        productId: productId
      }).then(function (response) {
        self.extrasList[index].product_name = response.name
        self.extrasList[index].accepts_quantity_input = response.accepts_quantity_input
        self.extrasList[index].bypass_fdi_chart_multiplier = response.bypass_fdi_chart_multiplier

        if ((self.extrasList.length - 1) === index) {
          self.extrasAreLoaded = true
          self.loadCompleted()
        }
      }).catch(function (error) {
        self.$store.commit({
          type: 'application/ADD_FEEDBACK',
          feedback: {
            type: 'error',
            message: error
          }
        })
      })
    },
    loadCompleted () {
      if (this.complemetaryAreLoaded && this.extrasAreLoaded) {
        if (this.loaded !== null) {
          this.loaded(this.complementaries, this.extras)
        }
      }
    },
    getProductOffersComplementaries () {
      const self = this
      this.complementaries = []
      this.extras = []
      this.extrasList = []
      this.$store.dispatch('products/getProductOffersComplementaries', {
        productOfferId: this.productOfferId,
        cb: (response) => {
          let i = 0
          for (i = 0; i < response.length; i++) {
            response[i].product_name = 'Loading...'
            if (response[i].extra) {
              if (self.activeExtras.length > 0) {
                for (let y = 0; y < self.activeExtras.length; y++) {
                  if (response[i].id === self.activeExtras[y].product_offer_id) {
                    response[i].quantity = self.activeExtras[y].quantity
                    self.selectedExtrasValue.push(response[i])
                    self.extras.push(response[i])
                  }
                }
              } else {
                self.selectedExtrasValue.push(response[i])
                self.extras.push(response[i])
              }
              self.extrasList.push(response[i])
            } else {
              self.complementaries.push(response[i])
            }
          }
          // self.complementaries = response
          if (self.complementaries.length > 0) {
            for (i = 0; i < self.complementaries.length; i++) {
              self.getProductNameComplementaries(self.complementaries[i].product_id, i)
            }
          } else {
            self.complemetaryAreLoaded = true
          }
          if (self.extrasList.length > 0) {
            for (i = 0; i < self.extrasList.length; i++) {
              self.getProductNameExtras(self.extrasList[i].product_id, i)
            }
          } else {
            self.extrasAreLoaded = true
          }
        },
        cbError: (error) => {
          console.log(error)
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error.response.data.error
            }
          })
        }
      })
    }
  },
  created () {
    this.getProductOffersComplementaries()
    if (!IsEmptyOrNull(this.$route.params.orderId)) {
      this.orderId = this.$route.params.orderId
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.complementary-list{
  position: relative;
  background-color: rgba(87, 86, 124, .1);
  width: 100%;
  border-radius: 4px;
  padding: 29px;
  .complementary-wrap{
    display: flex;
    justify-content: space-between;
    padding: 8px 0px;
    .product-quantity-wrap{
      text-align: right;
    }
  }
  .icon-button{
    border: 0px;
    cursor: pointer;
    .btn-label{
      font-size: 1rem;
      font-family: Avenir, Helvetica, Arial, sans-serif;
      font-family: "basic-sans",sans-serif;
      text-decoration: underline;
    }
  }
}
</style>
