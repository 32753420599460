<template>
  <div class="pre-footer-cta has-text-centered">
    <div class="circle-bg"></div>
    <img alt="STLLAB Join the list" src="../assets/images/1-hp-2-join.png">
    <h2 class="title is-1">¡Únete a nosotros!</h2>
    <p class="pb-5">
      Si tu laboratorio protésico provee servicios digitales CAD CAM y impresión 3D, ¡hablemos!
    </p>
    <button class="button is-medium" @click="goTo('/contacts')">Contacta con nosotros</button>
  </div>
</template>

<script>
export default {
  name: 'PreFooterCta',
  data () {
    return {
    }
  },
  methods: {
    goTo (path) {
      this.$router.push({ path: path })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.pre-footer-cta{
  position: relative;
  // height: 1500px;
  width: 100%;
  margin-top: 100px;
  padding: 0 21px;
  padding-bottom: 50px;
  overflow: hidden;
  .circle-bg{
    position: absolute;
    top: 750px;
    left: 50%;
    transform: translate(-50%, 0) scale(2);
    background-color: #1A1A1A;
    border-radius: 50%;
    z-index: -1;
    width: 100%;
    height: 1500px;
  }
  img{
    max-width: 100%;
  }
  h2, p{
    color: #F6F4EF;
  }
  h2{
    margin-top: -75px;
  }
  button {
    border-color: #F6F4EF;
    color: #F6F4EF;
    background-color: transparent;
  }
}
@media screen and (max-width: 767px) {
  .pre-footer-cta{
    .circle-bg{
      width: 120%;
    }
    h2{
      margin-top: 21px;
    }
  }

}
</style>
