import Vue from 'vue'
import Vuex from 'vuex'
import applicationModule from './application/index'
import userModule from './user/index'
import laboratoryModule from './laboratory/index'
import clinicModule from './clinic/index'
import productsModule from './products/index'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    application: applicationModule,
    user: userModule,
    lab: laboratoryModule,
    products: productsModule,
    clinic: clinicModule
  }
})
