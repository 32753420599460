const locations = state => state.locations
const shoppingCart = state => state.shoppingCart
const currentOrder = state => state.currentOrder
const primaryLocation = state => {
  for (let i = 0; i < state.locations.length; i++) {
    if (state.locations[i].primary) {
      return state.locations[i]
    }
  }

  return null
}

export default {
  locations,
  shoppingCart,
  currentOrder,
  primaryLocation
}
