<template>
  <div id="order-revision-modal" class="order-revision-modal">
    <p class="has-text-centered">Envía tus comentarios a la clínica para aclarar tus dudas sobre la orden:</p>
    <p class="has-text-centered has-text-weight-bold title is-4 pt-4 pb-4">{{modalOptions.orderItem.product_name}}</p>
    <div class="comment-wrap">
      <p><strong class="is-uppercase">Comentarios para la clínica</strong></p>
      <textarea placeholder="Instrucciones adicionales" v-model="modalOptions.orderItem.review_request_comment"></textarea>
    </div>
    <div class="columns btn-wrap">
      <div class="column is-12">
        <button v-if="!modalOptions.isUpdate" class="button is-primary is-fullwidth is-medium mt-5" @click="requestReview()">
          Solicitar la revisión
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modalOptions: Object
  },
  methods: {
    closeModal () {
      this.$store.commit({
        type: 'application/CLOSE_MODAL'
      })
    },
    requestReview () {
      const self = this
      this.$store.dispatch('lab/reviewRequest', {
        orderItemId: this.modalOptions.orderItem.id,
        reviewRequestComment: this.modalOptions.orderItem.review_request_comment,
        cb: (response) => {
          console.log(response)
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'success',
              message: 'Request sent succesfully'
            }
          })
          self.modalOptions.orderItem = response
          self.closeModal()
        },
        cbError: (error) => {
          if (IsEmptyOrNull(error.response)) {
            self.$store.commit({
              type: 'application/ADD_FEEDBACK',
              feedback: {
                type: 'alert',
                message: 'Error, something went wrong'
              }
            })
          } else {
            self.$store.commit({
              type: 'application/ADD_FEEDBACK',
              feedback: {
                type: 'error',
                message: error.response.data.error
              }
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">
.order-revision-modal{
  width: 100%;
  margin-bottom: 76px;
  padding: 0 76px;
  textarea, .comment-wrap{
    width: 100%;
    box-sizing: border-box;
    height: 150px;
    border: 1px solid rgba(87,86,124,0.5);
    border-radius: 4px;
  }
  textarea{
    padding: 10px;
    font-size: 1.1rem;
    margin-top: 14px;
  }
  .comment-wrap{
    height: auto;
    padding: 16px;
  }
}
</style>
