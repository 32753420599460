<template>
  <div class="column is-12 field-wrap mt-4 extra-products" v-if="extras.length > 0">
    <div class="extra-checkbox-wrap">
      <h4 class="title is-6">
        Extras
      </h4>
      <div class="field" v-if="isLoaded">
        <template v-for="item in extras">
          <div class="extra-wrap" v-bind:key="item.id">
            <div class="custom-switch">
              <input type="checkbox" :id="'switch-extra-' + item.id" @click="setExtrasCheckboxValue(item)" :checked="setExtrasCheckedValues(item)"/><label class="toggle" :for="'switch-extra-' + item.id">Toggle</label>
            </div>
            <div class="extra-name" v-bind:class="{'no-counter': !item.accepts_quantity_input}">
              {{item.product_name}}
            </div>
            <div class="counter" v-if="item.accepts_quantity_input">
              <button class="counter-button" v-show="setExtrasCheckedValues(item)" @click="subExtraCount(item)" :disabled="item.quantity <= 0">-</button>
              <div class="counter-value" v-show="setExtrasCheckedValues(item)">{{item.quantity}}</div>
              <button class="counter-button" v-show="setExtrasCheckedValues(item)" @click="addExtraCount(item)">+</button>
            </div>
            <div class="price-wrap">
              <span class="is-text-orange">{{item.price / 100}}€</span>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  IsEmptyOrNull
} from '@/utils/utils'
export default {
  name: 'ExtraProducts',
  props: {
    extrasList: {
      type: Array
    },
    activeExtras: {
      type: Array
    },
    selectedExtrasValue: {
      type: Array
    },
    orderId: {
      type: String
    }
  },
  data () {
    return {
      extras: [],
      isLoaded: false
    }
  },
  computed: {
    ...mapGetters({
      isLabLoggedIn: 'user/isLabLoggedIn'
    })
  },
  methods: {
    setExtrasOptions () {
      this.extras = this.extrasList
      for (let y = 0; y < this.extras.length; y++) {
        this.extras[y].quantity = 0
        if (!IsEmptyOrNull(this.activeExtras)) {
          for (let i = 0; i < this.activeExtras.length; i++) {
            if (this.extras[y].id === this.activeExtras[i].product_offer_id) {
              this.extras[y].quantity = this.activeExtras[i].quantity
              this.extras[y].orderItemId = this.activeExtras[i].id
              this.$emit('addExtra', this.extras[y])
            }
          }
        }
      }
      this.isLoaded = true
    },
    setExtrasCheckboxValue (item) {
      const selectedExtraIndex = this.getIndexSelectedExtra(item)
      const selectedExtra = (selectedExtraIndex === null) ? null : this.selectedExtrasValue[selectedExtraIndex]

      if (selectedExtra === null) {
        item.quantity = 1
        this.$emit('addExtra', item)
        if (this.isLabLoggedIn) {
          this.labAddExtra(item)
        }
      } else {
        item.quantity = 0
        this.$emit('removeExtra', selectedExtraIndex)
        if (this.isLabLoggedIn) {
          this.labRemoveExtra(item.orderItemId)
        }
      }
    },
    labAddExtra (extra) {
      // TO DO ADD QUANTITTY
      const self = this
      this.$store.dispatch('lab/createOrderItem', {
        productOfferId: extra.id,
        orderItemId: this.orderId,
        cb: (response) => {
          extra.orderItemId = response.id
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'success',
              message: 'Extra added correctly'
            }
          })
        },
        cbError: () => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: 'Error on adding extra'
            }
          })
        }
      })
    },
    labRemoveExtra (orderItemId) {
      const self = this
      this.$store.dispatch('lab/deleteOrderItem', {
        orderItemId: orderItemId,
        cb: (response) => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'success',
              message: 'Extra removed correctly'
            }
          })
        },
        cbError: () => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: 'Error on removing extra'
            }
          })
        }
      })
    },
    getIndexSelectedExtra (item) {
      for (let i = 0; i < this.selectedExtrasValue.length; i++) {
        if (item.id === this.selectedExtrasValue[i].product_offer_id) {
          return i
        }
      }
      return null
    },
    setExtrasCheckedValues (item) {
      const indexExtra = this.getIndexSelectedExtra(item)
      if (indexExtra !== null) {
        return true
      }

      return false
    },
    subExtraCount (item) {
      item.quantity--
      if (item.quantity === 0) {
        this.setExtrasCheckboxValue(item)
      } else {
        const indexExtra = this.getIndexSelectedExtra(item)
        this.$emit('updateExtraQuantity', { index: indexExtra, quantity: item.quantity })
      }
      this.$forceUpdate()
    },
    addExtraCount (item) {
      item.quantity++
      const indexExtra = this.getIndexSelectedExtra(item)
      this.$emit('updateExtraQuantity', { index: indexExtra, quantity: item.quantity })
      this.$forceUpdate()
    }
  },
  created () {
    this.setExtrasOptions()
    /* console.log(this.extrasList)
    console.log(this.activeExtras)
    console.log(this.selectedExtrasValue) */
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.extra-products{
  .extra-checkbox-wrap{
    .field{
      position: relative;
      display: flex;
      flex-wrap: wrap;
      &:before{
        content: '';
        position: absolute;
        top: 0px;
        left: 50%;
        transform: translate(-50%, 0);
        height: 100%;
        width: 1px;
        background-color: #57567C;
        opacity: .5;
      }
      .extra-wrap{
        display: flex;
        font-weight: bold;
        margin-bottom: 21px;
        padding-right: 21px;
        width: 50%;
        &:nth-child(odd){
          padding-right: 35px;
        }
        &:nth-child(even){
          padding-left: 30px;
        }
        .extra-name{
          width: calc(100% - 216px);
          align-self: center;
          &.no-counter{
            width: calc(100% - 126px);
          }
        }
        .price-wrap{
          width: 45px;
          align-self: center;
          text-align: right;
        }
        .counter{
          height: 30px;
          width: 90px;
          display: flex;
          text-align: center;
          margin-right: 14px;

          .counter-button{
            height: 30px;
            width: 30px;
            color: #57567C;
            font-weight: bold;
            font-size: 1.8rem;
            padding: 0px;
            background-color: transparent;
            border: 0px;
            margin: 0px;
            line-height: 30px;
            &:disabled{
              color: rgba(87,86,124,0.5);
            }
          }
          .counter-value{
            height: 30px;
            width: 30px;
            line-height: 30px;
            border-radius: 50%;
            border: 1px solid rgba(87,86,124,0.5);
          }
        }
        .custom-switch{
          width: initial;
          margin-right: 21px;
        }
        label{
          align-items: center;
          align-content: center;
          align-self: center;
        }
        .is-text-orange{
          color: #E3914F;
          font-size: 1.2rem;
        }
      }
    }
  }
}
</style>
