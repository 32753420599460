import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/index.js'
import { IsEmptyOrNull } from '@/utils/utils.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import(/* webpackChunkName: "Contacts" */ '../views/Contacts.vue')
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import(/* webpackChunkName: "products" */ '../views/Products.vue')
  },
  {
    path: '/laboratories',
    name: 'Laboratories',
    component: () => import(/* webpackChunkName: "laboratories" */ '../views/Laboratories.vue')
  },
  {
    path: '/laboratories/details/:labId',
    name: 'LaboratoryDetails',
    component: () => import(/* webpackChunkName: "laboratoryDetails" */ '../views/LaboratoryDetails.vue')
  },
  {
    path: '/laboratories/details/:labId/:productId',
    name: 'LaboratoryDetailsWithProductOrder',
    component: () => import(/* webpackChunkName: "laboratoryDetails" */ '../views/LaboratoryDetails.vue')
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import(/* webpackChunkName: "Checkout" */ '../views/Checkout/Checkout.vue')
  },
  {
    path: '/checkout/login',
    name: 'CheckoutLogin',
    component: () => import(/* webpackChunkName: "CheckoutLogin" */ '../views/Checkout/Login.vue')
  },
  {
    path: '/checkout/order-details',
    name: 'CheckoutOrderDetails',
    component: () => import(/* webpackChunkName: "CheckoutOrderDetails" */ '../views/Checkout/OrderDetails.vue'),
    meta: {
      authRequiredClinic: true
    }
  },
  {
    path: '/checkout/shipping',
    name: 'CheckoutOrderShipping',
    component: () => import(/* webpackChunkName: "CheckoutOrderShipping" */ '../views/Checkout/Shipping.vue'),
    meta: {
      authRequiredClinic: true
    }
  },
  {
    path: '/checkout/payment-methods',
    name: 'CheckoutOrderPayment',
    component: () => import(/* webpackChunkName: "CheckoutOrderPayment" */ '../views/Checkout/Payment.vue'),
    meta: {
      authRequiredClinic: true
    }
  },
  {
    path: '/checkout/order-completed/:orderId',
    name: 'CheckoutCompleted',
    component: () => import(/* webpackChunkName: "CheckoutCompleted" */ '../views/Checkout/Completed.vue'),
    meta: {
      authRequiredClinic: true
    }
  },
  {
    path: '/clinic/login',
    name: 'ClinicLogin',
    component: () => import(/* webpackChunkName: "ClinicLogin" */ '../views/Clinic/Login.vue')
  },
  {
    path: '/clinic/welcome',
    name: 'WelcomeClinic',
    component: () => import(/* webpackChunkName: "WelcomeClinic" */ '../views/Clinic/Welcome.vue')
  },
  {
    path: '/clinic/personal-informations',
    name: 'ClinicPersonalInfo',
    component: () => import(/* webpackChunkName: "ClinicPersonalInfo" */ '../views/Clinic/PersonalInfo.vue'),
    meta: {
      authRequiredClinic: true
    }
  },
  {
    path: '/clinic/addresses',
    name: 'ClinicAddresses',
    component: () => import(/* webpackChunkName: "ClinicAddresses" */ '../views/Clinic/Addresses.vue'),
    meta: {
      authRequiredClinic: true
    }
  },
  {
    path: '/clinic/my-cards',
    name: 'ClinicCards',
    component: () => import(/* webpackChunkName: "ClinicCards" */ '../views/Clinic/Cards.vue'),
    meta: {
      authRequiredClinic: true
    }
  },
  {
    path: '/clinic/orders',
    name: 'ClinicOrders',
    component: () => import(/* webpackChunkName: "ClinicOrders" */ '../views/Clinic/Orders.vue'),
    meta: {
      authRequiredClinic: true
    }
  },
  {
    path: '/clinic/orders/:orderId',
    name: 'ClinicOrderDetail',
    component: () => import(/* webpackChunkName: "ClinicOrderDetail" */ '../views/Clinic/OrderDetail.vue'),
    meta: {
      authRequiredClinic: true
    }
  },
  {
    path: '/clinic/orders/:orderId/completed',
    name: 'ClinicOrderCompleted',
    component: () => import(/* webpackChunkName: "ClinicOrderCompleted" */ '../views/OrderCompleted.vue'),
    meta: {
      authRequiredLab: true
    }
  },
  {
    path: '/clinic/favourites',
    name: 'ClinicFavourites',
    component: () => import(/* webpackChunkName: "ClinicFavourites" */ '../views/Clinic/Favourites.vue'),
    meta: {
      authRequiredLab: true
    }
  },
  {
    path: '/clinic/recover-password',
    name: 'ClinicRecoverPassword',
    component: () => import(/* webpackChunkName: "ClinicRecoverPassword" */ '../views/Clinic/RecoverPassword.vue')
  },
  {
    path: '/clinic/reset-password/:token',
    name: 'ClinicResetPassword',
    component: () => import(/* webpackChunkName: "ClinicResetPassword" */ '../views/Clinic/ResetPassword.vue')
  },
  {
    path: '/laboratory/login',
    name: 'LaboratoryLogin',
    component: () => import(/* webpackChunkName: "LaboratoryLogin" */ '../views/Laboratory/Login.vue')
  },
  {
    path: '/laboratory/info-laboratory',
    name: 'LaboratoryLabInfo',
    component: () => import(/* webpackChunkName: "LaboratoryLabInfo" */ '../views/Laboratory/LabInfo.vue'),
    meta: {
      authRequiredLab: true
    }
  },
  {
    path: '/laboratory/products',
    name: 'LaboratoryLabServices',
    component: () => import(/* webpackChunkName: "LaboratoryLabServices" */ '../views/Laboratory/Services.vue'),
    meta: {
      authRequiredLab: true
    }
  },
  {
    path: '/laboratory/orders',
    name: 'LaboratoryLabOrders',
    component: () => import(/* webpackChunkName: "LaboratoryLabOrders" */ '../views/Laboratory/Orders.vue'),
    meta: {
      authRequiredLab: true
    }
  },
  {
    path: '/laboratory/orders/:orderId',
    name: 'LaboratoryOrderDetail',
    component: () => import(/* webpackChunkName: "LaboratoryOrderDetail" */ '../views/Laboratory/OrderDetail.vue'),
    meta: {
      authRequiredLab: true
    }
  },
  {
    path: '/laboratory/orders/:orderId/completed',
    name: 'LaboratoryOrderCompleted',
    component: () => import(/* webpackChunkName: "LaboratoryOrderCompleted" */ '../views/OrderCompleted.vue'),
    meta: {
      authRequiredLab: true
    }
  },
  {
    path: '/laboratory/payment-details',
    name: 'LaboratoryPaymnetDetail',
    component: () => import(/* webpackChunkName: "LaboratoryPaymnetDetail" */ '../views/Laboratory/PaymentDetails.vue'),
    meta: {
      authRequiredLab: true
    }
  },
  {
    path: '/laboratories/reset-password/:token',
    name: 'LabResetPassword',
    component: () => import(/* webpackChunkName: "LabResetPassword" */ '../views/Laboratory/ResetPassword.vue')
  },
  {
    path: '/laboratories/recover-password',
    name: 'LabRecoverPassword',
    component: () => import(/* webpackChunkName: "LabRecoverPassword" */ '../views/Laboratory/RecoverPassword.vue')
  },
  {
    path: '/customer-supports/contacts',
    name: 'HelpContactos',
    component: () => import(/* webpackChunkName: "Help" */ '../views/Help.vue')
  },
  {
    path: '/customer-supports/shipping-and-returns',
    name: 'HelpEnvioDevolucioness',
    component: () => import(/* webpackChunkName: "Help" */ '../views/Help.vue')
  },
  {
    path: '/customer-supports/secure-payments',
    name: 'HelpPagosSeguros',
    component: () => import(/* webpackChunkName: "Help" */ '../views/Help.vue')
  },
  {
    path: '/customer-supports/order-details',
    name: 'HelpDetallesPedido',
    component: () => import(/* webpackChunkName: "Help" */ '../views/Help.vue')
  },
  {
    path: '/customer-supports/faq',
    name: 'HelpPreguntasFrecuentes',
    component: () => import(/* webpackChunkName: "Help" */ '../views/Help.vue')
  },
  {
    path: '/legal-area/privacy-policy',
    name: 'LegalAreaPoliticaPrivacidad',
    component: () => import(/* webpackChunkName: "LegalArea" */ '../views/LegalArea.vue')
  },
  {
    path: '/legal-area/cookies-policy',
    name: 'LegalAreaCookies',
    component: () => import(/* webpackChunkName: "LegalArea" */ '../views/LegalArea.vue')
  },
  {
    path: '/legal-area/terms-of-use',
    name: 'LegalAreaTerminosUsos',
    component: () => import(/* webpackChunkName: "LegalArea" */ '../views/LegalArea.vue')
  },
  {
    path: '/legal-area/return-policy',
    name: 'LegalAreaPoliticaDevoluciones',
    component: () => import(/* webpackChunkName: "LegalArea" */ '../views/LegalArea.vue')
  },
  // ONBOARD
  {
    path: '/confirmations/:invitationCode',
    name: 'ConfirmAccount',
    component: () => import(/* webpackChunkName: "ConfirmAccount" */ '../views/Onboard/ConfirmAccount.vue')
  },
  {
    path: '/confirmations/:invitationCode/:type',
    name: 'ConfirmAccountWithType',
    component: () => import(/* webpackChunkName: "ConfirmAccountWithType" */ '../views/Onboard/ConfirmAccount.vue')
  },
  // Receipt pritnable
  {
    path: '/print/certificate/:orderId',
    name: 'PrintCertificate',
    component: () => import(/* webpackChunkName: "PrintCertificate" */ '../views/Print/Certificate.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token')
  if (!IsEmptyOrNull(token)) {
    let savedUser = null
    if (!IsEmptyOrNull(localStorage.getItem('user'))) {
      savedUser = JSON.parse(localStorage.getItem('user'))
    }
    if (savedUser !== null) {
      store.commit({
        type: 'user/SET_USER',
        user: savedUser,
        isLogged: true
      })
    }

    let savedshoppingCart = null
    if (!IsEmptyOrNull(localStorage.getItem('shoppingCart'))) {
      savedshoppingCart = JSON.parse(localStorage.getItem('shoppingCart'))
    }
    if (savedshoppingCart !== null) {
      store.commit({
        type: 'clinic/SET_SHOPPING_CART',
        shoppingCart: savedshoppingCart
      })
    }

    let savedcurrentOrder = null
    if (!IsEmptyOrNull(localStorage.getItem('currentOrder'))) {
      savedcurrentOrder = JSON.parse(localStorage.getItem('currentOrder'))
    }
    if (savedcurrentOrder !== null) {
      store.commit({
        type: 'clinic/SET_CURRENT_ORDER_SHIPMENTS',
        currentOrder: savedcurrentOrder
      })
    }
  }

  if (to.meta.authRequiredClinic || to.meta.authRequiredLab) {
    if (to.meta.authRequiredLab && store.getters['user/user'].roles[0] === 'laboratory') {
      if (!IsEmptyOrNull(token)) {
        next()
      } else {
        next({ name: 'Home' })
      }
    }
    if (to.meta.authRequiredClinic && store.getters['user/user'].roles[0] === 'clinic') {
      // Check if has already the token, try to use it, if it works go ahead, if not return to login
      if (!IsEmptyOrNull(token)) {
        next()
      } else {
        next({ name: 'Home' })
      }
    }
  }
  next()
})

export default router
