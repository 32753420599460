import api from '@/api/api'
import { IsEmptyOrNull } from '@/utils/utils'
const endpoints = {
  clinics: 'clinics/',
  orderItems: 'order_items',
  addresses: 'addresses',
  locations: 'locations',
  orders: 'orders',
  cartItems: 'shopping_cart_items',
  cart: 'shopping_cart',
  dentalImpressions: 'dental_impressions/',
  upload: 'uploads/'
}
export default {
  updateClinic (payload) {
    api.put(endpoints.clinics + payload.clinicId, payload.params).then((response) => {
      if (response.status === 200) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.response.data.errors)
        }
      }
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error.response.data)
      }
    })
  },
  // LOCATIONS
  // TO DO PAGINATION??
  getLocations (payload) {
    api.get(endpoints.clinics + payload.clinicId + '/' + endpoints.locations).then((response) => {
      if (response.status === 200) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.response.data.errors)
        }
      }
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error.response.data)
      }
    })
  },
  createLocation (payload) {
    api.post(endpoints.clinics + payload.clinicId + '/' + endpoints.locations, { location: payload.data }).then((response) => {
      if (response.status === 200) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.response.data.errors)
        }
      }
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error.response.data)
      }
    })
  },
  // Shopping cart
  getShoppingCart (payload) {
    api.get(endpoints.clinics + payload.clinicId + '/' + endpoints.cart).then((response) => {
      if (response.status === 200) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.response.data.errors)
        }
      }
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error.response.data)
      }
    })
  },
  getShoppingCartList (payload) {
    api.get(endpoints.clinics + payload.clinicId + '/' + endpoints.cartItems).then((response) => {
      if (response.status === 200) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.response.data.errors)
        }
      }
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error.response.data)
      }
    })
  },
  getShoppingCartItem (payload) {
    const endpoint = endpoints.cartItems + '/' + payload.cartItemId
    api.get(endpoint).then((response) => {
      if (response.status === 200) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.response.data.errors)
        }
      }
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error.response.data)
      }
    })
  },
  createShoppingCartItem (payload) {
    api.post(endpoints.clinics + payload.clinicId + '/' + endpoints.cartItems, { shopping_cart_item: payload.data }).then((response) => {
      if (response.status === 200) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.response.data.errors)
        }
      }
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error.response.data)
      }
    })
  },
  updateShoppingCartItem (payload) {
    const endpoint = endpoints.cartItems + '/' + payload.cartItemId
    api.put(endpoint, { shopping_cart_item: payload.data }).then((response) => {
      if (response.status === 200) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.response.data.errors)
        }
      }
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error.response.data)
      }
    })
  },
  deleteShoppingCartItem (payload) {
    const endpoint = endpoints.cartItems + '/' + payload.cartItemId
    api.delete(endpoint).then((response) => {
      if (response.status === 200) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.response.data.errors)
        }
      }
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error.response.data)
      }
    })
  },
  // ORDERS
  getOrder (payload) {
    const endpoint = endpoints.orders + '/' + payload.orderId
    api.get(endpoint).then((response) => {
      if (response.status === 200) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.response.data.errors)
        }
      }
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error.response.data)
      }
    })
  },
  getOrderList (payload) {
    let endpoint = endpoints.clinics + payload.clinicId + '/' + endpoints.orders + '?page=' + payload.page + '&per_page=' + payload.pageSize
    if (!IsEmptyOrNull(payload.statusIn)) {
      endpoint = endpoint + '&status_in=' + payload.statusIn
    }
    if (!IsEmptyOrNull(payload.laboratoryIdIn)) {
      endpoint = endpoint + '&laboratory_id_in=' + payload.laboratoryIdIn
    }
    endpoint = endpoint + '&sorted_by=' + payload.filterSelected + '|' + payload.orderList

    if (!IsEmptyOrNull(payload.query)) {
      endpoint = endpoint + '&q=' + payload.query
    }

    api.get(endpoint).then((response) => {
      if (response.status === 200) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.response.data.errors)
        }
      }
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error.response.data)
      }
    })
  },
  createOrder (payload) {
    api.post(endpoints.clinics + payload.clinicId + '/' + endpoints.orders, payload.data).then((response) => {
      if (response.status === 200) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.response.data.errors)
        }
      }
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error.response.data)
      }
    })
  },
  updateOrderItems (payload) {
    const endpoint = endpoints.orderItems + '/' + payload.orderItemId
    api.put(endpoint, { order_item: payload.data }).then((response) => {
      if (response.status === 200) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.response.data.errors)
        }
      }
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error.response.data)
      }
    })
  },
  completeReview (payload) {
    const endpoint = endpoints.orderItems + '/' + payload.orderItemId + '/completed_reviews'
    api.post(endpoint, { order_item: payload.data }).then((response) => {
      if (response.status === 200) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.response.data.errors)
        }
      }
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error.response.data)
      }
    })
  },
  createPickUpRequest (payload) {
    const body = {
      pickup_request: {
        order_ids: payload.ordersId
      }
    }

    api.post(endpoints.clinics + payload.clinicId + '/pickup_requests', body).then((response) => {
      if (response.status === 200 || response.status === 201) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.data)
        }
      }
    }).catch(function (error) {
      if (error.response) {
        // Request made and server responded
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.response.data.error)
        }
      } else if (error.request) {
        // The request was made but no response was received
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.request)
        }
      } else {
        // Something happened in setting up the request that triggered an Error
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(error.message)
        }
      }
    })
  },
  downloadFile (payload) {
    return new Promise((resolve, reject) => {
      const url = endpoints.upload + payload.fileId + '/uploaded_file'
      api.get(url, { responseType: 'blob' }).then((response) => {
        resolve(response)
      }).catch(function (error) {
        reject(error)
      })
    })
  },
  getStreamChatToken (payload) {
    const endpoint = endpoints.clinics + payload.clinicId + '/chat_tokens'
    api.post(endpoint, {}).then((response) => {
      if (response.status === 200) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.response.data.errors)
        }
      }
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error.response.data)
      }
    })
  },
  getOrderChatSession (payload) {
    const endpoint = endpoints.orders + '/' + payload.orderId + '/chat_sessions'
    api.post(endpoint, {}).then((response) => {
      if (response.status === 200) {
        payload.cb(response.data)
      } else {
        if (!IsEmptyOrNull(payload.cbError)) {
          payload.cbError(response.response.data.errors)
        }
      }
    }).catch(function (error) {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error.response.data)
      }
    })
  }
}
