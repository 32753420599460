<template>
  <div class="checkbox-wrap">
    <h4 class="title is-6">
      {{name}} <span class="mandatory-label" v-if="isRequired">* obligatorio</span> <span v-bind:class="{'has-text-danger': selectedValue.length === maxFdiSelections}">({{selectedValue.length}}/{{maxFdiSelections}})</span>
    </h4>
    <p class="mb-4">TO TRANSLATE --> Haga clic en los dientes para seleccionarlos</p>
    <div class="field">
      <div class="columns teeth-wrapper is-flex-wrap-wrap">
        <template v-for="(teethQuadrant, indexQ) in teeth">
          <div class="column is-6 area-wrapper" v-bind:key="indexQ">
            <template v-for="tooth in teethQuadrant.q">
              <div v-bind:key="tooth" class="wrapper-teeth" v-bind:class="{'selected': isSelected(tooth)}">
                <div :id="'tooth-' + tooth" :class="'tooth t' + tooth" @click="setCheckboxValue($event, tooth)">
                  <label>{{tooth}}</label>
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {
  IsEmptyOrNull
} from '@/utils/utils'
export default {
  name: 'TeethPosition',
  props: {
    name: {
      type: String
    },
    selectedValue: {
      type: Array
    },
    list: {
      type: Array
    },
    index: {
      type: Number
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    maxFdiSelections: {
      type: Number
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      hasFocus: false,
      teeth: [
        { q: [] },
        { q: [] },
        { q: [] },
        { q: [] }
      ]
    }
  },
  methods: {
    isSelected (value) {
      return this.selectedValue.includes(value.toString())
    },
    setCheckboxValue (e, value) {
      if (!this.disabled) {
        this.$emit('updateCheckbox', {
          indexField: this.index,
          value: value,
          limit: this.maxFdiSelections
        })
      }
    },
    onInput (event) {
      // Can add validation here
      this.$emit('input', event.target.value)
      // On component add v-on:input="functionHERE" to listen to this emit
    },
    onFocus () {
      this.hasFocus = true
    },
    onBlur (event) {
      if (IsEmptyOrNull(event.target.value)) {
        this.hasFocus = false
      }
    }
  },
  mounted () {
    for (let i = 0; i < this.list.length; i++) {
      if (this.list[i] > 10 && this.list[i] < 20) {
        this.teeth[0].q.push(this.list[i])
      }

      if (this.list[i] > 20 && this.list[i] < 30) {
        this.teeth[1].q.push(this.list[i])
      }

      if (this.list[i] > 30 && this.list[i] < 40) {
        this.teeth[3].q.push(this.list[i])
      }

      if (this.list[i] > 40 && this.list[i] < 50) {
        this.teeth[2].q.push(this.list[i])
      }
    }
    this.teeth[0].q = this.teeth[0].q.reverse()
    this.teeth[2].q = this.teeth[2].q.reverse()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .teeth-wrapper{
    width: 100%;
    overflow: hidden;
    padding-bottom: 14px;
    .area-wrapper{
      display: flex;
      justify-content: space-between;
      margin-bottom: 21px;
      .wrapper-teeth{
        &.selected{
          .t11{
            background-image: url('../../assets/order/teeth-selected/11.svg');
          }
          .t12{
            background-image: url('../../assets/order/teeth-selected/12.svg');
          }
          .t13{
            background-image: url('../../assets/order/teeth-selected/13.svg');
          }
          .t14{
            background-image: url('../../assets/order/teeth-selected/14.svg');
          }
          .t15{
            background-image: url('../../assets/order/teeth-selected/15.svg');
          }
          .t16{
            background-image: url('../../assets/order/teeth-selected/16.svg');
          }
          .t17{
            background-image: url('../../assets/order/teeth-selected/17.svg');
          }
          .t18{
            background-image: url('../../assets/order/teeth-selected/18.svg');
          }
          .t21{
            background-image: url('../../assets/order/teeth-selected/21.svg');
          }
          .t22{
            background-image: url('../../assets/order/teeth-selected/22.svg');
          }
          .t23{
            background-image: url('../../assets/order/teeth-selected/23.svg');
          }
          .t24{
            background-image: url('../../assets/order/teeth-selected/24.svg');
          }
          .t25{
            background-image: url('../../assets/order/teeth-selected/25.svg');
          }
          .t26{
            background-image: url('../../assets/order/teeth-selected/26.svg');
          }
          .t27{
            background-image: url('../../assets/order/teeth-selected/27.svg');
          }
          .t28{
            background-image: url('../../assets/order/teeth-selected/28.svg');
          }
          .t31{
            background-image: url('../../assets/order/teeth-selected/31.svg');
          }
          .t32{
            background-image: url('../../assets/order/teeth-selected/32.svg');
          }
          .t33{
            background-image: url('../../assets/order/teeth-selected/33.svg');
          }
          .t34{
            background-image: url('../../assets/order/teeth-selected/34.svg');
          }
          .t35{
            background-image: url('../../assets/order/teeth-selected/35.svg');
          }
          .t36{
            background-image: url('../../assets/order/teeth-selected/36.svg');
          }
          .t37{
            background-image: url('../../assets/order/teeth-selected/37.svg');
          }
          .t38{
            background-image: url('../../assets/order/teeth-selected/38.svg');
          }
          .t41{
            background-image: url('../../assets/order/teeth-selected/41.svg');
          }
          .t42{
            background-image: url('../../assets/order/teeth-selected/42.svg');
          }
          .t43{
            background-image: url('../../assets/order/teeth-selected/43.svg');
          }
          .t44{
            background-image: url('../../assets/order/teeth-selected/44.svg');
          }
          .t45{
            background-image: url('../../assets/order/teeth-selected/45.svg');
          }
          .t46{
            background-image: url('../../assets/order/teeth-selected/46.svg');
          }
          .t47{
            background-image: url('../../assets/order/teeth-selected/47.svg');
          }
          .t48{
            background-image: url('../../assets/order/teeth-selected/48.svg');
          }
        }
      }
      .tooth{
        position: relative;
        width: 55px;
        height: 47px;
        cursor: pointer;
        label{
          position: absolute;
          left: 50%;
          top: 100%;
          transform: translate(-50%, 0);
        }
        &.t11{
          background-image: url('../../assets/order/teeth/11.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t12{
          background-image: url('../../assets/order/teeth/12.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t13{
          background-image: url('../../assets/order/teeth/13.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t14{
          background-image: url('../../assets/order/teeth/14.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t15{
          background-image: url('../../assets/order/teeth/15.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t16{
          background-image: url('../../assets/order/teeth/16.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }&.t17{
          background-image: url('../../assets/order/teeth/17.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }&.t18{
          background-image: url('../../assets/order/teeth/18.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t21{
          background-image: url('../../assets/order/teeth/21.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t22{
          background-image: url('../../assets/order/teeth/22.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t23{
          background-image: url('../../assets/order/teeth/23.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t24{
          background-image: url('../../assets/order/teeth/24.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t25{
          background-image: url('../../assets/order/teeth/25.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t26{
          background-image: url('../../assets/order/teeth/26.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t27{
          background-image: url('../../assets/order/teeth/27.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t28{
          background-image: url('../../assets/order/teeth/28.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t31{
          background-image: url('../../assets/order/teeth/31.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t32{
          background-image: url('../../assets/order/teeth/32.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t33{
          background-image: url('../../assets/order/teeth/33.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t34{
          background-image: url('../../assets/order/teeth/34.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t35{
          background-image: url('../../assets/order/teeth/35.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t36{
          background-image: url('../../assets/order/teeth/36.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t37{
          background-image: url('../../assets/order/teeth/37.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t38{
          background-image: url('../../assets/order/teeth/38.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t41{
          background-image: url('../../assets/order/teeth/41.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t42{
          background-image: url('../../assets/order/teeth/42.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t43{
          background-image: url('../../assets/order/teeth/43.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t44{
          background-image: url('../../assets/order/teeth/44.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t45{
          background-image: url('../../assets/order/teeth/45.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }&.t46{
          background-image: url('../../assets/order/teeth/46.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t47{
          background-image: url('../../assets/order/teeth/47.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &.t48{
          background-image: url('../../assets/order/teeth/48.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
    }
  }
</style>
