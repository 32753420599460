const SET_USER = (state, payload) => {
  state.user = payload.user
  state.isLoggedIn = payload.isLogged
  localStorage.setItem('user', JSON.stringify(state.user))
}

const SET_CLINIC_INFO = (state, payload) => {
  state.user.clinics[0] = payload.clinic
  localStorage.setItem('user', JSON.stringify(state.user))
}

const SET_LAB_INFO = (state, payload) => {
  state.user.laboratories[0] = payload.lab
  localStorage.setItem('user', JSON.stringify(state.user))
}

const SET_USER_LOGGED_IN = (state, payload) => {
  state.isLoggedIn = payload.isLogged
  state.token = payload.token
}

const LOG_OUT = (state) => {
  localStorage.removeItem('token')
  state.isLoggedIn = false
  state.user = {
    id: null,
    email: null,
    allow_password_change: false,
    provider: null,
    uid: null,
    first_name: null,
    last_name: null,
    terms_of_service_accepted: false,
    marketing_accepted: false,
    newsletter_accepted: false,
    clinics: [{
      id: null,
      company_name: null,
      date_of_birth: null,
      tax_id: null,
      stripe_setup_intent_client_secret: null
    }],
    laboratories: [{
      id: null,
      name: null,
      address_city: null,
      address_state: null,
      address_line1: null,
      address_postal_code: null,
      tax_id: null,
      business_profile_url: null,
      external_account_account_number: null,
      person_date_of_birth: null,
      person_title: null,
      phone: null,
      stripe_account_onboarding_link_url: null
    }]
  }
}

const SET_LOCATION = (state, payload) => {
  state.locations = payload.locations
}

const ADD_LOCATION = (state, payload) => {
  state.addresses.push(payload.location)
}

const UPDATE_LOCATION = (state, payload) => {
  for (let i = 0; i < state.locations.length; i++) {
    if (state.locations[i].id === payload.location.id) {
      state.locations[i] = payload.location
      break
    }
  }
}

const DELETE_LOCATION = (state, payload) => {
  for (let i = 0; i < state.locations.length; i++) {
    if (state.locations[i].id === payload.locationId) {
      state.locations.splice(i, 1)
      break
    }
  }
}

const ADD_SHOPPING_CART_ITEM = (state, payload) => {
  state.shoppingCart.items.push(payload.item)
  localStorage.setItem('shoppingCart', JSON.stringify(state.shoppingCart))
}

const UPDATE_SHOPPING_CART_ITEM = (state, payload) => {
  for (let i = 0; i < state.shoppingCart.items.length; ++i) {
    if (state.shoppingCart.items[i].id === payload.cartItem.id) {
      state.shoppingCart.items.splice(i, 1, payload.cartItem)
      break
    }
  }
  localStorage.setItem('shoppingCart', JSON.stringify(state.shoppingCart))
}

const REMOVE_SHOPPING_CART_ITEM = (state, payload) => {
  for (let i = 0; i < state.shoppingCart.items.length; ++i) {
    if (state.shoppingCart.items[i].id === payload.id) {
      state.shoppingCart.items.splice(i, 1)
      break
    }
  }
  localStorage.setItem('shoppingCart', JSON.stringify(state.shoppingCart))
}

const SET_CURRENT_ORDER_SHIPMENTS = (state, payload) => {
  state.currentOrder.shipping_location_id = payload.currentOrder.shipping_location_id
  state.currentOrder.billing_location_id = payload.currentOrder.billing_location_id
  state.currentOrder.pickup_location_id = payload.currentOrder.pickup_location_id
  localStorage.setItem('currentOrder', JSON.stringify(state.currentOrder))
}

const CLEAR_SHOPPING_CART = (state) => {
  state.shoppingCart = { items: [] }
  localStorage.setItem('shoppingCart', JSON.stringify(state.shoppingCart))
  state.currentOrder.shipping_location_id = null
  state.currentOrder.billing_location_id = null
  state.currentOrder.pickup_location_id = null
  localStorage.setItem('currentOrder', JSON.stringify(state.currentOrder))
}

export default {
  SET_USER,
  SET_USER_LOGGED_IN,
  SET_CLINIC_INFO,
  SET_LAB_INFO,
  LOG_OUT,
  SET_LOCATION,
  ADD_LOCATION,
  UPDATE_LOCATION,
  DELETE_LOCATION,
  ADD_SHOPPING_CART_ITEM,
  UPDATE_SHOPPING_CART_ITEM,
  REMOVE_SHOPPING_CART_ITEM,
  SET_CURRENT_ORDER_SHIPMENTS,
  CLEAR_SHOPPING_CART
}
