const SET_LOCATIONS = (state, payload) => {
  state.locations = payload.locations
}

const ADD_LOCATION = (state, payload) => {
  state.locations.push(payload.location)
}

const UPDATE_LOCATION = (state, payload) => {
  for (let i = 0; i < state.locations.length; i++) {
    if (state.locations[i].id === payload.location.id) {
      state.locations[i] = payload.location
      break
    }
  }
}

const DELETE_LOCATION = (state, payload) => {
  for (let i = 0; i < state.locations.length; i++) {
    if (state.locations[i].id === payload.locationId) {
      state.locations.splice(i, 1)
      break
    }
  }
}

export default {
  SET_LOCATIONS,
  ADD_LOCATION,
  UPDATE_LOCATION,
  DELETE_LOCATION
}
