import { IsEmptyOrNull } from '@/utils/utils'

const isLoggedIn = state => state.isLoggedIn
const user = state => state.user
const clinic = state => {
  if (state.user.clinics.length > 0) {
    return state.user.clinics[0]
  }
  return null
}
const laboratory = state => {
  if (state.user.laboratories.length > 0) {
    return state.user.laboratories[0]
  }
  return null
}
const isClinicLoggedIn = state => {
  if (!IsEmptyOrNull(state.user.roles)) {
    if (state.isLoggedIn && state.user.roles[0] === 'clinic') {
      return true
    }
  }
  return false
}
const isLabLoggedIn = state => {
  if (!IsEmptyOrNull(state.user.roles)) {
    if (state.isLoggedIn && state.user.roles[0] === 'laboratory') {
      return true
    }
  }
  return false
}

export default {
  user,
  isLoggedIn,
  isClinicLoggedIn,
  isLabLoggedIn,
  clinic,
  laboratory
}
