import userApi from '@/api/users'
import { IsEmptyOrNull } from '@/utils/utils'

const create = ({ commit }, payload) => {
  const params = {
    credentials: payload.credentials,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  userApi.create(params)
}

const sendConfirmationEmail = ({ commit }, payload) => {
  const params = {
    params: payload.params,
    cb: (response) => {
      commit({
        type: 'SET_USER_LOGGED_IN',
        token: response.token,
        isLogged: true
      })
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  userApi.sendConfirmationEmail(params)
}

const confirmAccount = ({ commit }, payload) => {
  const params = {
    confirmation_token: payload.code,
    cb: (response) => {
      commit({
        type: 'SET_USER_LOGGED_IN',
        token: response.token,
        isLogged: true
      })
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  userApi.confirmAccount(params)
}

const getUser = ({ commit }, payload) => {
  const params = {
    cb: (response) => {
      commit({
        type: 'SET_USER',
        user: response.user,
        isLogged: true
      })
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response.user)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  userApi.getUser(params)
}

const updateUser = ({ commit }, payload) => {
  const params = {
    params: payload.params,
    cb: (response) => {
      commit({
        type: 'SET_USER',
        user: response.user,
        isLogged: true
      })
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  userApi.updateUser(params)
}

const logIn = ({ commit }, payload) => {
  const params = {
    credentials: payload.credentials,
    cb: (response) => {
      commit({
        type: 'SET_USER_LOGGED_IN',
        token: response.token,
        isLogged: true
      })
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response.user)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  userApi.logIn(params)
}

const logOut = ({ commit }, payload) => {
  const params = {
    cb: (response) => {
      commit({
        type: 'LOG_OUT'
      })
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response.user)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  userApi.logOut(params)
}

const recoverPassword = ({ commit }, payload) => {
  const params = {
    email: payload.email,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  user.recoverPassword(params)
}

const resetPassword = ({ commit }, payload) => {
  const params = {
    password: payload.password,
    confirmPassword: payload.confirmPassword,
    token: payload.token,
    cb: (response) => {
      if (!IsEmptyOrNull(payload.cb)) {
        payload.cb(response)
      }
    },
    cbError: (error) => {
      if (!IsEmptyOrNull(payload.cbError)) {
        payload.cbError(error)
      }
    }
  }
  user.resetPassword(params)
}

export default {
  create,
  sendConfirmationEmail,
  confirmAccount,
  getUser,
  updateUser,
  logIn,
  logOut,
  recoverPassword,
  resetPassword
}
