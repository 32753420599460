<template>
  <div class="hero-image-wrap" :class="{ 'is-purple-bg': hasPurpleBg }">
    <div class="hero-image-bg" v-bind:style="{ backgroundImage: 'url(' + require('@/assets/' + bgImage) + ')' }">
      <div class="hero-image-cut-out"></div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeroImage',
  props: {
    bgImage: String,
    hasPurpleBg: Boolean
  },
  data () {
    return {
    }
  }
}
</script>

<style scoped lang="scss">
.hero-image-wrap{
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
  &.is-purple-bg{
    background-color: #57567C;
    .hero-image-bg{
      .hero-image-cut-out{
        box-shadow: 0px 500px 0px 500px #57567C;
      }
    }
  }
  .hero-image-bg{
    position: relative;
    width: 120%;
    left: -10%;
    height: 100%;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-image: url("../assets/images/1-hp-1-hero-original.jpg");
    overflow: hidden;
    .hero-image-cut-out{
      position: absolute;
      bottom: 0px;
      left: 0%;
      width: 100%;
      height: 200%;
      border-radius: 100%;
      box-shadow: 0px 500px 0px 500px #F6F4EF;
      z-index: 1;
    }
    .hero-title{
      position: relative;
      z-index: 999999;
      margin: 0 auto;
      max-width: 800px;
      width: 100%;
      p{
        font-weight: 400;
        line-height: 2rem;
      }
    }
    .hp-search-wrap{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      width: 100%;
      max-width: 80%;
      h1, h2, h3{
        color: #FFF;
        margin-bottom: 1.5rem;
      }
      .control{
        margin: 0 auto;
        width: 90%;
        max-width: 640px;
        input{
          position: relative;
          z-index: 1;
          height: 50px;
          &::placeholder {
            color: #57567C;
            opacity: 1; /* Firefox */
          }

          &::-ms-input-placeholder { /* Edge 12 -18 */
            color: #57567C;
          }
        }
        .icon{
          height: 39px;
          width: 39px;
          margin-right: 3px;
          margin-top: 5px;
          background-color: #E3914F;
          border-radius: 50%;
          line-height: 39px;
          color: #FFFFFF;
          font-size: 1.4rem;
        }
      }
    }
    /* .category-card-wrap{
      position: absolute;
      left: 50%;
      top: 55%;
      transform: translate(-50%, -50%);
      z-index: 2;
      width: 100%;
      max-width: 1000px;
      h3{
        text-align: center;
        color: #FFF;
      }
      .category-cards-list{
        width: 100%;
        .category-card{
          height: 180px;
          width: 200px;
          min-width: 280px;
          margin-right: 40px;
          border-radius: 4px;
          background-color: #FFFFFF;
          box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
          text-align: center;
          transition: all .4s ease-out;
          &:hover{
            box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
          }
          &:last-child{
            margin-right: 0px;
          }
          .category-icon{
            margin: 17px auto;
            height: 76px;
            width: 76px;
            border-radius: 50%;
            background-color: #E3914F;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #F6F4EF;
            font-size: 36px;
            font-weight: 600;
          }
          .category-title{
            color: #57567C;
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 23px;
            padding: 5px 0px;
          }
          a{
            color: #57567C;
            text-decoration: underline;
            font-size: 14px;
            .desktop-label{
              display: block;
            }
            .mobile-label{
              display: none;
            }
          }
        }
      }
      .category-cards-list{
        display: flex;
        width: 100%;
        .category-card{
          height: 180px;
          width: calc(33.333333333% - 20px);
          min-width: 280px;
          margin-right: 40px;
          border-radius: 4px;
          background-color: #FFFFFF;
          box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
          text-align: center;
          transition: all .4s ease-out;
          &:hover{
            box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
          }
          &:last-child{
            margin-right: 0px;
          }
          .category-icon{
            margin: 17px auto;
            height: 76px;
            width: 76px;
            border-radius: 50%;
            background-color: #E3914F;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #F6F4EF;
            font-size: 36px;
            font-weight: 600;
          }
          .category-title{
            color: #57567C;
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 23px;
            padding: 5px 0px;
          }
          a{
            color: #57567C;
            text-decoration: underline;
            font-size: 14px;
            .desktop-label{
              display: block;
            }
            .mobile-label{
              display: none;
            }
          }
        }
      }
    } */
  }
}

@media screen and (max-width: 1023px) {
  .hero-image-wrap{
    .hero-image-bg{
      .category-card-wrap{
        .category-cards-list{
          display: block;
          .category-card{
            margin: 0 auto;
            margin-bottom: 14px;
            height: 90px;
            width: 310px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &:last-child{
              margin: 0 auto;
            }
            .category-icon{
              height: 60px;
              width: 60px;
            }
            .category-title{
              text-align: left;
              width: calc(100% - 100px);
            }
            a{
              width: 24px;
              text-decoration: none;
              .desktop-label{
                display: none;
              }
              .mobile-label{
                display: block;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .hero-image-wrap{
    // height: 400px;
    .hero-image-bg{
      width: 180%;
      left: -40%;
      .hero-title{
        max-width: 600px;
        padding: 0 24px;
      }
    }
  }
}
@media screen and (max-width: 540px) {
  .hero-image-wrap{
    .hero-image-bg{
      .hp-search-wrap{
        max-width: 400px;
      }
      .hero-title{
        max-width: 400px;
        padding: 0 24px;
        .title{
          &.is-1{
            font-size: 2rem;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 420px) {
  .hero-image-wrap{
    .hero-image-bg{
      .hp-search-wrap{
        max-width: 380px;
      }
      .hero-title{
        max-width: 380px;
        padding: 0 24px;
      }
    }
  }
}
@media screen and (max-width: 390px) {
  .hero-image-wrap{
    .hero-image-bg{
      .hp-search-wrap{
        max-width: 320px;
      }
      .hero-title{
        max-width: 320px;
        padding: 0 24px;
      }
    }
  }
}
</style>
