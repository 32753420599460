<template>
  <footer class="footer">
    <div class="container">
      <div class="columns">
        <div class="column is-3">
          <img alt="STLLAB Logo" src="../assets/logo.svg">
          <div class="columns pt-6 hide-mobile">
            <div class="column is-1">
              <a href="#">
                <span class="icon-facebook"></span>
              </a>
            </div>
            <div class="column is-1">
              <a href="#">
                <span class="icon-instagram"></span>
              </a>
            </div>
          </div>
          <p class="is-size-7 pt-6 has-text-white hide-mobile">
            © 2021 STLab. All Rights Reserved.
          </p>
        </div>
        <div class="column is-3">
          <ul>
            <li><a href="#">SERVICES</a></li>
            <li><a href="#">LABORATORIES</a></li>
          </ul>
        </div>
        <div class="column is-3"><ul>
            <li><a href="#">ABOUT US</a></li>
            <li><a href="#">CONTACTS</a></li>
            <li><a href="#">LEGAL AREA</a></li>
          </ul>
        </div>
        <div class="column is-3"><ul>
            <li><a href="#">CLINIC DASHBOARD</a></li>
            <li><a href="#">LABORATORY DASHBOARD</a></li>
            <li><a href="#">HELP</a></li>
          </ul>
        </div>
        <div class="column is-3 mobile-socials">
          <div class="columns pt-6">
            <div class="column is-1">
              <a href="#">
                <span class="icon-facebook"></span>
              </a>
            </div>
            <div class="column is-1">
              <a href="#">
                <span class="icon-instagram"></span>
              </a>
            </div>
          </div>
          <p class="is-size-4 pt-6 has-text-white hide-mobile">
            © 2021 STLab. All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer{
  width: 100%;
  background-color: #1A1A1A;
  padding: 70px 0px;
  img{
    width: 150px;
  }
  p, a{
    color: #FFFFFF;
  }
  .mobile-socials{
    display: none;
  }
  ul{
    li{
      margin-bottom: 34px;
      a{
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .footer{
    text-align: center;
    .mobile-socials{
      display: block;
      a{
        span{
          font-size: 2rem;
        }
      }
    }
  }
}
</style>
