<template>
  <div id="order-details-modal" class="order-details-modal">
    <div class="columns revision-instructions mb-6" v-if="modalOptions.isReview">
      <div class="column is-12 has-border">
        <h4 class="title is-6">
          Instrucciones y comentarios del laboratorio
        </h4>
        <p v-html="modalOptions.shoppingCartItem.review_request_comment">
        </p>
      </div>
    </div>
    <div class="columns info-row separator-bottom-light">
      <div class="column is-3">
        <hr>
        <h4 class="title is-6 is-text-orange">
          LABORATORIO
        </h4>
        <p class="title is-6">
          {{modalOptions.laboratory.name}}
        </p>
        <p>
          {{modalOptions.laboratory.address_city}} {{modalOptions.laboratory.address_state}}
        </p>
      </div>
      <div class="column is-5">
        <h4 class="title is-6 is-text-orange">
          PRODUCTO
        </h4>
        <p class="title is-6">
          {{modalOptions.productName}}
        </p>
      </div>
      <div class="column is-4 has-text-right">
        <h4 class="title is-6 is-text-orange">
          PRECIO UNITARIO
        </h4>
        <p>
          {{modalOptions.productOfferPrice / 100}}€
        </p>
      </div>
      <div class="column is-3"></div>
      <div class="column is-9 pt-0">
        <div class="columns">
          <div class="column is-12 pt-2">
            <div class="custom-switch" title="Necesitas enviar una impresión fisica, aditamentos o alguna componente al laboratorio? Selecciona esta opción">
              <input type="checkbox" id="switch-impreison-fisica" v-model="sendFisicalImpression" :disabled="modalOptions.readOnly" /><label class="toggle" for="switch-impreison-fisica"></label>
              <label class="switch-label">Solicita recogida en clínica <!-- <br><span>como funciona</span>--></label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns upload-wrapper separator-bottom">
      <div class="column is-4 upload-column">
        <h4 class="title is-6 is-text-orange">
          STL/DWG FILES <span class="mandatory-label" v-if="!sendFisicalImpression">* obligatorio</span>
        </h4>
        <div id="upload-container-stl" class="columns pt-4 is-flex-direction-column field-wrap">
          <div class="column is-12 pt-0 pb-1">
            <ul class="upload-file-list" v-if="uploadedStlFiles.length > 0">
              <li v-for="(file, index) in uploadedStlFiles" v-bind:key="file.id">
                <p>{{file.filename.substring(0, 28)}} ({{convertSize(file.byte_size)}})</p>
                <button class="remove-upload-btn" @click="removeFile(uploadedStlFiles, index)"><span class="icon-close"></span></button>
              </li>
            </ul>

            <ul class="upload-file-list loading" v-if="filesStl.length > 0">
              <li v-for="(file, index) in filesStl" v-bind:key="index">
                <p>{{file.name.substring(0, 28)}} ({{convertSize(file.size)}})</p>
                <span class="progress-label">{{parseInt(file.progress)}}%</span>
                <div class="loading-bar" :style="{width: file.progress + '%'}"></div>
              </li>
            </ul>
            <file-upload
              input-id="fileStd"
              class="button is-primary is-outline upload-btn"
              :headers="headers"
              :post-action="uploadPostAction"
              :multiple="true"
              extensions="stl,dwg"
              :size="1024 * 1024 * 10"
              v-model="filesStl"
              name="uploaded_file"
              :thread="1"
              @input-filter="inputFilter"
              @input-file="inputFile"
              :data="{ upload_type: 'stl' }"
              ref="uploadStl"
              :disabled="modalOptions.readOnly">
              SUBIR EL ARCHIVIO
            </file-upload>
          </div>
          <div class="column is-12 pt-2 pl-7">
            <label class="small-txt mobile-block">Format: stl, dwg (max 10MB)</label>
          </div>
        </div>
      </div>
      <div class="column is-4 upload-column middle-column">
        <h4 class="title is-6 is-text-orange">
          IMAGES
        </h4>
        <div id="upload-container-img" class="columns pt-4 is-flex-direction-column field-wrap">
          <div class="column is-12 pt-0 pb-1">
            <ul class="upload-file-list" v-if="uploadedImgFiles.length > 0">
              <li v-for="(file, index) in uploadedImgFiles" v-bind:key="file.id">
                <p>{{file.filename.substring(0, 28)}} ({{convertSize(file.byte_size)}})</p>
                <button class="remove-upload-btn" @click="removeFile(uploadedImgFiles, index)"><span class="icon-close"></span></button>
              </li>
            </ul>

            <ul class="upload-file-list loading" v-if="filesImg.length > 0">
              <li v-for="(file, index) in filesImg" v-bind:key="index">
                <p>{{file.name.substring(0, 28)}} ({{convertSize(file.size)}})</p>
                <span class="progress-label">{{parseInt(file.progress)}}%</span>
                <div class="loading-bar" :style="{width: file.progress + '%'}"></div>
              </li>
            </ul>
            <!-- accept="image/png,image/gif,image/jpeg,image/webp" -->
            <file-upload
              input-id="fileImg"
              class="button is-primary is-outline upload-btn"
              :headers="headers"
              :post-action="uploadPostAction"
              :multiple="true"
              extensions="png,jpg,jpeg,webp,bmp"
              :size="1024 * 1024 * 10"
              v-model="filesImg"
              name="uploaded_file"
              :thread="1"
              @input-filter="inputFilter"
              @input-file="inputFile"
              :data="{ upload_type: 'image' }"
              ref="uploadImg"
              :disabled="modalOptions.readOnly">
              SUBIR EL ARCHIVIO
            </file-upload>
          </div>
          <div class="column is-12 pt-2 pl-7">
            <label class="small-txt mobile-block">Format: png, jpg, jpeg, webp, bmp (max 10MB)</label>
          </div>
        </div>
      </div>
      <div class="column is-4 upload-column">
        <h4 class="title is-6 is-text-orange">
          DICOM FILES
        </h4>
        <div id="upload-container-dicom" class="columns pt-4 is-flex-direction-column field-wrap">
          <div class="column is-12 pt-0 pb-1">
            <ul class="upload-file-list" v-if="uploadedDcomFiles.length > 0">
              <li v-for="(file, index) in uploadedDcomFiles" v-bind:key="file.id">
                <p>{{file.filename.substring(0, 28)}} ({{convertSize(file.byte_size)}})</p>
                <button class="remove-upload-btn" @click="removeFile(uploadedDcomFiles, index)"><span class="icon-close"></span></button>
              </li>
            </ul>

            <ul class="upload-file-list loading" v-if="filesDcom.length > 0">
              <li v-for="(file, index) in filesDcom" v-bind:key="index">
                <p>{{file.name.substring(0, 28)}} ({{convertSize(file.size)}})</p>
                <span class="progress-label">{{parseInt(file.progress)}}%</span>
                <div class="loading-bar" :style="{width: file.progress + '%'}"></div>
              </li>
            </ul>
            <!-- accept="image/png,image/gif,image/jpeg,image/webp" -->
            <file-upload
              input-id="fileDcom"
              class="button is-primary is-outline upload-btn"
              :headers="headers"
              :post-action="uploadPostAction"
              :multiple="true"
              extensions="dcm,lzw,jpg"
              :size="1024 * 1024 * 10"
              v-model="filesDcom"
              name="uploaded_file"
              :thread="1"
              @input-filter="inputFilter"
              @input-file="inputFile"
              :data="{ upload_type: 'dicom' }"
              ref="uploadDcom"
              :disabled="modalOptions.readOnly">
              SUBIR EL ARCHIVIO
            </file-upload>
          </div>
          <div class="column is-12 pt-2 pl-7">
            <label class="small-txt mobile-block">Format: dcm (max 10MB)</label>
          </div>
        </div>
      </div>
    </div>
    <div class="columns patient-details">
      <div class="column is-12">
        <h4 class="title is-6">
          NOMBRE DEL ODONTÓLOGO
        </h4>
        <div class="field is-grouped">
          <input-text :placeholder="'Nombre'" v-model="odontologist_name" :name="'odontologist_name'" :disabled="modalOptions.readOnly"></input-text>
        </div>
        <div class="columns">
          <div class="column is-6">
            <h4 id="customer-data" class="title is-6 pt-4">
              NOMBRE DEL PACIENTE <span class="mandatory-label">* obligatorio</span>
            </h4>
            <div class="field is-grouped ">
              <input-text :placeholder="'Nombre'" v-model="first_name" :name="'person_first_name'" :disabled="modalOptions.readOnly"></input-text>
              <!-- <p class="control">
                <input class="input is-primary" type="text" placeholder="Name*" v-model="first_name">
                <span class="icon is-small is-right">
                  <i class="fas fa-check"></i>
                </span>
              </p> -->
              <input-text :placeholder="'Apellidos'" v-model="last_name" :name="'person_last_name'" :disabled="modalOptions.readOnly"></input-text>
            </div>
          </div>
          <div class="column is-3">
            <h4 id="customer-data" class="title is-6 pt-4">
              NUMERO EXPEDIENTE
            </h4>
            <div class="field is-grouped ">
              <input-text :placeholder="'Numero expediente'" v-model="file_number" :name="'file_number'" :disabled="modalOptions.readOnly"></input-text>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns mandatory-options" v-if="product !== null">
      <template v-for="(field, index) in filteredProductFields">
        <div :id="'field-' + field.id" class="column is-12 field-wrap" v-bind:key="field.id">
          <checkbox
            v-if="field.order_field.field_type == 'checkbox_field'"
            :name="field.order_field.name"
            :list="field.order_field.values"
            :is-required="!field.optional"
            :index="index"
            :selected-value="field.values"
            :disabled="modalOptions.readOnly"
            v-on:updateCheckbox="updateFieldValueCheckbox"></checkbox>
          <select-custom
            v-if="field.order_field.field_type == 'select_field'"
            :name="field.order_field.name"
            :list="field.order_field.values"
            :is-required="!field.optional"
            :index="index"
            :selected-value="field.values"
            :disabled="modalOptions.readOnly"
            v-on:updateSelect="updateFieldValueSelect"></select-custom>
          <radio
            v-if="field.order_field.field_type == 'radio_field'"
            :name="field.order_field.name"
            :list="field.order_field.values"
            :is-required="!field.optional"
            :index="index"
            :selected-value="field.values"
            :disabled="modalOptions.readOnly"
            v-on:updateRadio="updateFieldValueRadio"></radio>
          <toggle
            v-if="field.order_field.field_type == 'toggle_field'"
            :name="field.order_field.name"
            :list="field.order_field.values"
            :is-required="!field.optional"
            :index="index"
            :selected-value="field.values"
            :disabled="modalOptions.readOnly"
            v-on:updateToggle="updateFieldValueToggle"></toggle>
          <input-field
            v-if="field.order_field.field_type == 'text_field'"
            :type="'text'"
            :name="field.order_field.name"
            :list="field.order_field.values"
            :is-required="!field.optional"
            :index="index"
            :selected-value="field.values"
            :disabled="modalOptions.readOnly"
            v-on:updateInteger="updateFieldValueInput"></input-field>
          <input-field
            v-if="field.order_field.field_type == 'integer_field'"
            :type="'number'"
            :name="field.order_field.name"
            :list="field.order_field.values"
            :is-required="!field.optional"
            :index="index"
            :selected-value="field.values"
            :disabled="modalOptions.readOnly"
            v-on:updateInteger="updateFieldValueInput"></input-field>
          <teeth-position
            v-if="field.order_field.field_type == 'fdi_chart_field'"
            :name="field.order_field.name"
            :list="field.order_field.values"
            :is-required="!field.optional"
            :index="index"
            :selected-value="field.values"
            :disabled="modalOptions.readOnly"
            :max-fdi-selections="product.maximum_fdi_chart_selections"
            v-on:updateCheckbox="updateFieldValueCheckbox"></teeth-position>

          <teeth-position-with-type
            v-if="field.order_field.field_type == 'fdi_chart_fixed_field'"
            :name="field.order_field.name"
            :list="['11','12','13','14','15','16','17','18','21','22','23','24','25','26','27','28','31','32','33','34','35','36','37','38','41','42','43','44','45','46','47','48']"
            :is-required="!field.optional"
            :index="index"
            :selected-value="field.values"
            :disabled="modalOptions.readOnly"
            :max-fdi-selections="product.maximum_fdi_chart_selections"
            :type="'fixed'"
            v-on:updateCheckbox="updateFieldValueCheckboxExtra"></teeth-position-with-type>

          <teeth-position-with-type
            v-if="field.order_field.field_type == 'fdi_chart_implant_field'"
            :name="field.order_field.name"
            :list="['11','12','13','14','15','16','17','18','21','22','23','24','25','26','27','28','31','32','33','34','35','36','37','38','41','42','43','44','45','46','47','48']"
            :is-required="!field.optional"
            :index="index"
            :selected-value="field.values"
            :disabled="modalOptions.readOnly"
            :max-fdi-selections="product.maximum_fdi_chart_selections"
            :type="'implant'"
            v-on:updateCheckbox="updateFieldValueCheckboxExtra"></teeth-position-with-type>

          <occlusal-design
            v-if="field.order_field.field_type == 'occlusal_design_field'"
            :name="field.order_field.name"
            :list="field.order_field.values"
            :index="index"
            :selected-value="field.values"
            :is-required="!field.optional"
            :disabled="modalOptions.readOnly"
            v-on:updateRadio="updateFieldValueRadio"
            v-on:clearFieldValueRadio="clearFieldValueRadio"></occlusal-design>

          <pontico-design
            v-if="field.order_field.field_type == 'pontic_design_field'"
            :name="field.order_field.name"
            :list="field.order_field.values"
            :index="index"
            :selected-value="field.values"
            :is-required="!field.optional"
            :disabled="modalOptions.readOnly"
            v-on:updateRadio="updateFieldValueRadio"
            v-on:clearFieldValueRadio="clearFieldValueRadio"></pontico-design>

          <tronera-design
            v-if="field.order_field.field_type == 'embrasure_design_field'"
            :name="field.order_field.name"
            :list="field.order_field.values"
            :index="index"
            :selected-value="field.values"
            :is-required="!field.optional"
            :disabled="modalOptions.readOnly"
            v-on:updateRadio="updateFieldValueRadio"
            v-on:clearFieldValueRadio="clearFieldValueRadio"></tronera-design>

          <teeth-color-single
            v-if="field.order_field.field_type == 'single_shade_field'"
            :name="field.order_field.name"
            :list="field.order_field.values"
            :index="index"
            :selected-value="field.values"
            :is-required="!field.optional"
            :disabled="modalOptions.readOnly"
            v-on:updateColorValues="updateColorValues"></teeth-color-single>

          <teeth-color-triple
            v-if="field.order_field.field_type == 'triple_shade_field'"
            :name="field.order_field.name"
            :list="field.order_field.values"
            :index="index"
            :selected-value="field.values"
            :is-required="!field.optional"
            :disabled="modalOptions.readOnly"
            v-on:updateColorValues="updateColorValues"></teeth-color-triple>

          <anatomia-occlusal
            v-if="field.order_field.field_type == 'occlusal_anatomy_field'"
            :name="field.order_field.name"
            :list="field.order_field.values"
            :index="index"
            :selected-value="field.values"
            :is-required="!field.optional"
            :disabled="modalOptions.readOnly"
            v-on:updateRadio="updateFieldValueRadio"
            v-on:clearFieldValueRadio="clearFieldValueRadio"></anatomia-occlusal>
        </div>
      </template>

      <extra-products v-if="extras.length > 0"
        :extras-list="extras"
        :active-extras="modalOptions.activeExtras"
        :selected-extras-value="selectedExtrasValue"
        :order-id="modalOptions.orderId"
        v-on:addExtra="addExtra"
        v-on:removeExtra="removeExtra"
        v-on:updateExtraQuantity="updateExtraQuantity"></extra-products>

    </div>
    <div class="columns additional-instructions">
      <div class="column is-12 has-border">
        <h4 class="title is-6">
          Instrucciones adicionales
        </h4>
        <textarea placeholder="Instrucciones adicionales" v-model="notes" :disabled="modalOptions.readOnly"></textarea>
      </div>
    </div>
    <div class="columns">
      <complementary-list :product-offer-id="modalOptions.productOfferId" :show-extras="false" :loaded="setExtrasOptions"></complementary-list>
    </div>
    <div class="columns btn-wrap">
      <div class="column is-12 has-text-right">
        <button class="button is-primary is-medium is-outlined mt-4 mr-4" @click="closeModal()">
          <span>Cerrar</span>
        </button>
        <button class="button is-primary is-medium mt-4" @click="processOrder()" v-if="!modalOptions.readOnly">
          <span v-if="!modalOptions.isUpdate">Agrega la orden al carrito</span>
          <span v-if="modalOptions.isUpdate">Guardar</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import InputText from '@/components/FormElements/InputText.vue'
import Checkbox from '@/components/FormElements/Checkbox.vue'
import TeethPosition from '@/components/FormElements/TeethPosition.vue'
import TeethPositionWithType from '@/components/FormElements/TeethPositionWithType.vue'
import Radio from '@/components/FormElements/Radio.vue'
import OcclusalDesign from '@/components/FormElements/OcclusalDesign.vue'
import AnatomiaOcclusal from '@/components/FormElements/AnatomiaOcclusal.vue'
import PonticoDesign from '@/components/FormElements/PonticoDesign.vue'
import TroneraDesign from '@/components/FormElements/TroneraDesign.vue'
import SelectCustom from '@/components/FormElements/Select.vue'
import Toggle from '@/components/FormElements/Toggle.vue'
import InputField from '@/components/FormElements/InputField.vue'
import TeethColorSingle from '@/components/FormElements/TeethColorSingle.vue'
import TeethColorTriple from '@/components/FormElements/TeethColorTriple.vue'
import ComplementaryList from '@/components/ComplementaryList.vue'
import ExtraProducts from '@/components/ExtraProducts.vue'
// import TextArea from '@/components/FormElements/TextArea.vue'
import {
  IsEmptyOrNull,
  HumanFileSize,
  AddClassById,
  RemoveClassBySelector,
  ScrollTo,
  ElemOffset,
  RemoveClassById
} from '@/utils/utils'

export default {
  props: {
    modalOptions: Object
  },
  components: {
    InputText,
    Checkbox,
    TeethPosition,
    TeethPositionWithType,
    SelectCustom,
    Radio,
    OcclusalDesign,
    AnatomiaOcclusal,
    PonticoDesign,
    TroneraDesign,
    Toggle,
    InputField,
    TeethColorSingle,
    TeethColorTriple,
    ComplementaryList,
    ExtraProducts
    // TextArea,
  },
  computed: {
    filteredProductFields () {
      return this.productFields.filter(item => item.order_field.field_type !== 'dental_impression_field')
    },
    ...mapGetters({
      isClinicLoggedIn: 'user/isClinicLoggedIn',
      isLabLoggedIn: 'user/isLabLoggedIn',
      clinic: 'user/clinic'
    })
  },
  data () {
    return {
      uploadPostAction: 'https://api-staging.stlab.es/api/v1/uploads',
      product: null,
      productFields: [],
      positionNumber: '',
      productOrderFieldsPagination: null,
      odontologist_name: '',
      first_name: '',
      last_name: '',
      file_number: '',
      notes: '',
      dentalImpression: null,
      sendFisicalImpression: false,
      filesStl: [],
      filesImg: [],
      filesDcom: [],
      uploadedStlFiles: [],
      uploadedImgFiles: [],
      uploadedDcomFiles: [],
      headers: null,
      extras: [],
      selectedExtrasValue: []
      // modalOptions.product.type.hasAttachment
    }
  },
  methods: {
    updateExtraQuantity (extraToUpdate) {
      this.selectedExtrasValue[extraToUpdate.index].quantity = extraToUpdate.quantity
    },
    addExtra (item) {
      const newExtra = {
        product_offer_id: item.id,
        quantity: item.quantity
      }
      this.selectedExtrasValue.push(newExtra)
    },
    removeExtra (index) {
      this.selectedExtrasValue.splice(index, 1)
    },
    setExtrasOptions (complementaryList, extrasList) {
      this.extras = extrasList
    },
    removeFile (fileArray, index) {
      fileArray.splice(index, 1)
    },
    closeModal () {
      if (!IsEmptyOrNull(this.modalOptions.closeCb)) {
        this.modalOptions.closeCb()
      }
      this.$store.commit({
        type: 'application/CLOSE_MODAL'
      })
    },
    convertSize (size) {
      return HumanFileSize(size)
    },
    initUploader () {
      this.uploadPostAction = process.env.VUE_APP_BASE_URL + 'clinics/' + this.clinic.id + '/uploads'
      const accessToken = localStorage.getItem('token')
      this.headers = {
        Authorization: `Bearer ${accessToken}`
      }
    },
    inputFilter (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // Filter system files or hide files
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }

        // Filter php html js file
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }
      }
    },
    inputFile (newFile, oldFile) {
      if (newFile && !oldFile) {
        // add
        console.log('add', newFile)
      }
      if (newFile && oldFile) {
        // update --> check errors
        console.log('update', newFile)
        if (newFile.progress !== oldFile.progress) {
          // progress
        }
        if (!IsEmptyOrNull(newFile.error)) {
          if (newFile.error === 'extension') {
            let i = 0
            for (i = 0; i < this.filesStl.length; i++) {
              if (newFile.id === this.filesStl[i].id) {
                this.filesStl.splice(i, 1)
                break
              }
            }
            for (i = 0; i < this.filesImg.length; i++) {
              if (newFile.id === this.filesImg[i].id) {
                this.filesImg.splice(i, 1)
                break
              }
            }
            for (i = 0; i < this.filesDcom.length; i++) {
              if (newFile.id === this.filesDcom[i].id) {
                this.filesDcom.splice(i, 1)
                break
              }
            }
            this.$store.commit({
              type: 'application/ADD_FEEDBACK',
              feedback: {
                type: 'error',
                message: 'File extension not supported'
              }
            })
          }
        }
      }
      if (!newFile && oldFile) {
        // remove
        if (oldFile.success && oldFile.response.id) {
          // $.ajax({
          //   type: 'DELETE',
          //   url: '/upload/delete?id=' + oldFile.response.id,
          // })
        }
      }

      // Automatically activate upload
      if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
        if (!IsEmptyOrNull(newFile)) {
          switch (newFile.data.upload_type) {
            case 'stl':
              if (!this.$refs.uploadStl.active) {
                this.$refs.uploadStl.active = true
              }
              break
            case 'image':
              if (!this.$refs.uploadImg.active) {
                this.$refs.uploadImg.active = true
              }
              break
            case 'dicom':
              if (!this.$refs.uploadDcom.active) {
                this.$refs.uploadDcom.active = true
              }
              break
            default:
          }
        }
      }

      if (newFile && oldFile && !newFile.active && oldFile.active) {
        // Get response data
        if (newFile.xhr) {
          //  Get the response status code
          if (newFile.xhr.status !== 200) {
            this.$store.commit({
              type: 'application/ADD_FEEDBACK',
              feedback: {
                type: 'error',
                message: newFile.error
              }
            })
            switch (newFile.data.upload_type) {
              case 'stl':
                this.$refs.uploadStl.remove(newFile)
                break
              case 'image':
                this.$refs.uploadImg.remove(newFile)
                break
              case 'dicom':
                this.$refs.uploadDcom.remove(newFile)
                break
              default:
            }
          } else {
            console.log('FINISHED!')
            console.log(newFile.data.upload_type)
            switch (newFile.data.upload_type) {
              case 'stl':
                this.uploadedStlFiles.push(newFile.response)
                this.$refs.uploadStl.remove(newFile)
                break
              case 'image':
                this.uploadedImgFiles.push(newFile.response)
                this.$refs.uploadImg.remove(newFile)
                break
              case 'dicom':
                this.uploadedDcomFiles.push(newFile.response)
                this.$refs.uploadDcom.remove(newFile)
                break
              default:
            }
            this.$store.commit({
              type: 'application/ADD_FEEDBACK',
              feedback: {
                type: 'success',
                message: 'File uploaded successfully!'
              }
            })
          }
        }
      }
    },
    processOrder () {
      RemoveClassById('customer-data', 'error')
      RemoveClassBySelector('.field-wrap', 'error')
      if (this.modalOptions.isUpdate) {
        if (this.modalOptions.isOrder) {
          this.updateOrder()
        } else {
          this.updateShoppingCart()
        }
      } else {
        this.addOrderToCart()
      }
    },
    addUploadedFiles (order) {
      if ((this.uploadedStlFiles.length + this.uploadedImgFiles.length + this.uploadedDcomFiles.length) > 0) {
        let i = 0
        for (i = 0; i < this.uploadedStlFiles.length; i++) {
          order.upload_ids.push(this.uploadedStlFiles[i].id)
        }
        for (i = 0; i < this.uploadedImgFiles.length; i++) {
          order.upload_ids.push(this.uploadedImgFiles[i].id)
        }
        for (i = 0; i < this.uploadedDcomFiles.length; i++) {
          order.upload_ids.push(this.uploadedDcomFiles[i].id)
        }
      }
    },
    addOrderToCart () {
      const self = this
      if (this.first_name.trim() === '' || this.last_name.trim() === '') {
        AddClassById('customer-data', 'error')
        console.log(document.documentElement.scrollTop)
        ScrollTo(document.documentElement, 0, 500)
        return
      }
      let i = 0
      const newOrder = {
        product_offer_id: this.modalOptions.productOfferId,
        first_name: this.first_name,
        last_name: this.last_name,
        file_number: this.file_number,
        odontologist_name: this.odontologist_name,
        notes: this.notes,
        shopping_cart_item_fields: [],
        upload_ids: [],
        extras: this.selectedExtrasValue
      }

      this.addUploadedFiles(newOrder)

      for (i = 0; i < this.productFields.length; i++) {
        if (this.productFields[i].order_field.field_type !== 'dental_impression_field') {
          if (!IsEmptyOrNull(this.productFields[i].values[0])) {
            newOrder.shopping_cart_item_fields.push({
              product_order_field_id: this.productFields[i].id,
              values: this.productFields[i].values
            })
          }
        } else {
          const stlType = this.sendFisicalImpression ? 'physical' : 'digital'
          newOrder.shopping_cart_item_fields.push({
            product_order_field_id: this.productFields[i].id,
            values: [stlType]
          })
        }
      }
      this.$store.dispatch('clinic/createShoppingCartItem', {
        clinicId: this.clinic.id,
        data: newOrder,
        cb: (response) => {
          self.$router.push({ path: '/checkout' })
          self.closeModal()
          /* const modalOptions = {
            componentId: 'OrderAdded',
            title: 'Order added correctly!',
            smallSize: true
          }
          self.$store.commit({
            type: 'application/SHOW_MODAL',
            modalOptions: modalOptions
          }) */
        },
        cbError: (error) => {
          console.log('error 1')
          console.log(error)
          console.log(error.errors['shopping_cart_item_fields.product_order_field_id'])
          if (!IsEmptyOrNull(error.errors['product_order_field_id'])) {
            console.log('error 2')
            for (let i = 0; i < error.errors['product_order_field_id'].length; i++) {
              console.log('error 3')
              for (let j = 0; j < error.errors['product_order_field_id'][i].missing_mandatory_field.length; j++) {
                console.log('error 4')
                AddClassById('field-' + error.errors['product_order_field_id'][i].missing_mandatory_field[j].id, 'error')
                if (j === 0) {
                  console.log('error 5')
                  const firstErrorElemnt = document.getElementById('field-' + error.errors['product_order_field_id'][i].missing_mandatory_field[j].id)
                  const elementOffset = ElemOffset(firstErrorElemnt)
                  ScrollTo(document.documentElement, elementOffset.top, 500)
                }
                console.log('error 6')
                self.$store.commit({
                  type: 'application/ADD_FEEDBACK',
                  feedback: {
                    type: 'error',
                    message: error.errors['product_order_field_id'][i].missing_mandatory_field[j].name
                  }
                })
              }
            }
          }
          if (!IsEmptyOrNull(error.errors['product_order_field'])) {
            for (let i = 0; i < error.errors['product_order_field'].length; i++) {
              self.$store.commit({
                type: 'application/ADD_FEEDBACK',
                feedback: {
                  type: 'error',
                  message: error.errors['product_order_field'][i]
                }
              })
            }
            ScrollTo(document.documentElement, 0, 500)
          }
          if (!IsEmptyOrNull(error.errors['shopping_cart_item_fields.values'])) {
            for (let i = 0; i < error.errors['shopping_cart_item_fields.values'].length; i++) {
              self.$store.commit({
                type: 'application/ADD_FEEDBACK',
                feedback: {
                  type: 'error',
                  message: error.errors['shopping_cart_item_fields.values'][i]
                }
              })
            }
            ScrollTo(document.documentElement, 0, 500)
          }
        }
      })
    },
    updateOrder () {
      const self = this
      let i = 0
      const newOrder = {
        product_offer_id: this.modalOptions.productOfferId,
        first_name: this.first_name,
        last_name: this.last_name,
        file_number: this.file_number,
        odontologist_name: this.odontologist_name,
        notes: this.notes,
        order_item_fields: [],
        upload_ids: [],
        extras: this.selectedExtrasValue
      }

      this.addUploadedFiles(newOrder)

      for (i = 0; i < this.productFields.length; i++) {
        if (this.productFields[i].order_field.field_type !== 'dental_impression_field') {
          if (!IsEmptyOrNull(this.productFields[i].values[0])) {
            if (this.productFields[i].order_field.field_type === 'fdi_chart_fixed_field' || this.productFields[i].order_field.field_type === 'fdi_chart_implant_field') {
              for (let t = 0; t < this.productFields[i].values.length; t++) {
                if (typeof this.productFields[i].values[t] !== 'string') {
                  this.productFields[i].values[t] = JSON.stringify(this.productFields[i].values[t])
                }
              }
            }

            newOrder.order_item_fields.push({
              id: this.productFields[i].updateId,
              product_order_field_id: this.productFields[i].id,
              values: this.productFields[i].values
            })
          }
        } else {
          const stlType = this.sendFisicalImpression ? 'physical' : 'digital'
          newOrder.order_item_fields.push({
            id: this.productFields[i].updateId,
            product_order_field_id: this.productFields[i].id,
            values: [stlType]
          })
        }
      }

      const action = this.modalOptions.isReview ? 'clinic/completeReview' : 'clinic/updateOrderItems'
      this.$store.dispatch(action, {
        orderItemId: this.modalOptions.shoppingCartItem.id,
        data: newOrder,
        cb: (response) => {
          self.modalOptions.updateCb()
          const modalOptions = {
            componentId: 'OrderUpdated',
            title: 'Order updated correctly!',
            smallSize: true
          }
          self.$store.commit({
            type: 'application/SHOW_MODAL',
            modalOptions: modalOptions
          })
        },
        cbError: (error) => {
          if (!IsEmptyOrNull(error.errors['product_order_field_id'])) {
            for (let i = 0; i < error.errors['product_order_field_id'].length; i++) {
              for (let j = 0; j < error.errors['product_order_field_id'][i].missing_mandatory_field.length; j++) {
                AddClassById('field-' + error.errors['product_order_field_id'][i].missing_mandatory_field[j].id, 'error')
                if (j === 0) {
                  const firstErrorElemnt = document.getElementById('field-' + error.errors['product_order_field_id'][i].missing_mandatory_field[j].id)
                  const elementOffset = ElemOffset(firstErrorElemnt)
                  ScrollTo(document.documentElement, elementOffset.top, 500)
                }
                self.$store.commit({
                  type: 'application/ADD_FEEDBACK',
                  feedback: {
                    type: 'error',
                    message: error.errors['product_order_field_id'][i].missing_mandatory_field[j].name
                  }
                })
              }
            }
          }
          if (!IsEmptyOrNull(error.errors['order_item_fields.missing_mandatory_field'])) {
            for (let i = 0; i < error.errors['order_item_fields.missing_mandatory_field'].length; i++) {
              self.$store.commit({
                type: 'application/ADD_FEEDBACK',
                feedback: {
                  type: 'error',
                  message: error.errors['order_item_fields.missing_mandatory_field'][i]
                }
              })
            }
          }
          if (!IsEmptyOrNull(error.errors['order_item_fields.product_order_field_id'])) {
            for (let i = 0; i < error.errors['order_item_fields.product_order_field_id'].length; i++) {
              self.$store.commit({
                type: 'application/ADD_FEEDBACK',
                feedback: {
                  type: 'error',
                  message: error.errors['order_item_fields.product_order_field_id'][i]
                }
              })
            }
          }
          if (!IsEmptyOrNull(error.errors['order_item_fields.values'])) {
            for (let i = 0; i < error.errors['order_item_fields.values'].length; i++) {
              self.$store.commit({
                type: 'application/ADD_FEEDBACK',
                feedback: {
                  type: 'error',
                  message: error.errors['order_item_fields.values'][i]
                }
              })
            }
          }
        }
      })
    },
    updateShoppingCart () {
      const self = this
      let i = 0
      const newOrder = {
        product_offer_id: this.modalOptions.productOfferId,
        first_name: this.first_name,
        last_name: this.last_name,
        file_number: this.file_number,
        odontologist_name: this.odontologist_name,
        notes: this.notes,
        shopping_cart_item_fields: [],
        upload_ids: [],
        extras: this.selectedExtrasValue
      }

      this.addUploadedFiles(newOrder)

      for (i = 0; i < this.productFields.length; i++) {
        if (this.productFields[i].order_field.field_type !== 'dental_impression_field') {
          if (!IsEmptyOrNull(this.productFields[i].values[0])) {
            if (this.productFields[i].order_field.field_type === 'fdi_chart_fixed_field' || this.productFields[i].order_field.field_type === 'fdi_chart_implant_field') {
              for (let t = 0; t < this.productFields[i].values.length; t++) {
                if (typeof this.productFields[i].values[t] !== 'string') {
                  this.productFields[i].values[t] = JSON.stringify(this.productFields[i].values[t])
                }
              }
            }

            newOrder.shopping_cart_item_fields.push({
              id: this.productFields[i].updateId,
              product_order_field_id: this.productFields[i].id,
              values: this.productFields[i].values
            })
          }
        } else {
          const stlType = this.sendFisicalImpression ? 'physical' : 'digital'
          newOrder.shopping_cart_item_fields.push({
            id: this.productFields[i].updateId,
            product_order_field_id: this.productFields[i].id,
            values: [stlType]
          })
        }
      }
      this.$store.dispatch('clinic/updateShoppingCartItem', {
        cartItemId: this.modalOptions.shoppingCartItem.id,
        data: newOrder,
        cb: (response) => {
          self.modalOptions.updateCb()
          const modalOptions = {
            componentId: 'OrderUpdated',
            title: 'Order updated correctly!',
            smallSize: true
          }
          self.$store.commit({
            type: 'application/SHOW_MODAL',
            modalOptions: modalOptions
          })
        },
        cbError: (error) => {
          if (!IsEmptyOrNull(error.errors['product_order_field_id'])) {
            for (let i = 0; i < error.errors['product_order_field_id'].length; i++) {
              for (let j = 0; j < error.errors['product_order_field_id'][i].missing_mandatory_field.length; j++) {
                AddClassById('field-' + error.errors['product_order_field_id'][i].missing_mandatory_field[j].id, 'error')
                if (j === 0) {
                  const firstErrorElemnt = document.getElementById('field-' + error.errors['product_order_field_id'][i].missing_mandatory_field[j].id)
                  const elementOffset = ElemOffset(firstErrorElemnt)
                  ScrollTo(document.documentElement, elementOffset.top, 500)
                }
                self.$store.commit({
                  type: 'application/ADD_FEEDBACK',
                  feedback: {
                    type: 'error',
                    message: error.errors['product_order_field_id'][i].missing_mandatory_field[j].name
                  }
                })
              }
            }
          }
          if (!IsEmptyOrNull(error.errors['shopping_cart_item_fields.product_order_field'])) {
            for (let i = 0; i < error.errors['shopping_cart_item_fields.product_order_field'].length; i++) {
              self.$store.commit({
                type: 'application/ADD_FEEDBACK',
                feedback: {
                  type: 'error',
                  message: error.errors['shopping_cart_item_fields.product_order_field'][i]
                }
              })
            }
          }
          if (!IsEmptyOrNull(error.errors['shopping_cart_item_fields.values'])) {
            for (let i = 0; i < error.errors['shopping_cart_item_fields.values'].length; i++) {
              self.$store.commit({
                type: 'application/ADD_FEEDBACK',
                feedback: {
                  type: 'error',
                  message: error.errors['shopping_cart_item_fields.values'][i]
                }
              })
            }
          }
        }
      })
    },
    updateColorValues (data) {
      this.filteredProductFields[data.indexField].values = data.values
    },
    updateFieldValueInput (data) {
      this.filteredProductFields[data.indexField].values = [data.value]
    },
    updateFieldValueToggle (data) {
      this.filteredProductFields[data.indexField].values = [data.valueToggle]
    },
    updateFieldValueRadio (data) {
      this.filteredProductFields[data.indexField].values = [data.indexRadio]
    },
    clearFieldValueRadio (data) {
      this.filteredProductFields[data.indexField].values = []
    },
    updateFieldValueCheckbox (data) {
      if (!this.filteredProductFields[data.indexField].values.includes(data.value)) {
        if (this.filteredProductFields[data.indexField].values.length < data.limit) {
          this.filteredProductFields[data.indexField].values.push(data.value)
        }
      } else {
        if (this.filteredProductFields[data.indexField].values.includes(data.value)) {
          const indexElement = this.filteredProductFields[data.indexField].values.indexOf(data.value)
          this.filteredProductFields[data.indexField].values.splice(indexElement, 1)
        }
      }
    },
    updateFieldValueCheckboxExtra (data) {
      this.filteredProductFields[data.indexField].values = data.value
    },
    updateFieldValueSelect (data) {
      this.filteredProductFields[data.indexField].values = [data.indexOption]
    },
    getProductOrderFields () {
      const self = this
      this.$store.dispatch('products/getProductOrderFields', {
        page: 1,
        pageSize: 1000,
        productId: this.modalOptions.productId,
        cb: (response) => {
          if (this.modalOptions.shoppingCartItem !== null) {
            for (let i = 0; i < response.length; i++) {
              response[i].values = []
              if (!IsEmptyOrNull(self.modalOptions.shoppingCartItem.shopping_cart_item_fields)) {
                for (let j = 0; j < self.modalOptions.shoppingCartItem.shopping_cart_item_fields.length; j++) {
                  if (response[i].id === self.modalOptions.shoppingCartItem.shopping_cart_item_fields[j].product_order_field_id) {
                    response[i].updateId = self.modalOptions.shoppingCartItem.shopping_cart_item_fields[j].id
                    response[i].values = self.modalOptions.shoppingCartItem.shopping_cart_item_fields[j].values
                    if (response[i].order_field.field_type === 'dental_impression_field') {
                      response[i].order_field.values = self.modalOptions.shoppingCartItem.shopping_cart_item_fields[j].values
                    }
                  }
                }
              }
              if (!IsEmptyOrNull(self.modalOptions.shoppingCartItem.order_item_fields)) {
                for (let j = 0; j < self.modalOptions.shoppingCartItem.order_item_fields.length; j++) {
                  if (response[i].id === self.modalOptions.shoppingCartItem.order_item_fields[j].product_order_field_id) {
                    response[i].updateId = self.modalOptions.shoppingCartItem.order_item_fields[j].id
                    response[i].values = self.modalOptions.shoppingCartItem.order_item_fields[j].values
                    if (response[i].order_field.field_type === 'dental_impression_field') {
                      response[i].order_field.values = self.modalOptions.shoppingCartItem.order_item_fields[j].values
                    }
                  }
                }
              }

              // Check if has physical or digital impression
              if (response[i].order_field.field_type === 'dental_impression_field') {
                self.sendFisicalImpression = response[i].order_field.values.includes('physical')
              }
            }
          } else {
            for (let i = 0; i < response.length; i++) {
              response[i].values = []
            }
          }

          self.productFields = response
          self.productOrderFieldsPagination = response.pagination
        },
        cbError: (error) => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error.response.data.error
            }
          })
        }
      })
    },
    getProductById () {
      const self = this
      this.$store.dispatch('products/getProductById', {
        productId: this.modalOptions.productId
      }).then(function (response) {
        self.product = response
      }).catch(function (error) {
        self.$store.commit({
          type: 'application/ADD_FEEDBACK',
          feedback: {
            type: 'error',
            message: error
          }
        })
      })
    }
  },
  created () {
    if (this.isClinicLoggedIn) {
      this.initUploader()
    }
    console.log(this.modalOptions.shoppingCartItem)
    this.getProductById()
    this.getProductOrderFields()
    this.first_name = this.modalOptions.patientName
    this.last_name = this.modalOptions.patientLastName
    this.file_number = this.modalOptions.shoppingCartItem.file_number
    this.odontologist_name = this.modalOptions.odontologistName
    if (this.modalOptions.shoppingCartItem !== null) {
      this.notes = this.modalOptions.shoppingCartItem.notes
    }
    if (this.modalOptions.shoppingCartItem !== null) {
      for (let i = 0; i < this.modalOptions.shoppingCartItem.uploads.length; i++) {
        switch (this.modalOptions.shoppingCartItem.uploads[i].upload_type) {
          case 'stl':
            this.uploadedStlFiles.push(this.modalOptions.shoppingCartItem.uploads[i])
            break
          case 'image':
            this.uploadedImgFiles.push(this.modalOptions.shoppingCartItem.uploads[i])
            break
          case 'dicom':
            this.uploadedDcomFiles.push(this.modalOptions.shoppingCartItem.uploads[i])
            break
          default:
        }
      }
    }
  }
}
</script>

<style lang="scss">
.order-details-modal{
  width: 100%;
  margin-bottom: 76px;
  padding: 0 76px;
  hr{
    margin-top: 0px;
    display: none;
  }
  h4{
    text-transform: uppercase;
    &.is-text-orange{
      color: #E3914F;
    }
  }
  .field-wrap{
    &.error{
      .title{
        color: red;
      }
    }
  }
  .file-uploads {
    display: block;
    box-sizing: border-box;
    height: 40px;
    line-height: 28px;
    min-width: 161px;
    width: 70%;
    border: 2px solid #57567C!important;
    border-radius: 4px;
    background-color: transparent!important;
    color: #57567C!important;
  }
  .link-download{
    text-decoration: underline;
    cursor: pointer;
    font-weight: 600;
  }
  #customer-data{
    &.error{
      color: red;
    }
  }
  .separator-bottom-light{
    border-bottom: 1px solid rgba(87, 86, 124, .3);
  }
  .separator-bottom{
    border-bottom: 1px solid #57567C;
  }
  .upload-wrapper{
    padding: 21px 0px;
    .upload-column{
      padding-top: 7px;
      padding-bottom: 7px;
      &:first-child{
        padding-right: 14px;
      }
      &:last-child{
        padding-right: 14px;
      }
      &.middle-column{
        padding: 7px 14px;
        border-left: 1px solid rgba(87, 86, 124, .3);
        border-right: 1px solid rgba(87, 86, 124, .3);
      }

      .upload-file-list{
        width: 100%;
        li{
          position: relative;
          padding: 14px;
          background-color: #F6F4EF;
          border-radius: 4px;
          width: 100%;
          margin-bottom: 7px;
          p{
            display: block;
            width: 90%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .remove-upload-btn{
            position: absolute;
            top: 0px;
            right: 0px;
            width: calc(10% + 10px);
            height: 100%;
            background-color: transparent;
            border: 0px;
            color: #57567C;
            cursor: pointer;
          }
          .progress-label{
            position: absolute;
            top: 0px;
            right: 0px;
            width: calc(10% + 10px);
            height: 100%;
            background-color: transparent;
            border: 0px;
            color: #57567C;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .loading-bar{
            position: absolute;
            bottom: 0px;
            left: 0px;
            width: 0px;
            height: 2px;
            background-color: #57567C;
            transition: width .3s ease-out;
          }
        }
      }

      .upload-btn{
        margin-top: 14px;
        width: 100%;
      }
    }
  }
  .info-row{
    flex-wrap: wrap;
    padding-bottom: 24px;
    .small-txt{
      padding-top: 5px;
      padding-left: 14px;
      font-size: .8rem;
      line-height: .8rem;
    }
    .custom-switch{
      width: 100%;
      justify-content: flex-start;
      .switch-label{
        padding-right: 12px;
        span{
          font-weight: bold;
        }
        a{
          text-decoration: underline;
        }
      }
    }
  }
  .patient-details, .position{
    padding-top: 24px;
  }
  .revision-instructions {
    color: #EA4B4B;
    margin-bottom: 21px;
    .title{
      color: #EA4B4B;
    }
    .has-border{
      border: 1px solid #EA4B4B;
    }
  }
  .additional-instructions, .btn-wrap{
    padding: 24px 0px;
    .has-border{
      border: 1px solid #57567C;
    }
    textarea{
      width: 100%;
      height: 100px;
      padding: 7px;
    }
  }
  .mandatory-options, .optional-options{
    flex-wrap: wrap;
    .column.is-12{
      padding-bottom: 24px;
      margin-bottom: 24px;
      border-bottom: 1px solid #57567C;
    }
    .checkbox, .radio, select{
      margin-right: 14px;
      margin-bottom: 14px;
    }
    select{
      display: block;
      margin-bottom: 14px;
      padding: 0 7px;
    }
  }
  .optional-options{
    .custom-switch{
      float: initial;
      width: 100%;
      margin-bottom: 14px;
      .title{
        position: relative;
        top: 5px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .order-details-modal{
    margin-bottom: 0px;
    padding: 0 35px;
    hr{
      display: block;
    }
    .columns{
      flex-direction: column;
      overflow: hidden;
      .column{
        padding: 0px;
        float: left;
        margin-left: 0px;
        width: 100%;
        &.has-text-right{
          text-align: left!important;
        }
        &.has-border{
          padding: 14px;
        }
        button{
          display: block;
          margin: 0 auto;
          width: 260px!important;
          max-width: 100%!important;
          line-height: 0px;
        }
        label{
          &.mobile-block{
            display: block;
            margin: 0 auto;
            margin-bottom: 32px;
            text-align: center;
          }
        }
        .field{
          &.is-grouped{
            display: block;
            .control{
              width: 100%;
              margin-bottom: 14px;
            }
          }
        }
      }
    }
  }
}
</style>
