import api from '@/api/api'

const getSettings = ({ commit }) => {
  api.get('settings').then((response) => {
    commit({
      type: 'SET_SETTINGS',
      value: response.data
    })
  }).catch(function () {})
}

export default {
  getSettings
}
