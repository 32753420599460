<template>
  <div class="my-account-login-wrap" v-bind:class="{'no-bg': noBg}">
    <div class="columns content-my-account-login-wrap">
      <clinic-login :callback="callback"></clinic-login>
      <clinic-register></clinic-register>
    </div>
  </div>
</template>

<script>
import ClinicLogIn from '@/components/Clinic/ClinicLogIn.vue'
import ClinicRegister from '@/components/Clinic/ClinicRegister.vue'

export default {
  name: 'ClinicAccess',
  props: {
    callback: {
      type: Function,
      default: null
    },
    noBg: {
      type: Boolean,
      default: false
    }
  },
  components: {
    'clinic-login': ClinicLogIn,
    'clinic-register': ClinicRegister
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.my-account-login-wrap{
  width: 100%;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
  &.no-bg{
    background-color: transparent;
    box-shadow: none;
  }
  .content-my-account-login-wrap{
    padding: 60px;
  }
}
@media screen and (max-width: 768px) {
  .my-account-login-wrap{
    .content-my-account-login-wrap{
      padding: 35px;
    }
  }
}
</style>
